<template>
  <div>
    <el-button
      @click="upBookShelf"
      style="background-color: #ff1932; color: #fff"
      >新建类别</el-button
    >
    <el-card style="margin-top: 10px">
      <el-table
        :data="tableData"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        style="width: 100%"
        ref="table"
        :highlight-current-row="true"
        :row-key="getRowKeys"
        :expand-row-keys="expands"
      >
        <el-table-column type="index" label=" " width="200">
        </el-table-column>
        <el-table-column label="标签名称">
          <template slot-scope="scope">
            <div
              style="
                margin-top: 1px;
                margin-right: 10px;
                display: inline-block;
                vertical-align: sub;
              "
            >
              <img
                v-show="scope.row.ChirendShow"
                src="@/assets/img/push.svg"
                @click="pushitem(scope.row, scope.$index)"
                alt=""
              />
              <img
                v-show="!scope.row.ChirendShow"
                src="@/assets/img/del.svg"
                @click="pushitem(scope.row, scope.$index)"
                alt=""
              />
            </div>
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="等级">
          <template slot-scope="scope">
            <span v-if="scope.row.level == 1">一级</span>
            <span v-else-if="scope.row.level == 2">二级</span>
            <span v-else-if="scope.row.level == 3">三级</span>
          </template>
        </el-table-column>

        <el-table-column label="序号">
          <template slot-scope="scope">
            <span>{{ scope.row.tagSort }}</span>
          </template>
        </el-table-column>

        <!-- 标签是否启用 -->
        <el-table-column width="200" label="是否启用">
          <template slot-scope="scope" style="padding-left: 10px">
            <el-switch
              v-if="scope.row.id != 24"
              v-model="scope.row.isShow"
              @change="switchLabel(scope.row)"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
          </template>
        </el-table-column>

        <el-table-column prop="author" label="操作">
          <template slot-scope="scope" style="padding-left: 10px">
            <el-button
              v-if="scope.row.id != 24"
              class="btn_right"
              type="text"
              size="mini"
              @click="showloeding(scope.row, scope.$index)"
            >
              编辑</el-button
            >
            <!--  v-if="scope.row.bookInfoChildrenList.length != 0" -->
            <el-button
              v-if="scope.row.id != 24"
              type="text"
              class="btn_right"
              size="mini"
              @click="handleToogleExpand(scope.row, scope.$index)"
              >添加标签</el-button
            >

            <el-button
              type="text"
              v-if="scope.row.id != 24"
              size="mini"
              @click="dellogding(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
        <el-table-column type="expand" width="1">
          <!-- 分区 -->
          <template>
            <el-table
              :data="childrenData"
              :show-header="false"
              style="width: 100%"
            >
              <el-table-column width="240"></el-table-column>
              <el-table-column prop="name"></el-table-column>
              <el-table-column label="等级"> </el-table-column>

              <el-table-column label="序号">
                <template slot-scope="scope">
                  <span>{{ scope.row.tagSort }}</span>
                </template>
              </el-table-column>

              <!-- 标签是否启用 -->
              <el-table-column width="200">
                <template slot-scope="scope" style="padding-left: 10px">
                  <el-switch
                    v-if="scope.row.parentId != 24"
                    @change="switchLabel(scope.row)"
                    v-model="scope.row.isShow"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                  >
                  </el-switch>
                </template>
              </el-table-column>

              <el-table-column prop="author">
                <template slot-scope="scope">
                  <!-- 已存在分类 -->
                  <div v-if="scope.row.parentId != 24">
                    <el-button
                      class="btn_right"
                      type="text"
                      size="mini"
                      @click="upchirenloeding(scope.row)"
                    >
                      编辑</el-button
                    >
                    <el-button
                      type="text"
                      size="mini"
                      @click="delchirenloeding(scope.row)"
                      >删除</el-button
                    >
                  </div>

                  <!-- 未分类 -->
                  <div v-else>
                    <el-button
                      class="btn_right"
                      type="text"
                      size="mini"
                      @click="movechirenloeding(scope.row)"
                    >
                      编辑</el-button
                    >
                    <el-button
                      type="text"
                      size="mini"
                      @click="delchirenloeding(scope.row)"
                      >删除</el-button
                    >
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </template>

          <!-- 分区 end-->
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.current"
          :page-sizes="[10, 15, 20]"
          :page-size="queryInfo.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>

      <!-- 一级标签 -->
      <el-dialog
        :title="lodingtext"
        @close="hideEnable"
        :visible.sync="dia"
        width="30%"
      >
        <el-form :model="form">
          <el-form-item :label="inoputNane" label-width="100px">
            <!-- 标签名称 -->
            <el-input
              v-model="form.name"
              autocomplete="off"
              maxlength="10"
              show-word-limit
            ></el-input>
          </el-form-item>

          <!-- 一级标签大类集合。 -->
          <el-form-item label="等级" v-if="editLabel == 0" label-width="100px">
            <el-select v-model="form.level" placeholder="请选择等级">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 序号 -->
          <el-form-item label="序号" label-width="100px">
            <el-input
              class="tagSort"
              v-model="form.tagSort"
              autocomplete="off"
              @input="ValidateSort(1)"
              show-word-limit
            ></el-input>
          </el-form-item>
          <!-- 新增一级标签。是否启用 -->
          <el-form-item label="是否启用" label-width="100px">
            <el-switch
              v-model="Enable"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dia = false">取 消</el-button>
          <el-button type="primary" class="BGcolor" @click="updataBook()"
            >确 定</el-button
          >
        </span>
      </el-dialog>

      <!-- 标签二级分类 -->
      <el-dialog
        :title="lodingtext"
        @close="hideEnable"
        :visible.sync="dia2"
        width="30%"
      >
        <el-form :model="form2">
          <el-form-item :label="inoputNane" label-width="100px">
            <el-input
              v-model="form2.name"
              autocomplete="off"
              maxlength="10"
              show-word-limit
            ></el-input>
          </el-form-item>

          <!-- 序号 -->
          <el-form-item label="序号" label-width="100px">
            <el-input
              class="tagSort"
              v-model="form2.tagSort"
              @input="ValidateSort(2)"
              autocomplete="off"
              show-word-limit
            ></el-input>
          </el-form-item>
          <!-- 一级标签大类集合。 -->
          <el-form-item
            label="所属类别"
            v-if="lodingStyle != 1 - 0"
            label-width="100px"
          >
            <el-select v-model="movechiren.region" placeholder="请选择相关类别">
              <el-option
                v-for="(item, index) in TagList"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <!-- 新增一级标签。是否启用 -->
          <el-form-item label="是否启用" label-width="100px">
            <el-switch
              v-model="Enable"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dia2 = false">取 消</el-button>
          <el-button type="primary" class="BGcolor" @click="updataBook2()"
            >确 定</el-button
          >
        </span>
      </el-dialog>

      <!-- 未分类标签-->
      <el-dialog title="标签分类" :visible.sync="dia3" width="30%">
        <el-form :model="movechiren">
          <el-form-item :label="inoputNane" label-width="100px">
            <el-input
              v-model="movechiren.name"
              autocomplete="off"
              maxlength="10"
              show-word-limit
            ></el-input>
          </el-form-item>

          <!-- 新增一级标签。是否启用 -->
          <!-- <el-form-item label="是否启用" label-width="100px">
            <el-switch
              v-model="moveEnable"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
          </el-form-item> -->

          <!-- 一级标签大类集合。 -->
          <el-form-item label="所属类别" label-width="100px">
            <el-select v-model="movechiren.region" placeholder="请选择相关类别">
              <el-option
                v-for="(item, index) in TagList"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dia3 = false">取 消</el-button>
          <el-button type="primary" class="BGcolor" @click="upmovechiren()"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>
<script>
// 存储当前修改项的id, 防止详情页刷新时， 页面出现空屏
import { getUserDetails } from "../../../utils/auth.js";

export default {
  data() {
    return {
      tableData: [],

      // 查询参数对象----分页
      queryInfo: {
        current: 1,
        size: 10,
      },

      // 遮罩层
      loading: true,
      total: 0,

      // form 新增标签大类
      form: {
        level: 0,
        tagSort: 0,
      },
      dia: false,

      // 书架 子标签
      form2: {},
      dia2: false,

      // ---------------- form 3 未分区的标签分区
      movechiren: {
        name: "",
        region: "",
      },
      dia3: false,

      // 弹窗标题
      lodingtext: "添加标签",
      // inupt名称
      inoputNane: "标签名称",
      // 1:编辑书架； 2:添加分区；  0：添加书架； 3:编辑标签
      lodingStyle: 1,

      //新增大类0;编辑时为1;
      editLabel: 0,
      // 分区数组
      childrenData: [],

      TagList: [], //一级标签集合

      // 当前点击项
      item: {},
      item_key: "",

      // 分区 当前点击的分区
      fen_delitem: {},
      getRowKeys(row) {
        return row.id;
      },
      expands: [], //element 默认展开项
      // ************ 新增一级标签 是否启用
      Enable: false,

      // 新增锁
      label: 1,
      // 新增子类锁
      childrenlabel: 1,

      // 3.0分类序号
      options: [
        {
          value: 1,
          label: "一级",
        },
        {
          value: 2,
          label: "二级",
        },
        {
          value: 3,
          label: "三级",
        },
      ],
    };
  },

  created() {
    let queryInfo = this.$route.params.queryInfo;
    if (queryInfo != undefined) {
      this.queryInfo = queryInfo;
    }

    // 获取所有标签
    this.getDataList();

    // 点击事件
    this.keyupSubmit();
  },

  mounted() {
    // 在这里你想初始化的时候展开哪一行都可以了
    let item_key = sessionStorage.getItem("label_key");

    if (!item_key) return false;

    setTimeout(() => {
      this.expands.push(this.tableData[item_key].id);
      this.childrenData = this.tableData[item_key].bookTagChildrenList;
    }, 1000);
  },
  methods: {
    // 绑定Enter事件
    keyupSubmit() {
      document.onkeydown = (e) => {
        let _key = window.event.keyCode;
        if (_key === 13) {
          if (this.dia == true) {
            this.updataBook();
          } else if (this.dia2 == true) {
            this.updataBook2();
          }
        }
      };
    },

    // 弹出层关闭 重置 显示按钮
    hideEnable() {
      this.Enable = false;
    },

    // ******************************************************
    async getDataList() {
      const { data } = await this.$http.get(
        "getBookTags/" + this.queryInfo.current + "/" + this.queryInfo.size
      );
      data.data.records.forEach((v) => {
        v.ChirendShow = true;
        if (v.isShow == 1) {
          v.isShow = false;
          v.bookTagChildrenList.forEach((s) => {
            //是否显示 0是 1否
            if (s.isShow == 1) {
              s.isShow = false;
            } else {
              s.isShow = true;
            }
          });
        } else {
          v.isShow = true;
          v.bookTagChildrenList.forEach((s) => {
            //是否显示 0是 1否
            if (s.isShow == 1) {
              s.isShow = false;
            } else {
              s.isShow = true;
            }
          });
        }
      });
      this.total = data.data.total;
      this.loading = false;
      this.tableData = data.data.records;
      // this.tableData.forEach((v) => {
      //   v.ChirendShow = true;
      //   if (v.isShow == 1) {
      //     v.isShow = false;
      //     v.bookTagChildrenList.forEach((s) => {
      //       //是否显示 0是 1否
      //       if (s.isShow == 1) {
      //         s.isShow = false;
      //       } else {
      //         s.isShow = true;
      //       }
      //     });
      //   } else {
      //     v.isShow = true;
      //     v.bookTagChildrenList.forEach((s) => {
      //       //是否显示 0是 1否
      //       if (s.isShow == 1) {
      //         s.isShow = false;
      //       } else {
      //         s.isShow = true;
      //       }
      //     });
      //   }
      // });
      this.loading = false;
    },

    // 新增分类
    upBookShelf() {
      this.lodingStyle = 0;
      this.lodingtext = "新建类别";
      this.inoputNane = "类别名称";
       this.form.level= 3,
      this.dia = true;
      this.editLabel = 0;
    },

    // 5.0弹出层提交按钮
    async updataBook() {
      if (this.label < 1) return false;
      this.label = -1;

      let Enable = this.Enable; //获取标签启用状态
      let isShow; //是否显示 0是 1否
      if (Enable) {
        isShow = 0;
      } else {
        isShow = 1;
      }
      // if (Enable === true) {
      //   if (this.form.tagSort == null || this.form.level == null) {
      //     this.label = 1;
      //     return this.$message.error("启用时，序号与等级不能为空");
      //   }
      // }
      sessionStorage.setItem("label_key", this.item_key);
      // 5.1编辑书架
      if (this.form.name == undefined || this.form.name == "") {
        this.$message.error("输入内容不能为空");
        this.label = 1;
        return;
      }
      if (this.lodingStyle == 1) {
        let params = {};
        params.isShow = isShow;
        params.name = this.form.name;
        params.id = this.form.id;
        params.tagSort = this.form.tagSort; //序号
        params.level = this.form.level; //等级
        if (Enable === true) {
          if (
            this.form.tagSort == null ||
            this.form.tagSort == "" ||
            this.form.level == null
          ) {
            this.label = 1;
            return this.$message({
              type: "error",
              message: "请先输入等级与序号，再启用该分类",
            });
          }
        }

        const { data: res } = await this.$http.post("updateBookTag", params);
        let msg = "编辑成功";
        let err = "失败";
        if (res.ok) {
          this.tableData[this.item_index].name = this.form.name;
          this.$message.success(msg);
          this.getDataList();
          this.dia = false;
          this.label = 1;
        } else {
          this.label = 1;
          this.$message.error(err);
        }

        // 添加子类
      } else if (this.lodingStyle == 2) { 
        let prams = {};
        prams.isShow = isShow;
        prams.parentId = this.item.id;
        prams.name = this.form.name;
        prams.level = this.form.level;
        prams.tagSort = this.form.tagSort;
        if (Enable) {
          if (this.form.tagSort == "" || this.form.tagSort == null) {
            this.label = 1;
            return this.$message({
              type: "error",
              message: "请先输入等级与序号，再启用该分类",
            });
          }
        }
        const { data: res } = await this.$http.post("createBookTag", prams);

        let msg = "编辑成功";
        let err = "失败";
        let partition = "fenqu";
        prams.id = res.data;
        prams.inventory = res.inventory;
        prams.lastTime = res.lastTime;
        prams.isShow = this.Enable; //是否显示该标签
        this.item.bookTagChildrenList.push(prams);
        this.$set(this.tableData, this.item_key, this.item);
        // let tableData = this.tableData;
        // let index = this.item_key;
        // let item = prams;
        // console.log(item);
        // for (let i = 0; i < tableData.length; i++) {
        //   const v = tableData[i];
        //   if (i == index) {
        //     console.log(i);
        //     console.log(index);
        //     console.log(v);
        //     v.bookTagChildrenList.push(item);
        //   }
        // }
        this.dia = false;
        this.label = 1;
        // this.elmMessage(res, msg, err, partition, prams);

        // 新增类别
      } else if (this.lodingStyle == 0) {
        let params = this.form;
        params.isShow = isShow;

        if (this.form.isShow == 0) {
          if (this.form.tagSort == undefined || this.form.level == undefined) {
            this.label = 1;
            return this.$message({
              type: "error",
              message: "请先输入等级与序号，再启用该分类",
            });
          }
        }
        const { data: res } = await this.$http.post("createBookTag", this.form);
        let msg = "添加成功";
        let err = "失败";
        if (res.ok) {
          this.getDataList();
          this.dia = false;
          this.$message.success(msg);
          // this.tableData.push(item);
          this.label = 1;
        } else {
          this.$message.error(err);
        }
      }
    },

    async updataBook2() {
      if (this.childrenlabel < 1) return false;
      this.onSonItemSuo = -1;
      // 5.1编辑书架
      let Enable = this.Enable; //获取标签启用状态
      let isShow; //是否显示 1是 0否
      if (Enable) {
        isShow = 0;
      } else {
        isShow = 1;
      }
      this.form2.isShow = isShow;
      if (Enable) {
        console.log(this.form2.tagSort);
        if (this.form2.tagSort === "" || this.form2.tagSort == null) {
          this.label = 1;
          return this.$message.error("启用时，序号不能为空");
        }
      }

      if (this.form2.name == undefined || this.form2.name == "") {
        this.$message.error("输入内容不能为空");
        this.childrenlabel = 1;
        return;
      }
      let data = {};
      data.isShow = this.form2.isShow;
      data.name = this.form2.name;
      data.id = this.form2.id;
      data.parentId = this.movechiren.region;
      data.tagSort = this.form2.tagSort; //序号
      const { data: res } = await this.$http.post("/updateBookTag", data);
      let msg = "编辑成功";
      let err = "失败";
      this.label = 1;
      console.log(this.movechiren.region);
      if (res.ok) {
        // 排序
        let fen_key = this.childrenData.findIndex((v) => {
          return v.id == this.form2.id;
        });
        let items = this.childrenData[fen_key];
        items.tagSort = this.form2.tagSort - 0;
        this.childrenData.splice(fen_key, 1);
        let item_index = this.childrenData.findIndex((v) => {
          return v.tagSort > items.tagSort;
        });
        console.log(item_index);
        if (item_index == -1) {
          this.childrenData.push(items);
        } else {
          if (items.tagSort == 0) {
            items.tagSort = "";
          }
          this.childrenData.splice(item_index, 0, items);
        }

        //

        if (data.parentId == this.form2.parentId) {
          let fen_key = this.childrenData.findIndex((v) => {
            return v.id == this.form2.id;
          });
          if (this.form2.isShow == 1) {
            this.form2.isShow = false;
          } else {
            this.form2.isShow = true;
          }
          this.$set(this.childrenData, fen_key, this.form2);
          this.$message.success(msg);
          this.childrenlabel = 1;
        } else {
          let fen_key = this.childrenData.findIndex((v) => {
            return v.id == this.form2.id;
          });
          this.childrenData.splice(fen_key, 1);
          let togitem = this.tableData.findIndex((v) => {
            return v.id == data.parentId;
          });
          let destination_item = this.form2;
          destination_item.parentId = this.movechiren.region;
          if (this.tableData[togitem] == undefined) {
            // this.getDataList(); //跨页了 全部数据中无法获取对应数据
            this.dia3 = false;
          } else {
            this.tableData[togitem].bookTagChildrenList.push(destination_item);
          }
          this.$set(this.tableData, togitem, this.tableData[togitem]);
        }
      } else {
        this.$message.error(err);
      }

      this.dia2 = false;
      this.childrenlabel = 1;
    },

    // this.$message  方法
    elmMessage(res, msg, err, y = "Refresh", x = " ") {
      // let taht
      if (res.ok) {
        this.dia = false;
        if (y == "Refresh") {
          this.getDataList();
        } else {
          this.item.bookTagChildrenList.push(x);
          this.$set(this.tableData, this.item_key, this.item);
        }

        this.$message.success(msg);
      } else {
        this.$message.error(err);
      }
    },

    // 编辑书架 1
    showloeding(item, index) {
      this.lodingtext = "编辑类别";
      this.inoputNane = "类别名称";
      this.lodingStyle = 1;
      this.item_index = index;
      this.form = JSON.parse(JSON.stringify(item));
      this.dia = true;
      this.Enable = this.form.isShow;
      this.editLabel = 0; //编辑大类，1
    },

    // 添加子类 2
    handleToogleExpand(item, index) {
      this.lodingtext = "添加标签";
      this.inoputNane = "标签名称";
      this.lodingStyle = 2;

      // 当前点击项
      this.item = item;
      this.item_key = index;

      this.form = JSON.parse(JSON.stringify(item));
      this.form.name = "";
      this.form.tagSort = "";
      this.dia = true;
      this.ParentTagList(); //获取类别列表
      // this.Enable = this.form.isShow;
      this.movechiren.region = item.id; //获取类别列表默认选中项
      this.editLabel = 1; //编辑大类，1
    },

    // 编辑分区
    upchirenloeding(item) {
      this.lodingtext = "编辑标签";
      this.inoputNane = "标签名称";
      this.lodingStyle = 3;
      this.form2 = JSON.parse(JSON.stringify(item));
      this.dia2 = true;
      this.Enable = this.form2.isShow;
      this.ParentTagList(); //获取类别列表
      this.movechiren.region = item.parentId; //获取类别列表默认选中项
    },

    // 删除 节点
    async delchirenloeding(item) {
      const { data } = await this.$http.get("findRemoveBeforeInfo/" + item.id);
      // type	0可以删除 1父类 2标签已关联书籍
      if (data.code == 0) {
        this.$confirm("是否删除该标签?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          confirmButtonClass: "btn_clas",
        }).then(() => {
          this.removeChildrenLabel(0, item.id); //0可以删除
        });
      } else if (data.code == 2) {
        this.$confirm(data.message, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          confirmButtonClass: "btn_clas",
        }).then(async () => {
          this.removeChildrenLabel(2, item.id); //2标签已关联书籍
        });
      }

      // this.fen_delitem = item;
      // this.$confirm("是否删除该子标签?", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      //   confirmButtonClass: "btn_clas",
      // })
      //   .then(async () => {
      //     const { data } = await this.$http.get(
      //       "findRemoveBeforeInfo/" + item.id
      //     );
      //     // type	0可以删除 1父类 2标签已关联书籍
      //     if (data.code == 0) {
      //       this.removeChildrenLabel(0, item.id); //
      //     } else if (data.code == 2) {
      //       this.$confirm(data.message, "提示", {
      //         confirmButtonText: "确定",
      //         cancelButtonText: "取消",
      //         type: "warning",
      //         confirmButtonClass: "btn_clas",
      //       }).then(async () => {
      //         this.removeChildrenLabel(2, item.id); //已关联书籍
      //       });
      //     } else {
      //       this.$message({
      //         type: "error",
      //         message: "删除失败!",
      //       });
      //     }
      //   })
      //   .catch(() => {
      //     this.$message({
      //       type: "info",
      //       message: "已取消删除",
      //     });
      //   });
    },

    // 删除 子标签
    async removeChildrenLabel(type, id) {
      const { data } = await this.$http.get(
        "removeBookTagById/" + id + "/" + type
      );
      if (data.ok) {
        let fen_key = this.childrenData.findIndex((v) => {
          return v.id == id;
        });
        this.childrenData.splice(fen_key, 1);
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      } else {
        this.$message({
          type: "error",
          message: "删除失败!",
        });
      }
      // this.getDataList(); 刷新页面导致节点消失 ，禁止刷新
    },

    // 删除 分类
    async dellogding(item) {
      const { data } = await this.$http.get(
        "findRemoveBeforeInfo/" + item.id + "/"
      );
      // console.log(data);
      if (data.code == 1) {
        this.$message({
          type: "none",
          message: "该类别下存在标签。请先清空标签再删除类别",
        });
      } else {
        this.$confirm("是否删除该类别?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          confirmButtonClass: "btn_clas",
        }).then(async () => {
          this.removelabel(0, item.id); //可以删除
        });
      }

      // this.$confirm("是否删除该标签?", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      //   confirmButtonClass: "btn_clas",
      // })
      //   .then(async () => {
      //     const { data } = await this.$http.get(
      //       "findRemoveBeforeInfo/" + item.id + "/"
      //     );
      //     // type	0可以删除 1父类 2标签已关联书籍
      //     if (data.code == 0) {
      //       this.removelabel(0, item.id); //可以删除
      //     } else if (data.code == 1) {
      //       this.$message({
      //         type: "none",
      //         message: "该标签下存在子标签。请先清空子标签在删除",
      //       });
      //     } else if (data.code == 2) {
      //       this.$confirm(data.message, "提示", {
      //         confirmButtonText: "确定",
      //         cancelButtonText: "取消",
      //         type: "warning",
      //         confirmButtonClass: "btn_clas",
      //       }).then(async () => {
      //         this.removelabel(2, item.id); //已关联书籍
      //       });
      //     }
      //   })
      //   .catch(() => {
      //     this.$message({
      //       type: "info",
      //       message: "已取消删除",
      //     });
      //   });
    },

    // 删除标签
    async removelabel(type, id) {
      const { data } = await this.$http.get(
        "removeBookTagById/" + id + "/" + type
      );
      if (data.ok) {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      } else {
        this.$message({
          type: "error",
          message: "删除失败!",
        });
      }
      this.getDataList();
    },

    //替换加减符号
    pushitem(row, index) {
      this.tableData.forEach((v) => {
        if (row.id == v.id) {
          v.ChirendShow = !v.ChirendShow;
        } else {
          v.ChirendShow = true;
        }
      });

      let $table = this.$refs.table;
      this.childrenData = row.bookTagChildrenList;
      for (let i = 0; i < this.childrenData.length; i++) {
        let v = this.childrenData[i];
        // console.log(v);
        // 启用
        // if (v.isShow == 1) {
        //   v.isShow = false;
        // } else {
        //   v.isShow = true;
        // }
        // 目录
        if (!v.inventory) {
          this.childrenData[i].inventory = 0;
        }
      }
      // this.getDataList();
      this.tableData.map((item) => {
        if (row.id != item.id) {
          $table.toggleRowExpansion(item, false);
        }
      });
      $table.toggleRowExpansion(row);
    },

    // 切换标签显示状态
    async switchLabel(item) {
      console.log(item);
      let params = {};
      if (item.parentId == null) {
        if (item.level == null || item.tagSort == null) {
          item.isShow = false;
          return this.$message({
            type: "error",
            message: "没有等级与序号，无法启用",
          });
        }
      } else {
        params.parentId = item.parentId;
        if (item.tagSort === null || item.tagSort === "") {
          item.isShow = false;
          return this.$message({
            type: "error",
            message: "序号为空，无法启用",
          });
        }
      }

      let isShow;
      if (item.isShow) {
        isShow = 0;
      } else {
        isShow = 1;
      }
      params.isShow = isShow;
      params.id = item.id;
      params.name = item.name;
      params.level = item.level;
      params.tagSort = item.tagSort;
      params.updateUser = item.updateUser;
      const { data } = await this.$http.post("updateBookTag", params);
    },

    // 获取一级标签集合
    async ParentTagList() {
      const { data } = await this.$http.get("getParentTagList");
      this.TagList = data.data;
      // console.log(this.TagList);
    },

    // 未分类 标签 置换
    movechirenloeding(item) {
      this.dia3 = true; //未分类标签弹出层
      // console.log(item);
      // 一级标签集合
      this.ParentTagList();
      // this.movechiren = item;
      this.movechiren.id = item.id;
      this.movechiren.name = item.name;
      this.movechiren.region = item.parentId; //获取类别列表默认选中项
    },

    // 保存切换类表信息
    async upmovechiren() {
      let params = {};
      params.id = this.movechiren.id;
      params.name = this.movechiren.name;
      params.isShow = 1;
      params.parentId = this.movechiren.region;
      // console.log(params);
      // const { data } = await this.$http.post("updateParentId", params);
      const { data } = await this.$http.post("updateBookTag", params);

      // console.log(data);
      if (data.code == 0) {
        this.$message({
          type: "success",
          message: "移出分类成功",
        });
        this.dia3 = false; //未分类标签弹出层
        let fen_key = this.childrenData.findIndex((v) => {
          return v.id == this.movechiren.id;
        });
        this.childrenData.splice(fen_key, 1);

        // 筛选出对应的一级分类项。将移动项push进去
        // id: 218
        // isShow: false
        // name: "万盛街"
        // parentId: 220
        let chclid_item = {};
        chclid_item.id = params.id;
        chclid_item.isShow = false;
        chclid_item.parentId = params.parentId;
        chclid_item.name = this.movechiren.name;
        for (let i = 0; i < this.tableData.length; i++) {
          const element = this.tableData[i];
          // console.log(element);
          if (element.id == params.parentId) {
            element.bookTagChildrenList.push(chclid_item);
          }
        }

        // this.getDataList();
      } else {
        this.$message({
          type: "error",
          message: "移出分类失败",
        });
      }
    },

    // 效验
    ValidateSort(status) {
      if (status == 1) {
        let tagSort = this.form.tagSort;
        let value = tagSort.replace(/[^\d]/g, "");
        if (value.length <= 3) {
          this.form.tagSort = value;
        } else {
          this.form.tagSort = "";
        }
      } else if (status == 2) {
        let tagSort = this.form2.tagSort;
        let value = tagSort.replace(/[^\d]/g, "");
        if (value.length <= 3) {
          this.form2.tagSort = value;
        } else {
          this.form2.tagSort = "";
        }
      }
    },

    // 监听分页跳转
    handleSizeChange(val) {
      this.queryInfo.size = val;
      this.getDataList();
    },

    handleCurrentChange(val) {
      this.queryInfo.current = val;
      this.getDataList();
    },
  },
};
</script>


<style lang="scss" scoped>
.demo-table-expand {
  font-size: 0;
}

.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}

.btn_right {
  color: #2689f7;
}

::v-deep .el-table__expanded-cell {
  padding: 0;
  padding-left: 2%;
}

.tagSort {
  width: 51%;
}
</style>