<template>
  <div id="app">
    <!-- keep-alive保留页面。不刷新 -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  
};
</script>

<style>
#app {
  margin: 0;
  padding: 0;
  height: 98vh;
}
</style>
