import Cookies from 'js-cookie'

// 存储的token名
const TokenKey = 'Admin-Token'
let queryInfo = {}


let userDetails = {}



//3.1 存储  从 书籍详情 -> 详情页 id
export function setbookid(id) {
    return Cookies.set("bookid", id)
}


// 3.1 获取 从 书籍详情 -> 详情页 id
export function getbookid() {
    return Cookies.get("bookid")
}

// 4.1 存储 从 书籍详情 -> 详情页 queryInfo 防止页面刷新时，返回无法定位到 书籍详情页
export function setQueryInfo(query) {
    let querys = query
    return Cookies.set(queryInfo, querys)
}

//  4.1.1 获取 从 书籍详情 -> 详情页 queryInfo 防止页面刷新时，返回无法定位到 书籍详情页
export function getQueryInfo() {
    return Cookies.get(queryInfo)
}


// 1.1.1清空token
export function removeUserDetails() {
    let date = new Date(); //获取当前时间 
    let expiresHours = -1; //将date设置为n小时以后的时间 
    date.setTime(date.getTime() + expiresHours * 12 * 3600 * 1000); //格式化为cookie识别的时间 
    document.cookie = "token=" + '' + ";expires=" + date.toGMTString(); //设置cookie 
    sessionStorage.removeItem("booKlistQuery");
    sessionStorage.removeItem("activePath");
    return Cookies.remove(userDetails)
}

// 4.2当前微信登陆用户信息
export function setUserDetails(userVX, hours) {
    let date = new Date(); //获取当前时间 
    let expiresHours = parseInt(hours); //将date设置为n小时以后的时间 
    date.setTime(date.getTime() + expiresHours * 12 * 3600 * 1000); //格式化为cookie识别的时间 
    let token = encodeURI(userVX.token)
    document.cookie = "token=" + token + ";expires=" + date.toGMTString(); //设置cookie 
    sessionStorage.removeItem("booKlistQuery");
    sessionStorage.removeItem("activePath");
    
    return Cookies.set(userDetails, userVX)
}

//  4.2 
export function getUserDetails() {
    return Cookies.get(userDetails)
}

export function getToken() {
    var cookieArr = document.cookie.split('; ');
    for (var i = 0; i < cookieArr.length; i++) {
        var arr = cookieArr[i].split('=');
        if (arr[0] === 'token') {
            return decodeURI(arr[1]);
        }
    }
    return false;
}


// 存储的单项标签
export function setRuleitem(item) {
    return Cookies.set("Ruleitem", item)
}

// 获取存储的单项标签
export function getRuleitem() {
    return Cookies.get("Ruleitem")
}

// 存储标签页面 type集合
export function setRuleList(RuleList) {
    return Cookies.set("RuleList", RuleList)
}

// 获取标签页面 type集合
export function getRuleList() {
    return Cookies.get("RuleList")
}





