import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入全局css
import "./assets/css/globel.css"
// 引入全局icon
import "./assets/font/iconfont.css"
import './plugins/element.js'

//ajax
import request from "./axios/axios.js";

// 将axios挂载到vue的原型中，可通过 this.$http.get等 全局使用 
Vue.prototype.$http = request

// 引入二维码生成器
import QRCode from 'qrcodejs2';
// 引入连接打印机插件
import Print from 'vue-print-nb'
import html2canvas from "html2canvas"
import print from "print-js"
Vue.use(Print);

// 屏幕适配自动将px转化为rem。正常写px
import 'amfe-flexible/index.js'

import "./assets/font/iconfont.css"
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)

// 防止网速过慢时，用户爆点按钮 el-dialog可拖拽
import {preventReClick,dialogDrag} from './service/utils'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
