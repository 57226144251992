<template>
  <!-- 首页书单 -->
  <div>
    <el-row>
      <el-col :span="24" class="edit_btn_Wrap">
        <div class="EB_Style">编辑书单</div>
        <div class="upbook" @click="addBook">添加书单</div>
      </el-col>
    </el-row>
    <template class="table_top">
      <el-table :data="tableData" style="width: 100%" height="65vh">
        <el-table-column prop="bookListTitle" label="书单名称">
        </el-table-column>
        <el-table-column prop="bookListInfo" label="书单描述">
        </el-table-column>
        <el-table-column prop="bookListSort" label="序号"> </el-table-column>
        <el-table-column prop="isShow" label="启用状态">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.check"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="isShow(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作">
          <template slot-scope="scope">
            <el-button
              @click="handleClick(scope.row)"
              type="text"
              size="small"
              style="color: #0bd46a"
              >编辑</el-button
            >
            <el-button
              v-if="scope.row.prohibit"
              @click.native.prevent="deleteRow(scope.row)"
              type="text"
              size="small"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      check_hd_btn: true,
      // table数据
      tableData: [],

      // 编辑弹出层
      dialogVisible: false,

      // 排序
      item: {
        page: 10,
      },

      // 分页
       queryInfo: {
        current: 1,
        size: 10,
      },

      id: "", //书单模块id
    };
  },
  mounted() {
    this.gethandBook();
  },
  methods: {
    // 获取书单
    async gethandBook() {
      const { data } = await this.$http.get("getBannerModuleList/1/10");
      let fixdBook = data.data.records;
      let item = fixdBook.find((v) => {
        return v.moduleType == 2;
      });
      this.id = item.id; //书单模块id
      this.getBookLists(item.id);
    },

    async getBookLists(id) {
      const { data } = await this.$http.get("getBannerModuleDetails/" + id);
      let slideshowList = data.data.bookListsVOS;
      for (let i = 0; i < slideshowList.length; i++) {
        const item = slideshowList[i];
        item.prohibit = true;
        if (item.isShow == 1) {
          item.check = false;
        } else {
          item.check = true;
        }
      }

      let arr = slideshowList.filter((item) => item.isShow == 0);
      if (arr.length <= 1) {
        // 根据id获取项
        for (let i = 0; i < arr.length; i++) {
          const element = arr[i];
          element.prohibit = false;
          let index = slideshowList.findIndex((v) => v.id == arr[i].id);
          slideshowList[index] = arr[i];
        }
      }
      this.arrShow = arr; //启用书单的数量，最少不能低于1本
      this.tableData = slideshowList;
    },

    // 1.顶部css切换
    editImg() {
      this.check_hd_btn = true;
    },

    editBook() {
      this.check_hd_btn = false;
    },

    // 2.编辑书单--- 排序编辑
    handleClick(item) {
      item.BookStatus = 1; //1为编辑
      item.bannerModuleId = this.id; //模块id
      this.$router.push({ path: "/EFBook", query: item });
    },

    // 2.1添加书单 -- 书单
    addBook() {
      let query = {};
      query.BookStatus = 0; //0为新增
      query.bannerModuleId = this.id; //模块id
      this.$router.push({ path: "/EFBook", query: query });
    },

    //2.1-1确认排序
    sortSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          return false;
        }
      });
    },
    //2.1-2重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    // 2.1-3删除表单
    deleteRow(item) {
      // console.log(item);
      this.$confirm("是否删除该书单？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          // console.log(item.id);
          const { data } = await this.$http.get("removeBookList/" + item.id);
          if (data.ok) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getBookLists(this.id);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    // 是否启用
    // 启用
    async isShow(item) {
      if (this.arrShow.length <= 1) {
        let index = this.arrShow.findIndex((v) => {
          return v.id == item.id;
        });
        if (index > -1) {
          item.check = true;
          this.$message.error("至少存在一条启用的书单");
        }
      }
      if (item.check) {
        item.isShow = 0;
        delete item.check;
      } else {
        item.isShow = 1;
        delete item.check;
      }
      const { data } = await this.$http.get(
        "getBookListWhetherIsInfo/" + item.id
      );
      if (data.ok) {
        const { data } = await this.$http.get(
          "openShowBookList/" + item.id + "/" + item.isShow
        );
        this.getBookLists(this.id);
      }else{
        this.$message.error("该书单下没有书籍，无法启用")
      }
    },
  },
};
</script>

<style lang="scss" scoped>
//2 表格列表
.box-card {
  margin-top: 10px;

  .edit_btn_Wrap {
    display: flex;
    justify-content: space-between;

    .EB_Style {
      font-weight: 700;
      padding: 5px 0;
    }

    .upbook {
      padding: 5px 14px;
      color: #fff;
      text-align: center;
      background: #ff1932;
    }
  }
}
</style>