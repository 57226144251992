// 引入二维码生成插件
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      // 效验子书 
      sonRules: {
        title: [{ required: true, message: "请输入书籍名称", trigger: "blur" }],
        author: [
          { required: true, message: '请输入作者名称', trigger: 'blur' },
        ],
        price: [
          { required: true, message: '请输入定价', trigger: 'blur' },
        ],
      },

      RenamingRules: {
        title: [{ required: true, message: "请输入书籍名称", trigger: "blur" }],
        author: [
          { required: true, message: '请输入作者名称', trigger: 'blur' },
        ],
        price: [
          { required: true, message: '请输入定价', trigger: 'blur' },
        ],
      },
      emptyText: "暂无数据",
      // 总书本
      // value: {},
      // 书籍是否可以编辑。默认true。不可编辑
      // readonly: true,
      // 书籍编辑弹框
      // dialogFormVisible: false,
      // ****************************************************************************

      //ISBN码 
      ISBN: "",

      // 入库 弹出层
      addDialog: false,
      loading: true,

      //tabs切换默认选中项 
      activeName: 'first',



      // 列表数据
      tableData: [

      ],


      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },

      // 总本数
      total: 0,

      // 当前点击书本详情
      details: {},

      // 2 公共数据存储数据.不渲染
      // public_details: [],

      // 1 弹出层 单书数据
      book_details: {},

      // 3 弹出层 父丛书数据
      Parent_details: [],

      // 4 弹出层 右侧单条子书籍数据
      son_item_details: {},

      // 5点击子书籍时添加获取整条数据
      son_value: {},

      // 6 提交子书籍时修改子书籍Bookid
      bookId: "",

      // 7 打印二维码时显示的书籍名称
      book_name: "",

      // 8 生成的二维码图片
      QR_img: "",

      // 9 改名
      revise_Name: {

      },

      // 10 v-for选中项
      isactive: -1,


      // 11 判断字符 子集和父级 true为父级
      Parent_son: true,

      // parentId 提交新建子书籍时父书籍级的唯一标识
      parentId: "",

      // 4.子集 书籍数据 
      son_details: [],
      son_details_length: "",


      // 确认按钮添加一个锁
      onSubmitSou: 1,
      onSonItemSuo: 1,

      // ********************************

      // 判断是新增的子级还是 拉取得子级
      son_shend: true,
      // 添加完子级后隐藏
      son_add_hide: true,

      // 判断字段。判断是回车打开弹窗还是编辑打开弹窗
      loding: true,

      // 弹出层 修改单书 && 丛书
      dialogVisible: false,



      // ******************************************
      //当前点击的子项下标
      index_v: "",
      // 12 禁用 enter 锁 
      suo: 1,
      // 13  完成 入库锁
      StorageLock: 0,
      // 14 改名锁
      reviseBookName_suo: 1,


      // 14.去除this指向的isbn码
      isbncode: "",

      // 15 判断isbn长度是否正常 1正常 2错误
      IsbnLeght: 1,

    };

  },
  created() {
    this.emptyText = "加载中";
    if (this.tableData.length == 0) {
      this.emptyText = "暂无数据";
    }
  },

  // 页面加载完，调用
  mounted() {
    // 获取焦点
    this.Focus();
    // 绑定鼠标事件
    this.keyupSubmit();
    //1 首先进入页面的时候需要监听浏览器的后退按钮，之后在操作的时候执行函数,在mounted中挂载去监听这个事件，并在methods里面添加函数，用来判断执行的条件

    var _this = this;
    window.onbeforeunload = function (e) {
      if (_this.$route.fullPath == '/manipulate') {
        e = e || window.event;
        // 兼容IE8和Firefox 4之前的版本
        if (e) {
          e.returnValue = "关闭提示";
        }
        return "关闭提示";
      } else {
        window.onbeforeunload = null;
      }
    };
  },


  // 2：需要在退出页面的时候销毁这个监听事件
  destroyed() {
    window.removeEventListener('popstate', this.back, false)
  },

  // 监听入库书籍数量
  watch: {
    total: {
      handler(newValue, oldValue) {
        if (oldValue > 48) {
          return this.$message.error("一次最大入库为50本，请注意！！！");
        }
      },
    },
  },

  // 路由跳转确认
  beforeRouteLeave(to, from, next) {
    const answer = window.confirm("当前页面数据未保存，确定要离开？");
    if (answer) {
      next();
    } else {
      next(false);
    }
  },

  methods: {
    // 1.页面加载时，获取焦点
    Focus() {
      this.ISBN = "";
      this.suo = 1,  //可以点击
        this.$refs["atlas-name-input"].focus();
    },

    animateFocus() {
      // this.ISBN = "";
      this.suo = 1; //可以点击
    },

    animateBlur() {
      this.suo = 2;  //禁止点击
    },

    // 生成时间
    getCurrentTime() {
      //获取当前时间并打印
      var _this = this;
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
      let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
      return _this.gettime = +hh + ':' + mf + ':' + ss;
    },



    // 1.1绑定enter事件
    keyupSubmit() {
      document.onkeydown = (e) => {
        let _key = window.event.keyCode;

        // let isbncode=JSON.stringify(this.ISBN)
        // isbncode=JSON.parse(isbncode)
        // this.isbncode=isbncode;
        // this.suo 默认为1 ，允许根据isbn请求数据， 为2时防止重复触发enter键盘事件，禁止调用isbn请求数据
        if (this.suo === 1) {
          if (_key === 13) {
            let lenght = this.ISBN.length;
            if (lenght == 13 || lenght == 10) {
              this.getshongshu(this.ISBN)
              // 1.1.1修改父书籍和子书籍的选中状态
              this.Parent_son = true
              this.isactive = -1
              this.son_item_details = {}
            } else {
              return this.$message.error("isbn码的长度应为10 或者 13 位")
            }
            this.son_item_details = {}
            this.loding = true
          }
        }

      }
    },







    //1.2 删除列表内单条数据
    removeById(value) {
      this.$confirm('删除该书籍, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let list = this.tableData;
        let item_list = list.findIndex((v) => v == value);
        if (item_list == -1) {
          this.$message.error("删除失败")
        } else {
          list.splice(item_list, 1);
          this.total = this.tableData.length;
          // 清空右侧书籍资料
          this.details = {}
          this.$message.success("删除成功")
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },
    // 1.3 点击单条数据时，替换左侧数据
    getMessageData(value) {
      this.details = value;
    },

    // 1.2点击确定 图书暂存列表(图书清单)
    onSubmit() {
      this.$refs.bookDetails.validate(async (valid) => {
        if (!valid) return this.$message.error("请输入姓名、作者、价格");
        // 添加点击锁
        if (this.onSubmitSou < 1) return false;
        this.onSubmitSou = -1
        this.isbnlenght(this.book_details.isbn)
        if (this.IsbnLeght == 2) return;
        // 添加点击锁 end

        // 发送请求
        this.book_details.isChildren = 0; //************丛书标记。丛书为1，单书为0
        this.book_details.isSaveOrUpdate = 0;  //  *********修改父书信息 和 添加单书共用一个接口 根据字段  判断 0添加   1修改
        if (!this.book_details.bookAlias) {
          this.book_details.bookAlias = ""
        }
        // toFixed(2)
        if (this.book_details.price <= 0) {
          this.onSubmitSou = 1
          this.$message.error("定价金额不正确，保存失败");
          return
        }
        const { data } = await this.$http.post(
          "saveBookDetails",
          this.book_details
        );
        this.book_details.qrCode = "http://jdsf.cc/t?t=b&i=" + data.data
        this.book_details.id = data.data
        if (data.code === 0) {
          this.onSubmitSou = 1
          this.printQcode(this.book_details)
          this.ISBN = ""
          if (this.loding) {
            let time = this.getCurrentTime()
            this.book_details.time = time

            this.tableData.unshift(this.book_details)
            this.total = this.tableData.length
            this.ISBN = ""
            this.screen(this.book_details, 1)
          }

          // 关闭弹窗赋值
          this.addDialog = false
          this.details = this.book_details;
        } else {
          this.onSubmitSou = 1
          return this.$message.error(data.message)
        }
      })




    },

    // 1.3点击取消 图书取消暂存列表 
    onCancel() {
      this.addDialog = false
      this.ISBN = ""
    },


    // input改变金额时，判断类型。保留两位小数
    hedlprice(num) {
      let price = this.pxPrice(num)
      this.book_details.price = price
    },

    hedlprice1(num) {
      let price = this.pxPrice(num)
      this.Parent_details.price = price
    },
    hedlprice2(num) {
      let price = this.pxPrice(num)
      this.son_item_details.price = price
    },

    hedlprice3(num) {
      let price = this.pxPrice(num)
      this.details.price = price
    },




    // ******************************************************


    // 2.0弹出层tab切换时
    async handleClick(event) {
      // 丛书
      if (event.name == "second") {
        // 2.0.1将 父级数据 （单书数据） 赋值g给Parent_details
        // this.Parent_details = this.book_details;
        // 2.0.2将父级数据存储在公共部分，切换时缓存下来
        // this.public_details = this.book_details
        // 2.0.3将单书数据清空
        // this.book_details = {}
        this.activeName = "second"
        this.Parent_son = true
        this.isactive = -1
        this.son_add_hide = true
        this.getChild()

      } else {
        // 2.0.1将父级数据清空
        // this.Parent_details = [];
        this.activeName = "first"
        // 2.0.2将公共缓存数据赋值-》单书数据
        // this.book_details = this.public_details

      }
    },

    // 2.0.1拉取丛书数据 
    async getChild() {
      // 重新调用接口。并且渲染数据
      const { data } = await this.$http.get('getChildrenBookByISBN/' + this.book_details.isbn)
      // isIncludeChildren 为 1 时暂无丛书，显示单书，0 时有丛书
      this.Parent_details = data.data;
      this.son_details = this.Parent_details.bookInfoChildrenList;
      this.son_details_length = this.Parent_details.bookInfoChildrenList.length;
    },

    // 2.1父级与子集切换时
    ParentSon(else_son, value) {
      this.isactive = else_son - 1
      if (else_son == 'Parent') {
        this.Parent_son = true
      } else {
        // 拿到子项
        this.Parent_son = false
        let item = this.son_details[else_son - 1]

        //赋值当前点击子项的下标
        this.index_v = else_son - 1


        // 替换this指向
        // item = JSON.stringify(item)
        // item = JSON.parse(item);
        this.son_item_details = item


        // 拿到当前点击项的数据
        this.son_value = value
        // ***********************************************
        // 判断最新的数组长度是否大于 数据库数组长度
        if (else_son > this.son_details_length) {
          // 添加
          this.son_shend = false

        } else {
          // 修改
          this.son_shend = true

        }
        // ********************************************************
      }
    },

    // 2.2添加子书籍到丛书列表内
    SonBook() {
      // 获取缓存的父级（单条）公共public_details中的id
      // this.son_details.push(data)
      // 2.2.1将父级数据添加到子级中,将父书部分数据赋值给子书籍
      let item = {}
      item.title = this.book_details.title
      item.author = this.book_details.author
      item.id = this.book_details.id
      item.img = this.book_details.img
      item.price = this.book_details.price
      item.page = this.book_details.page
      item.cip = this.book_details.cip
      item.isbn = this.book_details.isbn
      this.son_add_hide = false
      this.son_details.push(item)


      //  2.2.2点击新增子书时，将css样式追加到此新增项，数据同时替换
      // 拿到子项
      this.Parent_son = false


      //2.2.3 新的一项存为新增
      this.son_shend = false

      //赋值当前点击子项的下标
      setTimeout(() => {
        // 下标是从 0 开始，lenght 是从1开始
        this.son_item_details = this.son_details[this.son_details.length - 1]
        this.isactive = this.son_details.length - 1
      }, 100);
    },

    handleClose() {
      this.ISBN = "";
      // 不能重置表单。会造成一直存储初始值
      this.$nextTick(() => {
        this.$refs.bookDetails.clearValidate();
        this.$refs.ParentForm.clearValidate();
        this.$refs.sonRuleForm.clearValidate();
      })
    },


    // 2.3修改 或者 提交 子书数据
    onSonItem() {
      if (this.onSonItemSuo < 1) return false;
      this.onSonItemSuo = -1;
      this.isbnlenght(this.son_item_details.isbn)
      if (this.IsbnLeght == 2) return;
      // 提交
      this.son_item_details.price = Number(this.son_item_details.price)
      this.$refs.sonRuleForm.validate(async (valid) => {
        if (!valid) {
          this.$message.error("请输入图书名称或作者名称");
          this.onSonItemSuo = 1;
          return;
        }
        if (this.son_item_details.price <= 0) {
          this.onSonItemSuo = 1;
          this.$message.error("定价金额不正确，保存失败");
          return
        }
        // 根据页面子书籍的长度判断是否是新增还是修改
        this.isbnlenght(this.son_item_details.isbn)
        if (this.IsbnLeght == 2) return;
        this.son_item_details.bookAlias = "" //书籍别名置空
        if (!this.son_shend) {
          // 新增
          this.son_item_details.parentId = this.book_details.id
          this.son_item_details.isChildren = 1; //  ***************** 为丛书添加标记 丛书为1，单书为0
          const { data } = await this.$http.post(
            "saveChildrenBookByStorage",
            this.son_item_details
          );
          if (data.code === 0) {
            this.onSonItemSuo = 1
            this.addDialog = false

            this.ISBN = ""
            this.Focus()
            let time = this.getCurrentTime()
            let items = data.data
            items.bookId = data.data.id
            items.id = data.data.qrCode
            items.time = time
            items.qrCode = "http://jdsf.cc/t?t=b&i=" + data.data.qrCode
            this.printQcode(items)
            this.tableData.unshift(items)
            this.details = items;
            this.total = this.tableData.length
          } else {
            this.onSonItemSuo = 1
            return this.$message.error(data.message)
          }

        } else {
          // 修改
          this.son_item_details.id = this.son_value.id
          const { data } = await this.$http.post(
            "updateChildrenBookByStorage",
            this.son_item_details
          );
          if (data.code === 0) {
            this.onSonItemSuo = 1
            this.addDialog = false
            this.ISBN = ""
            this.Focus()
            let time = this.getCurrentTime()
            // 将修改好的数据赋值
            let items = data.data
            items.bookId = data.data.id
            items.id = data.data.qrCode
            items.time = time
            items.qrCode = "http://jdsf.cc/t?t=b&i=" + data.data.qrCode

            this.printQcode(items)
            this.tableData.unshift(items)
            this.details = items;
            this.total = this.tableData.length
          } else {
            this.onSonItemSuo = 1
            return this.$message.error(data.msg)
          }
        }
      })


    },

    // 2.2.2取消修改子项
    onSonCancel() {
      if (this.son_details[this.index_v] != undefined) {
        this.son_item_details = this.son_details[this.index_v]
      }
      this.ISBN = ""
      this.addDialog = false
    },


    // 2.4修改父书籍信息
    async ParentItem() {
      this.$refs.ParentForm.validate(async (valid) => {
        if (!valid) return this.$message.error("请输入姓名、作者");
        this.isbnlenght(this.Parent_details.isbn)
        if (this.IsbnLeght == 2) return;

        // 验证日期是否正确
        // this.Parent_details.pubdate = this.riqdata(this.Parent_details.pubdate)
        // if (this.Parent_details.pubdate == "") return this.$message.error("日期输入错误");
        this.Parent_details.isChildren = 1; //******  丛书标记。丛书为1，单书为0
        this.Parent_details.isSaveOrUpdate = 1;  //  *********修改父书信息 和 添加单书共用一个接口 根据字段  判断 0添加   1修改
        if (!this.Parent_details.bookAlias) {
          this.Parent_details.bookAlias = ""
        }
        if (this.Parent_details.price <= 0) return this.$message.error("定价金额不正确，保存失败");
        const { data } = await this.$http.post(
          "saveBookDetails",
          this.Parent_details
        );
        if (data.code === 0) {
          return this.$message.success("修改父书籍资料成功")
        } else {
          return this.$message.error(data.message)
        }

      })

    },

    // 2.1弹出层关闭时清空弹出层内所有数据



    // 1.4 编辑时替换数据
    EditId(item, id) {
      // 替换this指向
      let items = JSON.stringify(item)
      items = JSON.parse(items);
      this.revise_Name = items
      this.dialogVisible = true
    },


    // 3.5提交入库清单
    async addBookList() {
      if (!this.StorageLock == 0) {
        return this.$message("请勿重复点击")
      }
      this.StorageLock == 1
      let bookId = this.tableData.map(v => {
        return v.bookId;
      })
      let id = this.tableData.map(v => {
        return v.id;
      })
      let qrCode = this.tableData.map(v => {
        return v.qrCode;
      })
      let result = [];
      for (let index in qrCode) {
        result.push({ bookId: bookId[index], qrCode: qrCode[index], id: id[index] });
      }

      if (this.tableData.length === 0) {
        return this.$message.error("列表不能为空");
      }


      const { data } = await this.$http.post(
        "bookStorage",
        result
      );
      // console.log(data);
      if (data.code == 0) {
        this.StorageLock == 0
        this.total = 0;
        this.tableData = [];
        this.details = {};
        this.Focus();
        return this.$message({
          message: "添加成功",
          type: "success",
        });
      } else {
        return this.$message.error(data.msg);
      }
    },


    // 1.5编辑列表内书籍名称
    reviseBookName() {
      this.$refs.Renaming.validate(async (valid) => {
        if (!valid) return this.$message.error("请输入姓名、作者");
        this.$refs["atlas-name-input"].blur();
        if (this.reviseBookName_suo < 1) return false;
        this.reviseBookName_suo = -1;
        // 存储的单条数据信息
        let _item = {}
        _item.title = this.revise_Name.title
        _item.author = this.revise_Name.author
        _item.bookId = this.revise_Name.bookId
        _item.bookAlias = this.revise_Name.bookAlias
        const { data } = await this.$http.post(
          "updateBookTitle",
          _item
        );
        this.reviseBookName_suo = 1;
        if (data.code == 0) {
          this.dialogVisible = false
          this.printQcode(this.revise_Name)
          this.screen(this.revise_Name, 2)
        } else {
          this.$message.error(data.message)
        }
      })
    },
    //  *************************  封装部分 *************************************************************************

    // 取消修改
    cancelBook() {
      this.dialogVisible = false
    },


    // 封装筛选数据
    screen(revise_Name, status) {
      // status 判断是否是拉取书籍时，还是修改书籍名称时； 1是拉取。2是修改
      let time = this.getCurrentTime()



      // console.log(this.tableData);
      for (let i = 0; i < this.tableData.length; i++) {
        // 修改书名
        if (status == 2 && revise_Name.id == this.tableData[i].id) {
          this.tableData[i].title = revise_Name.title
          this.tableData[i].bookAlias = revise_Name.bookAlias
          this.tableData[i].author = revise_Name.author
          this.tableData[i].time = time  //时间的修改
        }
        //  拉取数据时
        if (status == 1 && this.tableData[i].bookId == revise_Name.bookId) {
          this.tableData[i].title = revise_Name.title
          this.tableData[i].bookAlias = revise_Name.bookAlias
          this.tableData[i].author = revise_Name.author
        }


        // if (this.tableData[i].bookId == revise_Name.bookId) {
        //   this.tableData[i].title = revise_Name.title
        //   this.tableData[i].author = revise_Name.author

        //   // this.tableData[i].time = time  //注释掉时间的修改
        // }
      }
    },


    // 拉取书籍信息
    async getshongshu(ISBN) {
      const { data } = await this.$http.get("getBookInfoByISBN/" + ISBN)
      let res = data.data;
      // 查到书籍，返回对应信息。查不到返回一个 {}
      if (res == null) {
        this.$message.error("暂无此书籍相关信息");
        res = {}
      }

      // isIncludeChildren 为 1 时暂无丛书，显示单书，0 时有丛书
      let { isIncludeChildren } = res

      if (res.ok == false) {
        this.ISBN = "";
        return this.$message.success("该书籍" + res.message);
      }

      if (this.total >= 50) {
        return this.$message.error("书籍入录过多，请先提交");
      }
      else {
        // 弹出框的数据赋值 单书
        // console.log(res);
        this.book_details = res
        this.book_details.bookId = res.id
        // 赋值parentId
        this.parentId = res.bookId;
        // 调用丛书信息
        this.getChild()
      }

      // 显示弹窗赋值
      this.addDialog = true
      this.son_add_hide = true

      // 输入isbn打开弹窗 默认显示单书||丛书  根据isIncludeChildren  0 有子书  1是单本
      if (isIncludeChildren == 1) {
        this.activeName = "first"
      } else {
        this.activeName = "second"
      }
    },


    // 判断二维码长度
    isbnlenght(cd) {
      let length = cd.length;
      if (length == 13 || length == 10) {
        this.IsbnLeght = 1;
      } else {
        this.IsbnLeght = 2;
        return this.$message.error("isbn码的长度应为10 或者 13 位")
      }
    },



    // 生成二维码
    printQcode(result) {
      this.addDialog = false
      let https = result.qrCode;
      let QR = JSON.stringify(https);
      QR = QR.replace(/^\"|\"$/g, "");
      if (result.title) {
        this.book_name = result.title;
      } else {
        this.book_name = result.name;
      }
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: QR, // 需要转换为二维码的内容
        width: 120,
        height: 120,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
      // 复制一份
      setTimeout(() => {
        let s = this.$refs.qrCodeUrl.childNodes.length
        //  该位置赋值图片暂时无法清除，现默认取最后一条
        let img = this.$refs.qrCodeUrl.childNodes[s - 1].getAttribute("src");
        this.QR_img = img;
        this.$refs.inputResult.$el.click();
      }, 10);

    },

    // 监听日期变化
    riqdata(e) {
      var str = e;
      var parent1 = /^\d{4}-\d{1,2}/;
      var parent2 = /^\d{4}-\d{1,2}-\d{1,2}/;
      let regular1 = parent1.test(str)
      let regular2 = parent2.test(str)
      // console.log(regular1, regular2);
      if (regular1 || regular2) {
        return str
      } else {
        return "";
      }
    },



    // 导出小数点后两位
    pxPrice(num) {
      num = num.replace(/[^\d.]/g, "");  //清除“数字”和“.”以外的字符  
      num = num.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的  
      num = num.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      num = num.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');//只能输入两个小数  
      if (num.indexOf(".") < 0 && num != "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额 
        num = parseFloat(num);
      }
      return num
    }

  }

}