<template>
  <el-card>
    <el-table
      :data="tableData"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      style="width: 100%"
      ref="table"
      :highlight-current-row="true"
    >
      <el-table-column
        class="custom-tree-node"
        prop="img"
        label="图书封面"
        width="120"
      >
        <template slot-scope="scope">
          <img :src="scope.row.img" alt="" srcset="" style="width: 60px" />
        </template>
      </el-table-column>
      <el-table-column prop="title" label="书籍名称"> </el-table-column>
      <el-table-column prop="inventory" label="库存" width="140"> </el-table-column>
      <el-table-column prop="vendibilityInventory" label="可售库存" width="140">
      </el-table-column>
      <el-table-column prop="readyOnShelfSum" label="待上架" width="140">
      </el-table-column>
      <el-table-column prop="isbn" label="ISBN码"> </el-table-column>
      <el-table-column prop="lastEntryTime" label="最后入库时间">
      </el-table-column>
      <el-table-column prop="author" label="操作">
        <template slot-scope="scope">
          <el-button
            class="btn_right"
            type="text"
            icon="el-icon-edit"
            size="mini"
            @click="showloeding(scope.row)"
          >
            查看</el-button
          >
        </template>
      </el-table-column>
      <el-table-column type="expand" width="1">
        <template>
          <el-table
            :data="childrenData"
            :show-header="false"
            style="width: 100%"
          >
            <el-table-column class="custom-tree-node" prop="image" width="200">
              <template slot-scope="scope">
                <img
                  :src="scope.row.img"
                  alt=""
                  srcset=""
                  style="width: 60px"
                />
              </template>
            </el-table-column>
            <el-table-column prop="title"> </el-table-column>
            <el-table-column prop="inventory"> </el-table-column>
            <el-table-column prop="isbn"> </el-table-column>
            <el-table-column prop="lastEntryTime"> </el-table-column>
            <el-table-column prop="author">
              <template slot-scope="scope">
                <el-button
                  class="btn_right"
                  type="text"
                  icon="el-icon-edit"
                  size="mini"
                  @click="showloeding(scope.row)"
                >
                  查看</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.current"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="queryInfo.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </el-card>
</template>
<script>
// 存储当前修改项的id, 防止详情页刷新时， 页面出现空屏
import { setbookid } from "../../utils/auth.js";
export default {
  data() {
    return {
      tableData: [],

      childrenData: [],

      // 查询参数对象----分页
      queryInfo: {
        current: 1,
        size: 10,
      },
      // 遮罩层
      loading: true,
      total: 0,
    };
  },

  created() {
    //  let id = this.$route.params.id;
    let queryInfo = this.$route.params.queryInfo;
    if (queryInfo != undefined) {
      this.queryInfo = queryInfo;
    }
    this.getDataList();
  },
  methods: {
    // 本地
    async getDataList(e) {
      if (e == 1) {
        this.queryInfo.current = 1;
      }

      const { data } = await this.$http.get(
        "getOutOfStockBookList/" +
          this.queryInfo.current +
          "/" +
          this.queryInfo.size +
          "?keyword=" +
          this.queryInfo.keyword
      );
      this.tableData = data.data.records;
      this.total = data.data.total;
      this.loading = false;
    },

    // 展开子书
    handleToogleExpand(row) {
      let $table = this.$refs.table;
      this.childrenData = row.bookInfoChildrenList;
      this.tableData.map((item) => {
        if (row.id != item.id) {
          $table.toggleRowExpansion(item, false);
        }
      });
      $table.toggleRowExpansion(row);
    },

    // 点击修改进行页面跳转
    showloeding(item) {
      // console.log(item);
      this.$router.push({
        path: "/BookEDetails",
        name: "BookEDetails",
        //params传参 需要使用 name 否则取不到；对应路由配置的 name
        params: {
          id: item.id,
          queryInfo: this.queryInfo,
        },
      });
      setbookid(item.id);
    },

    // 监听时间变化
    itemChang() {
      this.Item = getdate(this.Item);
    },

    // 监听分页跳转
    handleSizeChange(val) {
      this.queryInfo.size = val;
      this.getDataList();
    },

    handleCurrentChange(val) {
      this.queryInfo.current = val;
      this.getDataList();
    },
  },
};
</script>




<style lang="scss" scoped>
.demo-table-expand {
  font-size: 0;
}

.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}

::v-deep .el-table__expanded-cell {
  padding: 0 0 0 110px;
}

.ipt_Submit {
  width: 200px;
}

.ipt_Submit ::v-deep input {
  height: 35px;
}

.btn_Submit {
  margin-left: 10px;
}

.ipt1 {
  margin-right: 10px;
}
</style>