export default {
    data() {
      return {
        tableData: [
          {
            date: "2016-05-02",
            name: "王小虎",
            province: "上海",
            city: "普陀区",
            address: "上海市普陀区金沙江路 1518 弄",
            zip: 200333,
          },
          {
            date: "2016-05-04",
            name: "王小虎",
            province: "上海",
            city: "普陀区",
            address: "上海市普陀区金沙江路 1517 弄",
            zip: 200333,
          },
          {
            date: "2016-05-01",
            name: "王小虎",
            province: "上海",
            city: "普陀区",
            address: "上海市普陀区金沙江路 1519 弄",
            zip: 200333,
          },
          {
            date: "2016-05-03",
            name: "王小虎",
            province: "上海",
            city: "普陀区",
            address: "上海市普陀区金沙江路 1516 弄",
            zip: 200333,
          },
        ],
  
        queryInfo: {
          current: 1,
          size: 10,
        },
  
        currentPage: 1,
        total: 0,
        loading: true, //加载中
      };
    },
    mounted() {
      this.etWithdrawDepositList(); //获取提现订单列表
    },
  
    methods: {
      // 0.获取提现订单列表
      async etWithdrawDepositList() {
        const { data } = await this.$http.get(
          "getWithdrawDepositList/" +
            this.queryInfo.current +
            "/" +
            this.queryInfo.size
        );
        this.tableData = data.data.records;
        this.total = data.data.total;
        this.loading = false;
      },
  
      // 1.撤销操作
      deleteRow(item) {
        let params = {};
        params.id = item.id;
        params.status = 1;
        this.$confirm("是否撤销该提现申请?", "申请处理", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(async () => {
            const { data } = await this.$http.post("updateExamineStatus", params);
            if (data.data) {
              this.etWithdrawDepositList();
              this.$message({
                type: "success",
                message: "撤销成功!",
              });
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      },
  
      // 2.已打款操作
      handleClick(item) {
        let params = {};
        params.id = item.id;
        params.status = 0;
        this.$confirm("请先确认已联系会员并全额打款，处理完毕。", "申请处理", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(async () => {
            const { data } = await this.$http.post("updateExamineStatus", params);
            if (data.data) {
              this.etWithdrawDepositList();
              this.$message({
                type: "success",
                message: "操作成功!",
              });
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      },
  
      // 3.分页
      handleSizeChange(val) {
        this.queryInfo.size = val;
        this.etWithdrawDepositList();
      },
      handleCurrentChange(val) {
        this.queryInfo.current = val;
        this.etWithdrawDepositList();
      },
    },
  };