import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    BannerModuleDetails:[], //自定义模块 公用的模块信息
  },
  // 同步  舍弃功能
  mutations: {
    // addList(state, num) {
    //   state.list.unshift(num)
    // },
    // delList(state, num) {
    //   const i = state.list.findIndex(v => v.bookQrCode == num.bookQrCode)
    //   state.list.splice(i, 1)
    // },
    // replace(state, num) {
    //   const MUlist = state.list.find(v => v.ISBN == num.ISBN ? v = num : "")
    //   state.list = MUlist
    // },
    // setlist(state,num){
    //   state.list = num
    // },
    // removeList(state){
    //   state.list.length=0
    // }

    setBannerModuleDetails(state,params){
      state.BannerModuleDetails=params;
    }
  },
  // 获取数据并渲染
  getters: {
    // getList(state) {
    //   return state.list
    // },
  },

  // 异步
  actions: {
  },
  modules: {
  }
})
