<template>
  <div>
    <el-row>
      <el-col :span="8" style="border-right: 1px solid #ccc">
        <el-table :data="tableData" style="width: 100%; height: 80vh">
          <el-table-column prop="name" label="日期" width="180">
          </el-table-column>
        </el-table>
      </el-col>
      <el-col :span="16">
        <el-card style="height: 80vh">
          <el-header>
            <el-col :span="16">
              <div class="sok_wrap">
                <div class="sok_style">套书内书籍</div>
                <div class="sok_num">3</div>
              </div>
            </el-col>
            <el-col :span="8">大萨达撒</el-col>
          </el-header>
          <el-table :data="tableData" style="width: 100%">
            <el-table-column prop="name" label="日期" width="180">
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>

  
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [], //数组数据
    };
  },
};
</script>

<style  src="./suitBooks.css" scoped>
/* 通过  src="./bookdetalis.css" 引入不会造成全局污染*/
</style>

<style>
</style>