import Vue from 'vue'
import '../plugins/element.js'
import VueRouter from 'vue-router'
import Home from '@/views/home/welcome.vue'
import Login from '@/views/login/login.vue'
import Warehousing from '@/views/Warehousing/Warehousing.vue'
import Manipulate from '@/views/Manipulate/Manipulate.vue'
import Booklist from '@/views/Booklist/Booklist.vue'
import BookDetails from '@/views/BookDetails/BookDetails.vue'
import BookEDetails from '../views/BookEDetalis/BookEDetalis.vue'
import Classification from '../views/EssentialInformation/Classification/Classification2.vue'
import label from '../views/EssentialInformation/label/label.vue'
import Bookshelf from '../views/EssentialInformation/Bookshelf/Bookshelf.vue'
import Rulelist from '../views/Rulelist/Rulelist.vue'
import rule from '../views/rule/rule.vue'
import BookEmpty from '../views/BookEmpty/BookEmpty.vue'
import SuitBooks from '../views/suitBooks/suitBooks.vue'
import Withdrawal from '../views/Withdrawal/Withdrawal.vue'
import customColumn from '../views/customColumn'
import FixedColumn from '../views/FixedColumn'
import HDSwiper from '../views/HDSwiper'
import EFBook from '../views/EFBook'
import editcustom from '../views/EditCustom'
import editBookList from '../views/EditCustom/CustomModule/bookList/EditBookList'
import bookinquiry from '@/views/reportform/bookinquiry'
import Loanslip from '@/views/reportform/Loanslip'
import Returnlist from '@/views/reportform/Returnlist'
import Buyout from '@/views/reportform/Buyout'
import freight from '@/views/freight/freight'
import { getToken } from "@/utils/auth";

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    component: Login,
    meta: {
      title: '图书侠'
    }
  },
  {
    path: '/login',
    component: Login,
    meta: {
      title: '图书侠'
    }
  },
  {
    path: '/home',
    component: Home,
    redirect: '/warehousing',
    meta: {
      title: '首页'
    },
    children: [
      {
        path: '/warehousing', component: Warehousing,
        meta: {
          title: '书籍扫码'
        },
      },
      {
        path: '/manipulate', component: Manipulate,
        meta: {
          title: '书籍入库'
        }
      },
      {
        path: '/bookdetails', component: BookDetails, name: 'bookdetails', meta: {
          title: '图书详情'
        }
      },
      {
        path: '/BookEDetails', component: BookEDetails, name: 'BookEDetails', meta: {
          title: '无库存图书详情'
        }
      },
      {
        path: '/classification', component: Classification, name: 'classification', meta: {
          title: '分类管理'
        }
      },
      {
        path: '/bookshelf', component: Bookshelf, name: 'bookshelf', meta: {
          title: '书架管理'
        }
      },
      {
        path: '/label', component: label, name: 'label', meta: {
          title: '标签管理'
        }
      },
      {
        path: '/Rulelist', component: Rulelist, name: 'Rulelist',
        meta: {
          title: '编辑规则'
        }
      },
      {
        path: '/rule', component: rule, name: 'rule',
        meta: {
          title: '规则管理'
        }
      },
      {
        path: '/booklist', component: Booklist, name: 'booklist',
        meta: {
          title: '书籍列表'
        }
      },
      {
        path: '/bookempty', component: BookEmpty, name: 'BookEmpty', meta: {
          title: '书籍列表'
        }
      },
      {
        path: '/suitBooks', component: SuitBooks, name: 'suitBooks', meta: {
          title: '套装书管理'
        }
      },
      {
        path: '/withdrawal', component: Withdrawal, name: 'withdrawal', meta: {
          title: '提现管理'
        }
      },
      {
        path: '/freight', component: freight, name: 'freight', meta: {
          title: '运费管理'
        }
      },
      {
        path: '/customColumn', component: customColumn, name: 'customColumn', meta: {
          title: '自定义栏目'
        }
      },
      {
        path: '/FixedColumn', component: FixedColumn, name: 'FixedColumn', meta: {
          title: '固定栏目'
        }
      },
      {
        path: '/HDSwiper', component: HDSwiper, name: 'HDSwiper', meta: {
          title: '首页轮播图'
        }
      },
      {
        path: '/EFBook', component: EFBook, name: 'EFBook', meta: {
          title: '首页书单'
        },
      },
      {
        path: '/editcustom', component: editcustom, name: 'editcustom', meta: {
          title: '自定义首页模块', 
            // keepAlive: true, // 需要缓存
        }
      },

      {
        path: '/editBookList', component: editBookList, name: 'editBookList', meta: {
          title: '编辑书单', 
          // keepAlive: false, // 需要缓存
        }
      },
      {
        path: '/bookinquiry', component: bookinquiry, name: 'bookinquiry', meta: {
          title: '书籍查询', 
        }
      },
      {
        path: '/Loanslip', component: Loanslip, name: 'Loanslip', meta: {
          title: '借书订单', 
        }
      },
      {
        path: '/Returnlist', component: Returnlist, name: 'Returnlist', meta: {
          title: '还书订单', 
        }
      },
      {
        path: '/Buyout', component: Buyout, name: 'Buyout', meta: {
          title: '买断书籍', 
        }
      },
    ]
  },

]


const router = new VueRouter({
  scrollBehavior: () => ({ y: 0 }),
  routes
})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0;
  // to 将要访问的路径
  // from代表从哪里跳转来的
  // next是一个函数，表示放行
  window.document.title = to.meta.title;
  if (to.path === '/login') return next();
  // 获取token
  if (getToken() == "undefined" || getToken() == undefined) {
    return next('/login');
  }

  let token = getToken();
  if (!token || token == null) {
    return next('/login');
  }
  next();
})



export default router
