<template>
  <div>
    <div >
      <div class="booklid_wrap">
      
        <el-input
          placeholder="输入图书名"
          v-model.trim="queryInfo.bookName"
          class="ipt_Submit ipt1"
        ></el-input>
          <el-input
          placeholder="输入图书书号"
          v-model.trim="queryInfo.isbnCode"
          onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
          class="ipt_Submit ipt1"
        ></el-input>
          <el-input
          placeholder="输入借阅人"
          v-model.trim="queryInfo.memberName"
          class="ipt_Submit ipt1"
        ></el-input>
          <el-input
          placeholder="输入会员编号"
          v-model.trim="queryInfo.memberCode"
          class="ipt_Submit ipt1"
        ></el-input>
          <el-input
          placeholder="输入订单号"
          v-model.trim="queryInfo.orderNum"
          class="ipt_Submit ipt1"
        ></el-input>
       
      </div>
      <div class="booklid_wrap">
        <div class="block" style="margin-right:8px">
          <el-date-picker
            v-model="timeinterval"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <el-select v-model="status" multiple placeholder="请选择" style="width:380px;">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
        </el-select>
         <button
          class="btn_Submit"
          @click="bookReport(1)"
          v-on:keyup.enter="bookReport(1)"
        >
          查询
        </button>
         <div class="clear"
          @click="clear()"
        >
            <img src="@/assets/img/clear.png" alt="">
            <el-button
              class="clearbtn"
              type="text"
              size="mini"
            >
              清除条件</el-button
            >
        </div>
        <div class="flex export">
          <div>
            <img src="@/assets/img/edit.png" alt="">
          </div>
          <template> <download-excel
            class = "export-excel-wrapper"
            :data = "json_data"
            :fields = "json_fields"
            name = "还书订单.xls">
            <!-- 上面可以自定义自己的样式，还可以引用其他组件button -->
            <el-button
              class="clearbtn"
              type="text"
              size="mini"
            >
              导出EXCEL</el-button
            >
          </download-excel></template>
         
        </div>
      </div>
    </div>
    <el-card>
      
      <el-table
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        :data="tableData"
      >
        <el-table-column prop="createTime" label="还书时间" width="180">
        </el-table-column>
        <el-table-column prop="orderNum" label="订单号" width="200">
        </el-table-column>
        <el-table-column prop="memberCode" label="会员编号" >
        </el-table-column>
        <el-table-column prop="memberName" label="还书人" width="180">
        </el-table-column>
        <el-table-column prop="orderType" label="还书方式">
        </el-table-column>
        <el-table-column prop="takeBookAddress" label="地址" width="240">
        </el-table-column>
        <el-table-column prop="memberPhone" label="联系电话" width="180">
        </el-table-column>
        <el-table-column prop="expressFee" label="客户自付邮费" width="120">
        </el-table-column>
        <el-table-column prop="totalPrice" label="退款">
        </el-table-column>
        <el-table-column prop="actualExpressFee" label="实际邮费" >
        </el-table-column>
        <el-table-column prop="expressNum" label="快递单号" width="180">
        </el-table-column>
        <el-table-column prop="status" label="订单状态">
        </el-table-column>
         <el-table-column prop="modifiedTime" label="订单更新时间" width="180">
        </el-table-column>
        <el-table-column prop="buyOutOrdinaryCount" label="操作">
           <template slot-scope="scope">
                <el-button
                  class="btn_right"
                  type="text"
                  size="mini"
                  @click="showloeding(scope.row,scope.$index)"
                >
                  详情</el-button
                >
              </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="1"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <!-- 详情 -->
      <el-dialog :title="lodingtext" :visible.sync="dia" width="63%">
        <el-table
          :data="detailsData.orderReturnInfoReportSaleVOList"
        >
          <el-table-column prop="bookName" label="书名" width="220">
          </el-table-column>
          <el-table-column prop="isbnCode" label="ISBN" width="180">
          </el-table-column>
          <el-table-column prop="bookCondition" label="品相" >
          </el-table-column>
          <el-table-column prop="originalPrice" label="图书押金" >
          </el-table-column>
          <el-table-column prop="discountPrice" label="借阅费" >
          </el-table-column>
           <el-table-column prop="status" label="归还状态" >
          </el-table-column>
          <el-table-column prop="deductMoney" label="扣款" >
          </el-table-column>
        </el-table>
        <div class="flex">
          <p class="price">总原价：{{detailsData.totalOriginalPrice}} &nbsp;&nbsp;&nbsp;&nbsp;</p>
          <p class="price"> 借阅费：{{detailsData.borrowPrice}} &nbsp;&nbsp;&nbsp;&nbsp;</p>
          <p class="price"> 扣款：{{detailsData.withholdPrice}}</p>
          <el-button type="primary" class="BGcolor" @click="closedia"
              >确 定</el-button>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
    data() {
      return {
        tableData: [],
        detailsData: [],
        dia:false,
        timeinterval:"",
        options:[
          {
            label:"待发货",
            value:0
          },
          {
            label:"待评估",
            value:1
          },
          {
            label:"已完成",
            value:2
          },
          {
            label:"已取消",
            value:3
          },

        ],
        status:[],
        lodingtext:"详情",
        queryInfo: {
          current: 1,
          size: 10,
          bookName:'',
          isbnCode:'',
          memberName:'',
          memberCode:'',
          orderNum:'',
          statusList:[],
          startDate:'',
          endDate:'',
        },
        title: "还书订单",
        json_fields: {
        "还书时间": "createTime",    //常规字段
        "订单号": "orderNum", //支持嵌套属性
        "会员编号": "memberCode", //支持嵌套属性
        "还书人": "memberName", //支持嵌套属性
        "还书方式": "orderType", //支持嵌套属性
        "地址": "takeBookAddress", //支持嵌套属性
        "联系电话": "memberPhone", //支持嵌套属性
        "书籍总价": "totalOriginalPrice", //支持嵌套属性
        "总借阅费": "borrowPrice", //支持嵌套属性
        "退款": "totalPrice", //支持嵌套属性
        "扣款": "withholdPrice", //支持嵌套属性
        "客户自付邮费": "expressFee", //支持嵌套属性
        "实际邮费": "actualExpressFee", //支持嵌套属性
        "快递单号": "expressNum", //支持嵌套属性
        "订单状态": "status", //支持嵌套属性
        "订单更新时间": "modifiedTime", //支持嵌套属性
      },
      json_data: [
      ],
        currentPage: 1,
        total: 0,
        loading: true, //加载中
      };
    },
    mounted() {
      this.bookReport(1); //获取提现订单列表
      
    },
  
    methods: {
      // 0.获取还书订单列表
      async bookReport(e) {
        if(e==1){
          this.queryInfo.current=1;
        }
        if(this.timeinterval!=''){
          let startDate = this.time(this.timeinterval[0])
          let endDate = this.time(this.timeinterval[1])
          this.startDate=startDate;
          // this.startDate=startDate.slice(0,10);
          this.endDate=endDate;
        }
        let params ={
          current:this.queryInfo.current,
          size:this.queryInfo.size,
          bookName:this.queryInfo.bookName,
          isbnCode:this.queryInfo.isbnCode,
          memberName:this.queryInfo.memberName,
          orderNum:this.queryInfo.orderNum,
          startDate:this.startDate,
          endDate:this.endDate,
          statusList:this.status,
          memberCode:this.queryInfo.memberCode,
        }
        let { data } = await this.$http.post("orderReturnReport", params);
          data.data.list =  data.data.list.map(item => {
          if(item.orderType==0){
            item.orderType = '邮寄';
          }else if(item.orderType==1){
            item.orderType = '上门';
          }else{
            item.orderType = '站点还书';
          }
          if(item.status==0){
            item.status = '待发货';
          }else if(item.status==1){
            item.status = '待评估';
          }else if(item.status==2){
            item.status = '已完成';
          }else{
            item.status = '已取消';
          }
          return item
        })
        this.tableData = data.data.list;
        this.total = data.data.total;
        this.loading = false;
        this.excel(); //获取excel列表
      },
  
      // 详情 1
      async showloeding(item, index) {
        this.item = item;
        this.lodingtext = item.orderNum;
        let params ={
          orderNum:item.orderNum,
        }
        let { data } = await this.$http.post("orderReturnInfoReport", params);
        console.log(data.data);
        for(let i in data.data.orderReturnInfoReportSaleVOList){
          if(data.data.orderReturnInfoReportSaleVOList[i].bookCondition==0){
            data.data.orderReturnInfoReportSaleVOList[i].bookCondition = '全新';
          }else if(data.data.orderReturnInfoReportSaleVOList[i].bookCondition==1){
            data.data.orderReturnInfoReportSaleVOList[i].bookCondition = '良好';
          }else{
            data.data.orderReturnInfoReportSaleVOList[i].bookCondition = '普通';
          }
        }
         for(let i in data.data.orderReturnInfoReportSaleVOList){
          if(data.data.orderReturnInfoReportSaleVOList[i].status==4){
            data.data.orderReturnInfoReportSaleVOList[i].status = '快递丢失';
          }else if(data.data.orderReturnInfoReportSaleVOList[i].status==5){
            data.data.orderReturnInfoReportSaleVOList[i].status = '移出订单';
          }else{
            data.data.orderReturnInfoReportSaleVOList[i].status = ' ';
          }
        }
        this.detailsData =data.data
        this.lodingStyle = 1;
        this.item_index = index;
        this.dia = true;
      },
      async excel(){
         // excel数据
         let obj ={
          table:1,
          orderReturnReportDto:{
            bookName:this.queryInfo.bookName,
            isbnCode:this.queryInfo.isbnCode,
            memberName:this.queryInfo.memberName,
            orderNum:this.queryInfo.orderNum,
            startDate:this.startDate,
            endDate:this.endDate,
            statusList:this.status,
            memberCode:this.queryInfo.memberCode,
          }
         
        }
        let { data } = await this.$http.post("excelExport", obj);
        data.data =  data.data.map(item => {
          
          if(item.orderType==0){
            item.orderType = '邮寄';
          }else if(item.orderType==1){
            item.orderType = '上门';
          }else{
            item.orderType = '站点还书';
          }
          if(item.status==0){
            item.status = '待发货';
          }else if(item.status==1){
            item.status = '待评估';
          }else if(item.status==2){
            item.status = '已完成';
          }else{
            item.status = '已取消';
          }
          item.orderNum="/"+item.orderNum
          return item
        })
        this.json_data = data.data;
      },
      clear(){
        this.queryInfo.bookName='';
        this.queryInfo.isbnCode='';
        this.queryInfo.memberName='';
        this.queryInfo.memberCode='';
        this.queryInfo.orderNum='';
        this.startDate='';
        this.endDate='';
        this.timeinterval='';
        this.status=[];
        this.statusList=[];
        this.excel(); //获取excel列表
      },
      time(date) {
          var y = date.getFullYear()
          var m = date.getMonth() + 1
          m = m < 10 ? '0' + m : m
          var d = date.getDate()
          d = d < 10 ? '0' + d : d
          var h = date.getHours()
          h = h < 10 ? '0' + h : h
          var minute = date.getMinutes()
          minute = minute < 10 ? '0' + minute : minute
          var second = date.getSeconds()
          second = second < 10 ? '0' + second : second
          return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second
      },
      closedia(){
        this.dia = false;
      },
      // 3.分页
      handleSizeChange(val) {
        this.queryInfo.size = val;
        this.bookReport(0);
      },
      handleCurrentChange(val) {
        this.queryInfo.current = val;
        this.bookReport(0);
      },
    },
  };
</script>

<style scoped>
.flex{
  display: flex;
}
.booklid_wrap {
  /* width: 100%; */

  display: flex;
  margin-bottom: 21px;
  /* justify-content: space-between; */
}


.ipt_Submit {
  width: 200px;
}

.ipt_Submit ::v-deep input {
  height: 35px;
}

.btn_Submit {
  margin-left: 10px;
}
.price{
  margin-top: 36px;
  margin-left: 10px;
}
.BGcolor{
  margin-left: 63%;
  margin-top: 36px;
}
.ipt1 {
  margin-right: 10px;
}
.el-table{
  color: #333333;
}
::v-deep.el-table th > .cell {
  text-align: center;
}

::v-deep.el-table .cell {
  text-align: center;
}
.clear{
  display: flex;
  margin-top: 9px;
  margin-left: 22px;
}
.clearbtn{
  color: #999999;
  padding-top: 0;
  font-size: 18px;
}
.clear img{
  margin-top: 2px;
  width: 16px;
  height: 16px;
}
.export{
  margin-top: 11px;
  color: #999999;
  margin-left: 22px;
}
.export img{
  margin-top: 2px;
  width: 16px;
  height: 16px;
}
</style>