<template>
  <div>
    <!-- 1.顶部切换目录 -->
    <el-card style="margin-bottom: 10px">
      <div style="display: flex">
        <div
          class="hd_btn hd_btn1"
          :class="{ check_hd_btn: check_hd_btn }"
          @click="editImg"
        >
          <div
            class="hd_btn_round"
            :class="{ check_hd_round: check_hd_btn }"
          ></div>
          <span :class="{ check_hd_color: check_hd_btn }">编辑轮播</span>
        </div>
        <div
          class="hd_btn hd_btn2"
          :class="{ check_hd_btn: !check_hd_btn }"
          @click="editBook"
        >
          <div
            class="hd_btn_round"
            :class="{ check_hd_round: !check_hd_btn }"
          ></div>
          <span :class="{ check_hd_color: !check_hd_btn }">编辑书单</span>
        </div>
      </div>
    </el-card>

    <!-- 2.中间内容区。表格+新增操作 -->
    <el-card class="box-card">
      <Editpicture v-if="check_hd_btn"></Editpicture>
      <handBook v-else></handBook>
    </el-card>

    <!-- 书单排序编辑 -->
    <el-dialog title="书单排序" :visible.sync="dialogVisible" width="30%">
      <el-form
        :model="item"
        ref="itemForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item
          label="排序"
          prop="page"
          :rules="[
            { required: true, message: '序号不能为空' },
            { type: 'number', message: '序号必须为数字值' },
          ]"
        >
          <el-input
            type="age"
            v-model.number="item.page"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="sortSubmit('itemForm')"
            >提交</el-button
          >
          <el-button @click="resetForm('itemForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 书单排序编辑 -->
    <el-dialog title="书单排序" :visible.sync="ImgDialog" width="30%">
      <el-form
        :model="item"
        ref="ImgDialogForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item
          label="排序"
          prop="page"
          :rules="[
            { required: true, message: '序号不能为空' },
            { type: 'number', message: '序号必须为数字值' },
          ]"
        >
          <el-input
            type="age"
            v-model.number="item.page"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="sortSubmit('ImgDialogForm')"
            >提交</el-button
          >
          <el-button @click="IDForm('ImgDialogForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import handBook from "../../components/handBook";
import Editpicture from "../../components/Editpicture";
export default {
  components: {
    handBook,
    Editpicture,
  }, //添加注册组件事件
  data() {
    return {
      check_hd_btn: true, //true显示轮播图； false显示书单
      // 书单编辑弹出层
      dialogVisible: false,
      // 轮播排序
      ImgDialog: false,

      // 排序
      item: {
        page: 10,
      },
    };
  },
  mounted() {
    let switch_WB = sessionStorage.getItem("switch_WB");
    if (switch_WB == 0) {
      this.check_hd_btn = true; //显示为轮播图模块
    } else {
      this.check_hd_btn = false; //显示为书单模块
    }
  },
  methods: {
    // 1.顶部css切换
    editImg() {
      this.check_hd_btn = true;
      sessionStorage.setItem("switch_WB", "0"); //切换轮播和书单依据； 0为轮播；1为书单
    },

    // 显示书单
    editBook() {
      this.check_hd_btn = false;
      sessionStorage.setItem("switch_WB", "1"); //切换轮播和书单依据； 0为轮播；1为书单
    },

    // 2.排序编辑
    showDialog(item) {
      this.dialogVisible = true;
    },

    //2.1-1确认排序
    sortSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          return false;
        }
      });
    },
    //2.1-2重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    // 2.1-3删除表单
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },

    // 2.2-1 编辑轮图***********
    showDialog(item) {
      this.ImgDialog = true;
    },

    IDForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style lang="scss" scoped>
.hd_btn {
  padding: 9px 11px;
  border-radius: 2px;

  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #666666;
  .hd_btn_round {
    width: 9px;
    height: 9px;
    margin-right: 5px;
    border-radius: 50%;
    border: 1px solid #666666;
  }

  .check_hd_round {
    border: 1px solid #ff1932;
  }

  .check_hd_color {
    color: #ff1932;
  }
}

// 选中css
.check_hd_btn {
  border: 1px solid #ff1932;
}

.hd_btn1 {
  margin-left: 42px;
}

.hd_btn2 {
  margin-left: 21px;
}

.el-row {
  margin-bottom: 10px;
}
</style>