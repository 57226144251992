export function getdate(date) {
    var date = new Date(date);
    var YY = date.getFullYear() + "-";
    var MM =
        (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-";
    var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    var hh =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
    var mm =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
    var ss =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    return YY + MM + DD + " " + hh + mm + ss;
}

// 解析url
export function UrlSearch() {
    var wxCode;

    var str=location.href; //取得整个地址栏
    // var str = "https://manager.tushuxia.com/#/login?code=041BHeGa1EzLnB0os1Ga1RLVMo2BHeGt&state="
    var num = str.indexOf("?")
    str = str.substr(num + 1); //取得所有参数   stringvar.substr(start [, length ]
    var arr = str.split("&"); //各个参数放到数组里
    let  code =arr[0]
    let  ipos = code.indexOf("=");
    let  Code=code.substring(ipos+1,code.length)
    wxCode=Code
    return wxCode
}







