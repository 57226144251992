<template>
  <div>
    <el-card class="cent_wrap">
      <el-row>
        <el-col :span="12">
          <div class="hui_weap">
            <!-- 规则标题 -->
            <el-form
              ref="form"
              :model="form"
              :rules="rules"
              label-position="left"
              label-width="100px"
            >
              <el-form-item label="规则标题" prop="name">
                <el-input
                  placeholder="请输入规则标题"
                  v-model="form.name"
                  :disabled="PostageCheckout"
                  clearable
                ></el-input>
              </el-form-item>
              <!-- 展示位置 -->
              <el-form-item label="展示位置" prop="region">
                <el-select v-model="form.region" placeholder="请选择展示位置">
                  <el-option
                    label="暂不发布"
                    :disabled="PostageCheckout"
                    value="0"
                  ></el-option>
                  <el-option
                    label="借阅规则"
                    :disabled="PostageCheckout"
                    value="1"
                  ></el-option>
                  <el-option
                    label="预估退款规则"
                    :disabled="PostageCheckout"
                    value="2"
                  ></el-option>
                  <el-option
                    label="支付规则"
                    :disabled="PostageCheckout"
                    value="3"
                  ></el-option>
                  <el-option
                    label="运费规则"
                    :disabled="!PostageCheckout"
                    value="4"
                  ></el-option>
                </el-select>
              </el-form-item>

              <!-- 规则内容 -->
              <el-form-item label="规则内容" prop="desc">
                <el-input
                  :rows="10"
                  type="textarea"
                  v-model="form.desc"
                  :disabled="PostageCheckout"
                ></el-input>
              </el-form-item>

              <!-- 运费 -->
              <el-form-item label="运费规则" v-if="PostageCheckout">
                <el-card>
                  <div class="flexWrap">
                    <div class="flexWrap_item">
                      <span class="text_describe">借阅邮费百分比</span>
                      <div class="flexWrap_item_input">
                        <el-input
                          size="mini"
                          v-model="form.proportion_Borrow"
                          @input="changen(0)"
                          placeholder="请输入比例"
                        ></el-input>
                      </div>
                      <span class="Company">%</span>
                    </div>
                    <div class="flexWrap_item">
                      <span class="text_describe">借阅最低邮费</span>
                      <div class="flexWrap_item_input">
                        <el-input
                          size="mini"
                          v-model="form.min_Borrow"
                          @input="changen(2)"
                          placeholder="请输入金额"
                        ></el-input>
                      </div>
                      <span class="Company">元</span>
                    </div>
                  </div>
                </el-card>

                <el-card>
                  <div class="flexWrap">
                    <div class="flexWrap_item">
                      <span class="text_describe">归还邮费百分比</span>
                      <div class="flexWrap_item_input">
                        <el-input
                          size="mini"
                          v-model="form.proportion_return"
                          @input="changen(1)"
                          placeholder="请输入比例"
                        ></el-input>
                      </div>
                      <span class="Company">%</span>
                    </div>
                    <div class="flexWrap_item">
                      <span class="text_describe">归还最低邮费</span>
                      <div class="flexWrap_item_input">
                        <el-input
                          size="mini"
                          v-model="form.min_return"
                          @input="changen(3)"
                          placeholder="请输入金额"
                        ></el-input>
                      </div>
                      <span class="Company">元</span>
                    </div>
                  </div>
                </el-card>
              </el-form-item>

              <!-- 保存 -->
              <el-form-item>
                <el-button
                  class="btnRig"
                  type="primary"
                  @click="onSubmit('form')"
                  >保存</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>
<script>
import { getRuleitem, getRuleList, getUserDetails } from "../../utils/auth.js";

export default {
  data() {
    return {
      form: {
        name: "",
        region: "", //展示位置
        desc: "", //解决规则
        min_Borrow: 5, //出借
        proportion_Borrow: 0.03,

        min_return: 8, //归还
        proportion_return: 0.04,
      },

      status: 0, //默认显示新增功能

      item: {}, //上个页面传递来的值
      ruleTypelist: [], //上个页面传递来的集合分类
      // 为空效验
      rules: {
        name: [
          { required: true, message: "请输入规则标题", trigger: "blur" },
          {
            min: 3,
            max: 20,
            message: "长度在 3 到 20 个字符",
            trigger: "blur",
          },
        ],
        region: [
          { required: true, message: "请选择展示位置", trigger: "change" },
        ],
        desc: [{ required: true, message: "请填写规则内容", trigger: "blur" }],
      },

      // 4.邮费
      // Postage: {
      //   min_Borrow: 5, //出借
      //   proportion_Borrow: 0.03,

      //   min_return: 8, //归还
      //   proportion_return: 0.04,
      // },

      PostageCheckout: false, //为运费时 4 ，显示为true
    };
  },
  created() {
    let item = getRuleitem();
    if (item) {
      item = JSON.parse(item);
      this.item = item; //上个页面传递来的值
    }
    // 获取 status 判断,判断是新增 还是编辑  0 添加 1编辑
    // let status = this.$route.params.status;
    // this.ruleTypelist = getRuleList(); //上个页面传递来的集合分类
    let params = JSON.parse(getRuleList());
    this.ruleTypelist = params.ruleTypelist;
    this.status = params.status;
    if (params.status == 1) {
      this.getBorrowRuleInfo(item.id);
    }
  },

  methods: {
    onSubmit(form) {
      // 效验
      let userName = JSON.parse(getUserDetails()).name;
      let ruleTypelist = this.ruleTypelist; //上个页面传递来的数组
      this.$refs[form].validate(async (valid) => {
        if (valid) {
          let params = {};
          params.ruleTitle = this.form.name;
          params.ruleDetails = this.form.desc;
          params.ruleType = this.form.region;
          if (this.form.region == "暂不发布") {
            params.ruleType = 0;
          } else if (this.form.region == "借阅规则") {
            params.ruleType = 1;
          } else if (this.form.region == "预估退款规则") {
            params.ruleType = 2;
          } else if (this.form.region == "支付规则") {
            params.ruleType = 3;
          } else if (this.form.region == "运费规则") {
            params.ruleType = 4;
            params.expressFeeRules = this.form.expressFeeRules;
          }

          for (let i = ruleTypelist.length - 1; i > -1; i--) {
            const v = ruleTypelist[i];
            if (v == 0) {
              ruleTypelist.splice(i, 1);
            }
          }
          if (this.status == 1) {
            this.form.min_Borrow = this.form.min_Borrow.toString();
            this.form.proportion_Borrow =
              this.form.proportion_Borrow.toString();
            this.form.min_return = this.form.min_return.toString();
            this.form.proportion_return =
              this.form.proportion_return.toString();
            if (
              !this.form.min_Borrow ||
              !this.form.proportion_Borrow ||
              !this.form.min_return ||
              !this.form.proportion_return
            ) {
              return this.$message({
                message: "运费比例或运费金额不能为空",
                type: "error",
              });
            }
            // 更新规则
            params.id = this.form.id;

            if (this.item.ruleType == params.ruleType) {
              // 正常提交
              this.postData(params);
            } else {
              // 判断是否已经添加该项规则
              // //console.log(ruleTypelist);
              let index = ruleTypelist.indexOf(params.ruleType - 0);
              if (index == -1) {
                // 正常提交
                this.postData(params);
              } else {
                this.$message({
                  message: "该展示位置，已存在相关规则。请重新选择",
                  type: "error",
                });
              }
            }
          } else {
            this.form.min_Borrow = this.form.min_Borrow.toString();
            this.form.proportion_Borrow =
              this.form.proportion_Borrow.toString();
            this.form.min_return = this.form.min_return.toString();
            this.form.proportion_return =
              this.form.proportion_return.toString();
            if (
              !this.form.min_Borrow ||
              !this.form.proportion_Borrow ||
              !this.form.min_return ||
              !this.form.proportion_return
            ) {
              return this.$message({
                message: "运费比例或运费金额不能为空",
                type: "error",
              });
            }
            // 新增 规则
            let index = ruleTypelist.indexOf(params.ruleType - 0);
            let expressFeeRules = [];
            if (params.ruleType == 4) {
              expressFeeRules[0] = {};
              expressFeeRules[1] = {};
              expressFeeRules[0].createUser = userName; //用户名
              expressFeeRules[0].saleOrRefund = 0; //出借
              expressFeeRules[0].expressFee = this.form.min_Borrow; //出借基础运费
              expressFeeRules[0].orderAmountPercentage =
                this.form.proportion_Borrow / 100; //出借基础运费%
              expressFeeRules[1].createUser = userName; //用户名
              expressFeeRules[1].saleOrRefund = 1; //归还
              expressFeeRules[1].expressFee = this.form.min_return; //归还基础运费
              expressFeeRules[1].orderAmountPercentage =
                this.form.proportion_return; //归还基础运费%
              params.expressFeeRules = expressFeeRules / 100;
            }

            if (index == -1) {
              // 正常提交
              const { data } = await this.$http.post(
                "createBorrowRule",
                params
              );
              this.$message({
                message: "添加成功，稍后返回上一页",
                type: "success",
              });
              setTimeout(() => {
                this.$router.back();
              }, 2000);
            } else {
              this.$message({
                message: "该展示位置，已存在相关规则。请重新选择",
                type: "error",
              });
            }
          }
        } else {
          // //console.log("error submit!!");
          return false;
        }
      });
    },

    // 更新规则
    async postData(params) {
      console.log(params);
      if (params.ruleType == 4) {
        let expressFeeRules = params.expressFeeRules; //借阅邮费和归还邮费
        for (let i = 0; i < expressFeeRules.length; i++) {
          const item = expressFeeRules[i];
          if (item.saleOrRefund == 0) {
            item.expressFee = this.form.min_Borrow; //最低出借
            item.orderAmountPercentage = this.form.proportion_Borrow / 100; //最低出借%
          } else if (item.saleOrRefund == 1) {
            item.expressFee = this.form.min_return; //最低归还
            item.orderAmountPercentage = this.form.proportion_return / 100; //最低归还%
          }
        }
      }
      const { data } = await this.$http.post("updateBorrowRule", params);
      if (data.data) {
        this.$message({
          message: "更新成功，稍后返回上一页",
          type: "success",
        });
        setTimeout(() => {
          this.$router.back();
        }, 2000);
      }
    },

    // 重置为空
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    // 获取借阅规则信息
    async getBorrowRuleInfo(type) {
      const { data } = await this.$http.get("getBorrowRuleInfo/" + type);
      let res = data.data;
      this.form.name = res.ruleTitle;
      if (res.ruleType == 0) {
        this.form.region = "暂不发布";
      } else if (res.ruleType == 1) {
        this.form.region = "借阅规则";
      } else if (res.ruleType == 2) {
        this.form.region = "预估退款规则";
      } else if (res.ruleType == 3) {
        this.form.region = "支付规则";
      } else if (res.ruleType == 4) {
        this.form.region = "运费规则";
        this.PostageCheckout = true; //其他勾选项禁用。该项可选。同时给 Postage 赋值
        let expressFeeRules = res.expressFeeRules;
        this.rules = {};
        for (let i = 0; i < expressFeeRules.length; i++) {
          const item = expressFeeRules[i];
          if (item.saleOrRefund == 0) {
            //出借
            this.form.min_Borrow = item.expressFee;
            this.form.proportion_Borrow = item.orderAmountPercentage * 100;
          } else if (item.saleOrRefund == 1) {
            //归还
            this.form.min_return = item.expressFee;
            this.form.proportion_return = item.orderAmountPercentage * 100;
          }
        }
      }
      this.form.desc = res.ruleDetails;
      this.form.id = res.id;
      this.form.expressFeeRules = res.expressFeeRules;
    },

    // 邮费精确到小数点2位
    changen(status) {
      let num;
      if (status == 0 || status == 1) {
        if (status == 0) {
          num = this.form.proportion_Borrow;
        } else if (status == 1) {
          num = this.form.proportion_return;
        }
        num = num.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
        num = num.replace(/^\./g, ""); //验证第一个字符是数字
        num = num.replace(/\.{2,}/g, ""); //只保留第一个, 清除多余的
        num = num.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
        num = num.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
        console.log(num);
        if (Number(num) >= 100) {
          num = 100;
        }
      }

      if (status == 2 || status == 3) {
        if (status == 2) {
          num = this.form.min_Borrow;
        } else if (status == 3) {
          num = this.form.min_return;
        }
        num = num.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
        num = num.replace(/^\./g, ""); //验证第一个字符是数字
        num = num.replace(/\.{2,}/g, ""); //只保留第一个, 清除多余的
        num = num.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
        num = num.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
        console.log(num);
      }

      if (status == 0) {
        this.form.proportion_Borrow = num;
      } else if (status == 1) {
        this.form.proportion_return = num;
      } else if (status == 2) {
        this.form.min_Borrow = num;
      } else if (status == 3) {
        this.form.min_return = num;
      }
    },
  },
};
</script>

<style scoped>
.cent_wrap {
  height: 86vh;
}

.hui_weap {
  min-height: 60vh;
}

.btnRig {
  float: right;
}

.flexWrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.flexWrap_item {
  display: flex;
  width: 50%;
}

.text_describe {
  font-size: 12px;
  margin-right: 10px;
}

.flexWrap_item_input {
  width: 30%;
}

.Company {
  margin-left: 10px;
}
</style>