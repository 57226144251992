 <template>
  <div class="wrap">
    <!-- 表格 -->
    <!-- collapse是否展开   -->
    <el-menu
      :default-active="activePath"
      class="el-menu-vertical-demo"
      :router="true"
      active-text-color="#fa2800"
      :collapse="isCollapse"
      :collapse-transition="true"
    >
      <!-- zdy log布局位置 -->
      <div v-show="!isCollapse" class="log_wrap">
        <div class="log_ig">
          <img src="@/assets/img/log.svg" alt="" />
        </div>
        <div class="log_describe">
          <img class="lg_de_p1" src="@/assets/img/thx.png" alt="" />
          <img class="lg_de_p2" src="@/assets/img/tushuxia.png" alt="" />
        </div>
      </div>

      <div v-show="isCollapse" class="log_wrap2">
        <img src="@/assets/img/log.svg" alt="" />
      </div>

      <!-- 菜单栏 -->
      <el-submenu index="1">
        <template slot="title">
          <img src="@/assets/img/updata.svg" class="icon_tsx" alt="" />
          <span slot="title" class="nav_class">入库管理</span>
        </template>
        <el-menu-item-group>
          <!-- <span slot="title" class="item_header">基础信息维护</span> -->
          <el-menu-item
            index="warehousing"
            @click="saveNavState('warehousing')"
            class="item_css"
          >
            <template slot="title">
              <span>书籍入库</span>
            </template></el-menu-item
          >
        </el-menu-item-group>
      </el-submenu>
      <!-- 菜单栏 end -->

      <el-submenu index="2">
        <template slot="title">
          <img src="@/assets/img/run.svg" class="icon_tsx" alt="" />
          <span slot="title" class="nav_class">图书管理</span>
        </template>
        <el-menu-item-group>
          <!-- 全部书籍 -->
          <el-menu-item
            index="booklist"
            class="item_css"
            @click="saveNavState('booklist')"
          >
            <template slot="title">
              <span>全部书籍</span>
            </template>
          </el-menu-item>
          <!-- 0库存书籍 -->
          <el-menu-item
            index="bookempty"
            @click="saveNavState('bookempty')"
            class="item_css"
          >
            <template slot="title">
              <span>0库存书籍</span>
            </template>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>

      <el-submenu index="3">
        <template slot="title">
          <img src="@/assets/img/news.svg" class="icon_tsx" alt="" />
          <span slot="title" class="nav_class">基础信息</span>
        </template>

        <el-menu-item-group>
          <el-menu-item
            index="classification"
            @click="saveNavState('classification')"
            class="item_css"
          >
            <template slot="title">
              <span>分类管理</span>
            </template>
          </el-menu-item>
          <el-menu-item
            index="bookshelf"
            @click="saveNavState('bookshelf')"
            class="item_css"
          >
            <template slot="title">
              <span>书架管理</span>
            </template>
          </el-menu-item>
          <el-menu-item
            index="label"
            @click="saveNavState('label')"
            class="item_css"
          >
            <template slot="title">
              <span>标签管理</span>
            </template>
          </el-menu-item>
          <el-menu-item
            index="Rulelist"
            @click="saveNavState('Rulelist')"
            class="item_css"
          >
            <template slot="title">
              <span>规则管理</span>
            </template>
          </el-menu-item>
          <el-menu-item
            index="withdrawal"
            @click="saveNavState('withdrawal')"
            class="item_css"
          >
            <template slot="title">
              <span>提现申请</span>
            </template>
          </el-menu-item>
          <el-menu-item
            index="freight"
            @click="saveNavState('freight')"
            class="item_css"
          >
            <template slot="title">
              <span>运费管理</span>
            </template>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>

      <el-submenu index="4">
        <template slot="title">
          <img src="@/assets/img/news.svg" class="icon_tsx" alt="" />
          <span slot="title" class="nav_class">首页管理</span>
        </template>

        <el-menu-item-group>
          <el-menu-item
            index="FixedColumn"
            @click="saveNavState('FixedColumn')"
            class="item_css"
          >
            <template slot="title">
              <span>固定栏目</span>
            </template>
          </el-menu-item>

          <el-menu-item
            index="customColumn"
            @click="saveNavState('customColumn')"
            class="item_css"
          >
            <template slot="title">
              <span>自定义栏目</span>
            </template>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>

      <el-submenu index="5">
        <template slot="title">
          <img src="@/assets/img/news.svg" class="icon_tsx" alt="" />
          <span slot="title" class="nav_class">报表管理</span>
        </template>

        <el-menu-item-group>
          <el-menu-item
            index="bookinquiry"
            @click="saveNavState('bookinquiry')"
            class="item_css"
          >
            <template slot="title">
              <span>书籍查询</span>
            </template>
          </el-menu-item>

          <el-menu-item
            index="Loanslip"
            @click="saveNavState('Loanslip')"
            class="item_css"
          >
            <template slot="title">
              <span>借书订单</span>
            </template>
          </el-menu-item>

           <el-menu-item
            index="Returnlist"
            @click="saveNavState('Returnlist')"
            class="item_css"
          >
            <template slot="title">
              <span>还书订单</span>
            </template>
          </el-menu-item>
            <el-menu-item
            index="Buyout"
            @click="saveNavState('Buyout')"
            class="item_css"
          >
            <template slot="title">
              <span>买断书籍</span>
            </template>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
    </el-menu>

    <!-- 用户信息以及表单 -->
    <el-container>
      <el-header
        style="text-align: right; font-size: 12pxheight: 64px;background: #FFFFFF;border-bottom:1px solid #ECEEF5"
      >
        <el-radio-group
          v-model="isCollapse"
          style="margin-top: 10px; float: left"
        >
          <el-radio-button :label="false">展开</el-radio-button>
          <el-radio-button :label="true">收起</el-radio-button>
        </el-radio-group>

        <div
          style="line-height: 64px; float: right; line-height: 64px"
          :class="{ width: wid }"
          class="user_flex"
        >
          <div class="lex-it">
            <img
              src="../../assets/img/address.svg"
              style="margin-right: 10px"
              alt=""
            />
            <span style="cursor: default">
              {{ siteName }}
            </span>
          </div>
          <div class="user_cut lex-it">|</div>
          <div class="lex-it">
            <img :src="img" class="us_ig" alt="" />
            <span style="cursor: default">{{ userName }}</span>
          </div>
          <div class="user_cut lex-it">|</div>
          <div class="lex-it" @click="loginOut">
            <img
              src="../../assets/img/Out.svg"
              style="margin-right: 10px; vertical-align: middle"
              alt=""
            />
            <span style="cursor: pointer">退出</span>
          </div>
        </div>
      </el-header>

      <el-main>
        <router-view> </router-view>
      </el-main>
    </el-container>
  </div>
</template>


<style>
.wrap {
  display: flex;
  height: 100vh;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
</style>

<script>
import { removeUserDetails, getUserDetails } from "../../utils/auth";
export default {
  data() {
    return {
      isCollapse: true,
      tableData: [],

      // 用户姓名
      userName: "",
      // 用户img
      img: "",
      //站点
      siteName: "",

      wid: "",

      // 用户基本信息
      userdetails: {},

      //监听页面宽度的变化 实时更新页面宽度
      screenWidth: document.body.clientWidth,
      activePath: "", //默认选中菜单
    };
  },
  created() {
    this.userName = JSON.parse(getUserDetails()).name;
    this.img = JSON.parse(getUserDetails()).profile;
    this.siteName = JSON.parse(getUserDetails()).siteName;
    if (this.siteName == undefined) {
      this.siteName = "青岛市北天润店";
    }

    let activePath = sessionStorage.getItem("activePath");
    if (activePath) {
      this.activePath = sessionStorage.getItem("activePath");
    } else {
      this.activePath = "warehousing";
    }
  },

  mounted() {
    this.screenWidth = document.body.clientWidth;
    if (this.screenWidth < 1200) {
      this.wid = "27%";
      this.isCollapse = true;
    } else {
      this.isCollapse = false;
    }
    window.onresize = () => {
      return (() => {
        this.wid = "19%";
        this.screenWidth = document.body.clientWidth;
      })();
    };

    sessionStorage.setItem("switch_WB", "0"); //固定书单模块：页面第一次进入存储固定参数；切换轮播和书单依据； 0为轮播；1为书单
  },

  watch: {
    screenWidth: {
      immediate: true,
      handler(newValue) {
        if (newValue < 1200) {
          this.isCollapse = true;
        } else {
          this.isCollapse = false;
        }
      },
    },
  },
  methods: {
    // 存储页面刷新时导航栏某项高亮显示
    saveNavState(activePath) {
      sessionStorage.setItem("activePath", activePath);
      // this.activePath = activePath;
      this.$router.push({ path: activePath });
    },

    // 退出登录
    loginOut() {
      this.$router.push("/login");
      if (this.$route.path == "/login") {
        removeUserDetails();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-menu {
  border-right: solid 0.00526rem #eceef5;
}
::v-deep .el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 264px;
}
.nav_class {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 500;
  margin-left: 5px;
  color: #333333;
}

.item_header {
  font-size: 18px;
}

.item_css {
  font-size: 16px;
}

.log_wrap {
  margin-top: 43px;
  width: 100%;
  margin-bottom: 43px;
  height: 63px;
  max-height: 63px;
  box-sizing: border-box;
  padding: 0 15px;

  display: flex;
}

.log_ig img {
  height: 57px;
}

.log_describe {
  font-family: ziyuechuzhiyuan-Regular, ziyuechuzhiyuan;
  font-weight: 700;
  color: #333333;
  margin-top: 5%;
  margin-left: 13px;
}
.lg_de_p1 {
  width: 80%;
  margin-bottom: 5%;
}

.lg_de_p2 {
  width: 60%;
}

.log_wrap2 {
  width: 100%;
  height: 63px;
  margin-top: 40px;
  margin-bottom: 26px;
  text-align: center;
}

.log_wrap2 > img {
  height: 50px;
  margin-top: 6px;
}

.icon_tsx {
  width: 17px;
  height: 17px;
  margin: 0 3px 0 3px;
}

.us_ig {
  vertical-align: middle;
  margin-right: 10px;
  width: 24px;
  height: 24px;
}

.user_cut {
  margin: 0 20px;
}

.user_flex {
  display: flex;
}

::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: rgb(221, 2, 27);
}

::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  border-color: rgb(221, 2, 27);
}

::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  box-shadow: -0.005rem 0 0 0 rgb(221, 2, 27);
}

::v-deep .el-button--text {
  color: rgb(221, 2, 27);
}

::v-deep .el-button--primary {
  background-color: rgb(221, 2, 27);
  border-color: rgb(221, 2, 27);
}

::v-deep .el-input__inner:focus {
  border-color: rgb(221, 2, 27) !important;
}

::v-deep .el-radio-button__inner {
  padding: 9px 9px;
}
</style>