<template>
  <div>
    <el-card style="margin-bottom: 10px">
      <div class="head_add" style="cursor: pointer" @click="addColumn">
        <span>添加栏目</span>
      </div>
    </el-card>
    <el-card>
      <el-table :data="tableData" height="70vh" style="width: 100%">
        <el-table-column type="index"  width="50">
        </el-table-column>
        <el-table-column prop="moduleTitle" label="模块名称"> </el-table-column>
        <el-table-column label="模块">
           <template slot-scope="scope">
             <span v-if="scope.row.moduleType==3">
                书籍模块一
             </span>
             <span v-else-if="scope.row.moduleType==4">
                书籍模块二
             </span>
             <span v-else-if="scope.row.moduleType==5">
                书籍模块三
             </span>
              <span v-else-if="scope.row.moduleType==6">
                书单模块
             </span>
             <span v-else-if="scope.row.moduleType==7">
                标签模块
             </span>
           </template>
        </el-table-column>
        <el-table-column prop="moduleSort" label="序号"> </el-table-column>
        <el-table-column  label="启用状态">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.check"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="isShow(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              style="color: #0bd46a"
              @click="handleClick(scope.row)"
              >编辑</el-button
            >
            <el-button type="text" size="small" @click="deleteItem(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.current"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="queryInfo.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>

    <!-- 编辑弹出层 -->
    <el-dialog title="提示" :visible.sync="addDialog" width="60%" center>
      <el-row>
        <div class="ModuleList_wrap">
          <el-col v-for="(intem, index) in ModuleList" :key="index">
            <div class="cent_img" @click="toBook(intem)">
              <img :src="intem.moduleImage" alt="" style="width: 80%" />
            </div>
            <div class="cent_txt">{{ intem.moduleTitle }}</div></el-col
          >
        </div>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialog = false">取 消</el-button>
        <el-button type="primary" @click="addDialog = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      ModuleList: [], //获取模块类型集合
      addDialog: false, //编辑弹出层
      queryInfo: {
        current: 1,
        size: 10,
      },
      total:0,
    };
  },
  mounted() {
    this.getcustom(); //获取自定义信息、
    this.getModuleList(); //获取新增模块
  },
  methods: {
    // 获取自定义信息
    async getcustom() {
      const { data } = await this.$http.get(
        "getBannerModuleList/" +
          this.queryInfo.current +
          "/" +
          this.queryInfo.size
      );
      let fixdBook = data.data.records;
      if (this.queryInfo.current == 1) {
        for (let i = fixdBook.length - 1; i >= 0; i--) {
          const item = fixdBook[i];
          if (item.moduleType == 1 || item.moduleType == 2) {
            fixdBook.splice(i, 1);
          }
        }
      }
      for (let i = 0; i < fixdBook.length; i++) {
        const item = fixdBook[i];
        if (item.isShow == 1) {
          item.check = false;
        } else {
          item.check = true;
        }
      }
      this.total = data.data.total;
      this.tableData = fixdBook;
    },

    // 获取模块信息
    async getModuleList() {
      const { data } = await this.$http.get("getModuleList/0");
      this.ModuleList = data.data;
      this.$store.commit("setBannerModuleDetails", data.data); //将该数据存储到公共数据中
    },

    // 新增栏目
    addColumn() {
      this.addDialog = true;
    },

    // 编辑
    handleClick(item) {
      item.Editstatus = 1; //编辑为1
      sessionStorage.setItem("itemsID", item.id); //存id
      sessionStorage.setItem("Satus", 2); //新增为1;  编辑为2
      this.$router.push({ path: "/editcustom", query: item });
    },

    // 删除栏目
    async deleteItem(item) {
      this.$confirm("是否删除该模块?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const { data } = await this.$http.get("removeBannerModule/" + item.id);
        if (data.ok) {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          setTimeout(() => {
            this.getcustom();
          }, 1000);
        } else {
          this.$message({
            type: "success",
            message: "删除失败!",
          });
        }
      });
    },

    // 是否启用
    async isShow(item) {
      let ishow = 0;
      if (item.check) {
        ishow = 0;
      } else {
        ishow = 1;
      }
      const { data } = await this.$http.get(
        "getModuleWhetherIsInfo/" + item.id
      );
      if (data.ok) {
        const {data}= await this.$http.get('openShowModule/'+item.id+"/"+ishow) 
      } else {
        item.check = false;
        let meg=""
        if (item.moduleType ==3 || item.moduleType ==4 ||item.moduleType ==5) {
          meg="书籍不满足6本，无法启用"
        }else if(item.moduleType ==6){
          meg="该模块无启用书单，无法启用"
        }else if(item.moduleType ==7){
          meg="该模块无标签，无法启用"
        }
        this.$message.error(meg);
      }
    },

    // 新增
    toBook(item) {
      item.Editstatus = 0; //新增为0
      sessionStorage.setItem("itemsSatus", -1); //存id
      sessionStorage.setItem("Satus", 1); //新增为1;  编辑为2
      this.$router.push({ path: "/editcustom", query: item });
    },

    handleSizeChange(val) {
     this.queryInfo.size = val;
     this.getcustom()
    },
    handleCurrentChange(val) {
     this.queryInfo.current = val;
     this.getcustom()
    },
  },
};
</script>
<style lang="scss" scoped>
.head_add {
  padding: 6px 12px;
  display: flex;
  justify-content: center;
  text-align: center;
  max-width: 72px;
  background: #ff1932;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
}

.ModuleList_wrap {
  display: flex;
  justify-content: flex-end;
}

.cent_img {
  text-align: center;
}

.cent_txt {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  color: #0e0e0e;
}
</style>