<template>
  <div class="login_container">
    <el-card class="login_wrap">
      <div class="classBox" :class="{ classBox_bottom: isActive }">
        <div :class="{ active: !isActive }" class="yh">
          <span @click="cutyonghu" class="login_sap">用户登录</span>
        </div>
        <div>|</div>
        <div :class="{ active: isActive }" class="wx">
          <span @click="cutvx" class="login_sap">微信登录</span>
        </div>
      </div>

      <!-- 登录区 -->
      <div v-if="!isActive">
        <div class="login_box">
          <!-- 表单区 -->
          <el-form
            ref="ruleForm"
            class="login_form"
            :model="login_form"
            :rules="loginFormRules"
            label-width="0px"
          >
            <!-- 用户名 -->
            <el-form-item prop="username">
              <el-input
                prefix-icon="el-icon-user-solid"
                v-model.trim="login_form.username"
              ></el-input>
            </el-form-item>
            <!-- 密码 -->
            <el-form-item prop="password" class="password_top">
              <el-input
                prefix-icon="el-icon-lock"
                v-model.trim="login_form.password"
                type="password"
              ></el-input>
            </el-form-item>
            <!-- 按钮 -->
            <el-form-item class="btns">
              <el-button
                type="primary"
                class="BGcolor"
                @click.enter.native="login('ruleForm')"
                >登录</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div v-if="isActive">
        <wxlogin
          :appid="appid"
          :scope="scope"
          :redirect_uri="redirect_uri"
          :href="href"
        ></wxlogin>
      </div>
    </el-card>

    <!--浮动 log -->
    <div class="log_wrap">
      <div class="log_img">
        <img src="../../assets/img/log.svg" alt="" />
      </div>
      <div class="log_text">
        <p>图书侠管理端</p>
        <p>TUSHUXIA</p>
      </div>
    </div>
  </div>
</template>

<script type='text/javascript' src="http://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js"></script>
<script>
import { UrlSearch } from "@/utils/item";
import {
  setUserDetails,
} from "@/utils/auth";
import wxlogin from "vue-wxlogin";
export default {
  name: "login",
  components: {
    wxlogin,
  },
  data() {
    return {
      // tabs双相绑定页
      activeName: "first",
      // appid: "wx8727e7376c22967f", 师者联盟
      appid: "wx0fac09cc30276c4a", //图书侠
      scope: "snsapi_login",
      // https://manager.tushuxia.com/#/login
      // https://test.tushuxia.com/#/login
      redirect_uri: encodeURIComponent("https://manager.tushuxia.com/#/login"),
      href: "data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHsKICAgIGRpc3BsYXk6IG5vbmU7Cn0KLmltcG93ZXJCb3ggLnN0YXR1cy5zdGF0dXNfYnJvd3NlciB7CiBkaXNwbGF5OiBub25lOwp9Ci5pbXBvd2VyQm94IC5xcmNvZGUgewogYm9yZGVyOiBub25lOwogd2lkdGg6IDIwMHB4OwogaGVpZ2h0OiAyMDBweDsKfQouaW1wb3dlckJveCAuc3RhdHVzewogZGlzcGxheTogbm9uZQp9Cg==",
      isActive: false,
      //绑定登录标点的数据
      login_form: {
        username: "",
        password: "",
      },
      // 验证用户名是否合法
      loginFormRules: {
        userForm: [
          { required: true, message: "请输用户名", trigger: "blur" },
          {
            min: 3,
            max: 10,
            message: "长度在 3 到 10 个字符",
            trigger: "blur",
          },
        ],
        // 验证密码是否合法
        password: [
          { required: true, message: "请输密码", trigger: "blur" },
          {
            min: 6,
            max: 15,
            message: "长度在 6 到 15 个字符",
            trigger: "blur",
          },
        ],

        // 1 监听页面宽度的变化 实时更新页面宽度
        screenWidth: document.body.clientWidth,
      },
    };
  },

  created() {
    this.keyupSubmit();
    if(window.location.hash=="#/login"){
      let res={}
      setUserDetails(res,1)
    }
  },

  mounted() {
    window.onhashchange = () => {
      let url = UrlSearch();
      sessionStorage.setItem("code", url);
      let prams = {};
      prams.jsCode = url;
      if (url.length==32) {
        this.wxlogin(prams.jsCode);
      }
    };

    // 屏幕跨度的变化
    this.screenWidth = document.body.clientWidth;
    if (this.screenWidth < 1150) {
      this.href ="data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlewpkaXNwbGF5Om5vbmUKfQoud2FpdGluZ3sKd2lkdGg6MjE2cHgKfQouaW1wb3dlckJveCAucXJjb2RlewogICAgd2lkdGg6IDUwJTsKfQoKLmltcG93ZXJCb3ggLmluZm97CndpZHRoOjIxNnB4Owp9Cg=="
    } 
    else if (this.screenWidth >= 1150) {
      this.href ="data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlewpkaXNwbGF5Om5vbmUKfQoKLndhaXRpbmcgewogd2lkdGg6OTclCn0KLmltcG93ZXJCb3ggLnFyY29kZXsKd2lkdGg6ODAlOwptYXJnaW4tdG9wOiAxNXB4Owp9";
    }
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
  },

  methods: {
    cutyonghu() {
      this.isActive = false;
    },
    cutvx() {
      this.isActive = true;
    },

    // 登录事件
    login(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (!valid) return;
        const { data } = await this.$http.post("loginPC", this.login_form);
        let res = data.data;
        if (data.code == -1) return this.$message.error("请核对用户名或密码");
        this.$message({
          message: "登录成功",
          type: "success",
        });
        setUserDetails(res,1);
        // 2.页面跳转
        this.$router.push("/home");
      });
    },

    //微信扫码登录
    wxlogin(prams) {
      if (prams.indexOf("127.0.0.1") != -1 || prams.indexOf("http://localhost/") != -1) {
         return;
      } else {
        this.$http.get("wxCallback/" + prams).then(res=> {
          if (!res.data.ok)  return this.$message.error("登录失败，请联系管理员")
          let data = res.data.data;
          setUserDetails(data,1);
          // 2.页面跳转
          this.$router.push("/home");
        });
      }
    },

    // 绑定Enter事件
    keyupSubmit() {
      document.onkeydown = (e) => {
        let _key = window.event.keyCode;
        if (_key === 13) {
          this.login("ruleForm");
        }
      };
    },
  },
};
</script>


<style lang="scss" scoped>
.login_container {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}
// fixed log
.log_wrap {
  position: fixed;
  top: 48px;
  left: 48px;
  display: flex;
}

.log_img > img {
  width: 67px;
  height: 67px;
  margin-right: 22px;
}

.log_text {
  font-size: 27px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 39px;
  p:first-child {
    display: inline-block;
  }
  p:nth-child(2) {
    margin-top: 10px;
    font-size: 17px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 23px;
    letter-spacing: 1px;
  }
}

.CheckBgc {
  color: #fa2800;
}
.classBox {
  display: flex;
  font-size: 23px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  margin-bottom: 90px;
  line-height: 32px;
}
.password_top {
  margin-top: 44px;
}

.classBox_bottom {
  margin-bottom: 49px !important;
}

.btns {
  margin-top: 58px;
}

.login_wrap {
  width: 500px;
  height: 460px;
  border-radius: 4px;
  // box-sizing: border-box;
  padding: 68px 80px;
  text-align: center;
}

::v-deep .el-card__body {
  padding: 0;
}

.yh {
  width: 100%;
  text-align: center;
}
.wx {
  width: 100%;
  text-align: center;
}

.login_box {
  margin-top: 67px;
}

::v-deep .el-button {
  width: 100%;
}

// 选中颜色
.active {
  color: #fd4a10;
}

@media only screen and (min-width: 1200px) {
  span {
    font-size: 20px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1199px) {
  div {
    font-size: 23px !important;
  }
}
.iframe{
  width: 200px;
  height: 200px;
}
 
</style>