<template>
  <div>
    <el-card>
        <div class="flex title">
            <div style="margin-left:38px;margin-right:24px;"><p>快递选择</p></div>
            <div><p>中通快递</p></div>
        </div>
        <div class="flex">
            <el-form
                :model="formLabelAlign"
                :rules="rules"
                ref="ruleForm"
                label-width="120px"
                :inline="true"
                class="demo-ruleForm"
            >
                <el-form-item label="首重费（元）" prop="firstWeight">
                <el-input v-model="formLabelAlign.firstWeight" ></el-input>
                </el-form-item>
                <el-form-item label="续重费（元）" prop="additionalWeight">
                <el-input v-model="formLabelAlign.additionalWeight" ></el-input>
                </el-form-item>
            </el-form>
            <div class="adds" @click="add()"><p>添加</p></div>
        </div>
        
            
        <div class="rule" v-for="(item,i) in expressFeeTemplateList" :key="i">
            <el-select v-model="item.province" placeholder="请选择" style="margin-top:9px;margin-left:10px;" class="dizhi">
                <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.label">
                </el-option>
            </el-select>
            <el-form
            :model="item"
            :rules="rules"
            ref="ruleForm"
            label-width="120px"
            :inline="true"
            class="demo-ruleForms"
            >
                <el-form-item label="首重费（元）" prop="firstWeight">
                <el-input v-model="item.firstWeight" ></el-input>
                </el-form-item>
                <el-form-item label="续重费（元）" prop="additionalWeight">
                <el-input v-model="item.additionalWeight" ></el-input>
                </el-form-item>
            </el-form>
            <div class="add" @click="add()"><p>添加</p></div>
            <div class="del" @click="del(i)"><p>删除</p></div>
        </div>
        <div class="sub" @click="sub"><p>保存</p></div>
    </el-card>
  </div>
</template>

<script>
 import { provinceAndCityData } from 'element-china-area-data'
export default {
    data() {
      return {
        tableData: [
        ],
        formLabelAlign: {
            firstWeight:'',additionalWeight:"",province:''
        },
        ruleForm: {},
        options:provinceAndCityData,
        rules: {
            firstWeight: [
            { required: true, message: '请输入首重费', trigger: 'blur' },
            ],
            additionalWeight: [
            { required: true, message: '请输入续重费', trigger: 'blur' },
            ],
        },
        expressFeeTemplateList:[
            {
                firstWeight:'',additionalWeight:"",province:''
            }
        ],
        loading: true, //加载中
      };
    },
    mounted() {
        this.rulelist();
    },
  
    methods: {
      // 0.获取书籍列表
      async rulelist(e) {
        let params ={
          province:"ALL",
          expressType:0,
        }
        let { data } = await this.$http.post("getExpressFeeTemplateList", params);
        for(let i in data.data){
            if(data.data[i].province==''){
                this.formLabelAlign=data.data[i]
                data.data.splice(i,1)
            }
        }
        if(data.data.length!=0){
            this.expressFeeTemplateList=data.data
        }
      },
      add(){
        let obj = {
             firstWeight:'',additionalWeight:"",province:''
        }
        this.expressFeeTemplateList.push(obj)
      },
      del(key){
        // if(this.expressFeeTemplateList.length  ==1){
        //     return this.$message.error('错了哦，这是最后一条');
        // }
        this.expressFeeTemplateList.splice(key,1)
      },
      async sub(){
        if(this.formLabelAlign.firstWeight==''){
            return this.$message.error('请填写默认规则首重');
        }
        if(this.formLabelAlign.additionalWeight==''){
            return this.$message.error('请填写默认规则续重');
        }
        if(this.expressFeeTemplateList.length !=0){
            for(let i in this.expressFeeTemplateList){
                if(this.expressFeeTemplateList[i].firstWeight==''){
                    return this.$message.error('请填写特殊规则首重');
                }
                if(this.expressFeeTemplateList[i].additionalWeight==''){
                    return this.$message.error('请填写默认规则续重');
                }
                if(this.expressFeeTemplateList[i].province==''){
                    return this.$message.error('请选择省份');
                }
            }
        }
        let arr = JSON.parse(JSON.stringify(this.expressFeeTemplateList)) 
        arr.push(this.formLabelAlign)
        
        let params = {
            expressFeeTemplateList:arr,
            expressType:0
        }
        const { data } = await this.$http.post("saveExpressFeeTemplateBatch", params)
        if (data.ok) {
            this.$message.success("操作成功")
            this.rulelist()
        } else {
            this.$message.error("资料不全")
        }
      }
    },
  };
</script>

<style scoped>
.flex{
    display: flex;
}
.title{
    margin-top: 39px;
}
.rule{
    width: 1290px;
    height: 58px;
    background: #F1F3F4;
    border-radius: 6px;
    /* opacity: 0.8; */
    margin-left: 20px;
    display: flex;
    margin-bottom: 24px;
}
.demo-ruleForm{
    margin-top: 24px;
    margin-left: 20px;
}
.demo-ruleForms{
    margin-top: 10px;
    margin-left: 20px;
}
::v-deep .dizhi  .el-input__inner {
    border: none;
    box-shadow: none;
    background-color: #F1F3F4;
    color: #333333;
}  
::v-deep .el-select__caret{
    color: #333333;
}
::v-deep input::-webkit-input-placeholder{
  
  -webkit-text-fill-color: #333333;
}
.add{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    margin: 18px 21px 0 50px;
}
.adds{
    width: 60px;
    height: 26px;
    background: #DD021B;
    border-radius: 3px;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 26px;
    text-align: center;
    margin-top: 30px;
    margin-left: 30px;
}
.del{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #DD021B;
    margin-top: 18px;
}
.sub{
    width: 80px;
    height: 36px;
    background: #DD021B;
    border-radius: 3px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 36px;
    text-align: center;
    margin-top: 48px;
    margin-left: 30px;
}
</style>