<template>
  <div>
    <div >
      <div class="booklid_wrap">
        <el-input
          placeholder="输入图书名"
          v-model.trim="queryInfo.bookName"
          class="ipt_Submit ipt1"
        ></el-input>
         <el-input
          placeholder="输入图书书号"
          v-model.trim="queryInfo.isbnCode"
          onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
          class="ipt_Submit ipt1"
        ></el-input>
         <el-input
          placeholder="输入可售库存数量下限"
          v-model.trim="queryInfo.vendibilityInventoryStart"
          onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
          class="ipt_Submit ipt1"
        ></el-input>
        <el-input
          placeholder="输入可售库存数量上限"
          v-model.trim="queryInfo.vendibilityInventoryEnd "
          onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
          class="ipt_Submit ipt1"
        ></el-input>
        <button
          class="btn_Submit"
          @click="bookReport(1)"
          v-on:keyup.enter="bookReport(1)"
        >
          查询
        </button>
        <div class="clear"
          @click="clear()"
        >
            <img src="@/assets/img/clear.png" alt="">
            <p>清除条件</p>
        </div>
      </div>

    </div>
    <el-card>
      
      <el-table
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        :data="tableData"
        :cell-style="colour"
        height="430"
      >
        <el-table-column prop="image" label="图书封面" >
          <template slot-scope="scope">
            <img :src="scope.row.image" alt="" srcset="" style="width: 74px" />
          </template>
        </el-table-column>
        <el-table-column prop="bookName" label="书名" width="290">
        </el-table-column>
        <el-table-column prop="isbnCode" label="ISBN" width="180">
        </el-table-column>
        <el-table-column prop="inventory" label="买进数量">
        </el-table-column>
        <el-table-column prop="vendibilityInventory" label="可售库存">
        </el-table-column>
        <el-table-column prop="putawayedCount" label="待上架">
        </el-table-column>
        <el-table-column prop="loseCount" label="丢失">
        </el-table-column>
        <el-table-column prop="borrowTotalCount" label="在借数量">
        </el-table-column>
        <el-table-column prop="borrowNewCount" label="全新" >
        </el-table-column>
        <el-table-column prop="borrowGoodCount" label="良好">
        </el-table-column>
        <el-table-column prop="borrowOrdinaryCount" label="普通">
        </el-table-column>
         <el-table-column prop="buyOutTotalCount" label="买断数量">
        </el-table-column>
         <el-table-column prop="buyOutNewCount" label="全新">
        </el-table-column>
         <el-table-column prop="buyOutGoodCount" label="良好">
        </el-table-column>
        <el-table-column prop="buyOutOrdinaryCount" label="普通">
        </el-table-column>
        <el-table-column prop="saleCount" label="借出次数">
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="1"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
    data() {
      return {
        tableData: [
        ],
  
        queryInfo: {
          current: 1,
          size: 10,
          bookName:'',
          isbnCode:'',
          vendibilityInventoryStart:'',
          vendibilityInventoryEnd:'',
        },
  
        currentPage: 1,
        total: 0,
        loading: true, //加载中
      };
    },
    mounted() {
      this.bookReport(1); //获取提现订单列表
    },
  
    methods: {
      // 0.获取书籍列表
      async bookReport(e) {
        if(e==1){
          this.queryInfo.current=1;
        }
        let params ={
          current:this.queryInfo.current,
          size:this.queryInfo.size,
          bookName:this.queryInfo.bookName,
          isbnCode:this.queryInfo.isbnCode,
          vendibilityInventoryStart:this.queryInfo.vendibilityInventoryStart,
          vendibilityInventoryEnd:this.queryInfo.vendibilityInventoryEnd
        }
        let { data } = await this.$http.post("bookReport", params);
        this.tableData = data.data.list;
        this.total = data.data.total;
        this.loading = false;
      },
      colour({row, column, rowIndex, columnIndex}){       
        if(columnIndex == 8 || columnIndex == 9|| columnIndex == 10|| columnIndex == 12|| columnIndex == 13|| columnIndex == 14) {
          return "color: #999999;"
        }
      },
      clear(){
        this.queryInfo.bookName=''
        this.queryInfo.isbnCode=''
        this.queryInfo.vendibilityInventoryStart=''
        this.queryInfo.vendibilityInventoryEnd=''
      },
      // 1.撤销操作
      deleteRow(item) {
        let params = {};
        params.id = item.id;
        params.status = 1;
        this.$confirm("是否撤销该提现申请?", "申请处理", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(async () => {
            const { data } = await this.$http.post("updateExamineStatus", params);
            if (data.data) {
              this.etWithdrawDepositList();
              this.$message({
                type: "success",
                message: "撤销成功!",
              });
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      },
  
      // 3.分页
      handleSizeChange(val) {
        this.queryInfo.size = val;
        this.bookReport(0);
      },
      handleCurrentChange(val) {
        this.queryInfo.current = val;
        this.bookReport(0);
      },
    },
  };
</script>

<style scoped>
.booklid_wrap {
  /* width: 100%; */

  display: flex;
  margin-bottom: 21px;
  /* justify-content: space-between; */
}


.ipt_Submit {
  width: 200px;
}

.ipt_Submit ::v-deep input {
  height: 35px;
}

.btn_Submit {
  margin-left: 10px;
}

.ipt1 {
  margin-right: 10px;
}
.el-table{
  color: #333333;
}
::v-deep.el-table th > .cell {
  text-align: center;
}

::v-deep.el-table .cell {
  text-align: center;
}
.clear{
  display: flex;
  margin-top: 9px;
  margin-left: 22px;
  color: #999999;
}
.clear img{
  width: 16px;
  height: 16px;
}
</style>