<template>
  <div>
    <el-card style="padding: 25px 50px 21px">
      <div class="img_top">编辑书单</div>
      <el-row :gutter="20">
        <el-col :span="16">
          <el-col :span="5">
            <el-upload
              class="avatar-uploader"
              action
              :show-file-list="false"
              :auto-upload="true"
              :limit="1"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img
                v-if="form.bookListImage"
                :src="form.bookListImage"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-col>
          <el-col :span="18">
            <el-form ref="form" :model="form" :rules="rules" label-width="80px">
              <el-form-item label="书单名称" prop="bookListTitle">
                <el-input v-model="form.bookListTitle"></el-input>
              </el-form-item>
              <el-form-item label="书单描述" prop="bookListInfo">
                <el-input v-model="form.bookListInfo"></el-input>
              </el-form-item>
              <el-form-item label="序号" prop="bookListSort">
                <el-input v-model.number="form.bookListSort"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  class="btn_Submit"
                  style="background: #ff1932"
                  @click="onSubmit('form')"
                  :loading="loadingBut"
                  >{{ loadingButtext }}</el-button
                >
              </el-form-item>
            </el-form>
          </el-col>
        </el-col>
      </el-row>
    </el-card>

    <el-card style="margin-top: 20px" v-if="BookStatus == 1">
      <el-row>
        <el-col :span="24" class="edit_btn_Wrap">
          <div class="EB_Style">书单内书籍</div>
          <div class="upbook" @click="addBook">添加书籍</div>
        </el-col>
      </el-row>
      <template class="table_top">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column label="书籍封面">
            <template slot-scope="scope">
              <img :src="scope.row.bookImage" style="width: 80px" alt="" />
            </template>
          </el-table-column>
          <el-table-column prop="bookName" label="书籍名称"> </el-table-column>
          <el-table-column prop="bookSort" label="序号"> </el-table-column>
          <el-table-column prop="address" label="操作">
            <template slot-scope="scope">
              <el-button
                @click="handleClick(scope.row)"
                type="text"
                size="small"
                style="color: #0bd46a"
                >编辑</el-button
              >
              <el-button
                v-if="prohibit"
                @click.native.prevent="deleteRow(scope.row)"
                type="text"
                size="small"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </el-card>

    <!-- 右侧弹出层 -->
    <el-drawer
      :with-header="false"
      :visible.sync="table"
      direction="rtl"
      size="50%"
      @close="hideDrawer"
    >
      <el-card>
        <el-col :span="24">
          <div class="wrap_cx">
            <div>
              <el-input
                placeholder="请输入内容"
                v-model="input3"
                class="input-with-select"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="queryBook"
                ></el-button>
              </el-input>
            </div>
            <el-row class="addupbook">
              <div class="cancel" style="margin-right: 10px" @click="cancal">
                取消
              </div>
              <div class="upbook" @click="upBook">添加</div>
            </el-row>
          </div>
        </el-col>
      </el-card>
      <el-card>
        <el-table
          :data="gridData"
          ref="multipleTable"
          :show-header="false"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          @row-click="handleRowClick"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column property="title" label="书名"></el-table-column>
          <el-table-column property="isbn" label="isbn"></el-table-column>
          <el-table-column property="bookAlias" label="别名"></el-table-column>
        </el-table>
      </el-card>
    </el-drawer>

    <!-- 编辑排序 -->
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <el-form
        :model="numbersolt"
        ref="numberValidateForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item
          label="序号"
          prop="bookSort"
          :rules="[
            { required: true, message: '序号不能为空' },
            { type: 'number', message: '序号必须为数字值' },
          ]"
        >
          <el-input
            type="bookSort"
            v-model.number="numbersolt.bookSort"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('numberValidateForm')"
            >提交</el-button
          >
          <el-button @click="resetForm('numberValidateForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        bookListTitle: "",
        bookListInfo: "",
        bookListSort: 1,
        bookListImage: "",
        isShow: 1, //默认不启用
      },
      isShow: false, //是否启用
      table: false, //抽屉
      dialogVisible: false, //排序弹出层
      prohibit: true, //可以删除

      loadingButtext: "保存",
      loadingBut: false,

      tableData: [], //书籍

      gridData: [],

      // 排序
      numbersolt: {
        bookSort: 0,
      },

      input3: "",
      SerialNumber: 0, //存储下来的序号。重置时，使用

      // 效验
      rules: {
        bookListTitle: [
          { required: true, message: "请输入书单名称", trigger: "blur" },
        ],
        bookListInfo: [
          { required: true, message: "请输入书单描述", trigger: "blur" },
        ],
        bookListSort: [
          { required: true, message: "序号不能为空" },
          { type: "number", message: "序号必须为数字值" },
        ],
      },

      multipleSelection: [], //选中书籍
      BookStatus: 0, //获取上一页传递来的参数1 编辑；0新增
    };
  },
  mounted() {
    let item = this.$route.query;
    this.form.bannerModuleId = item.bannerModuleId; //栏目id
    this.BookStatus = item.BookStatus; //获取上一页传递来的参数
    if (item.BookStatus == 1) {
      item.bookListSort = Number(item.bookListSort);
      this.form = item;
      this.getBookModuleByBookList(item.id);
    }
    this.keyupSubmit();
  },

  methods: {
    // 获取书单中的书籍
    async getBookModuleByBookList(id) {
      const { data } = await this.$http.get("getBookModuleByBookList/" + id);
      this.form.bannerModuleId = data.data.bannerModuleId;
      this.form.bookListImage = data.data.bookListImage;
      this.form.bookListInfo = data.data.bookListInfo;
      this.form.bookListSort = data.data.bookListSort;
      this.form.bookListTitle = data.data.bookListTitle;
      this.form.isShow = data.data.isShow;
      this.BookListID = data.data.id;
      if (data.data.isShow == 0) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
      if (data.data.bookModuleList.length <= 1) {
        this.prohibit = false; //禁止删除
      } else {
        this.prohibit = true; //可以删除
      }
      this.tableData = data.data.bookModuleList;
    },

    // 是否可以启用
    isSHOW() {
      if (this.tableData.length < 1) {
        this.isShow = false;
        this.$message.error("书单下书籍不能少于1本");
      }
    },

    beforeAvatarUpload(file) {
      const typeArr = ["image/png", "image/gif", "image/jpeg", "image/jpg"];
      const isJPG = typeArr.indexOf(file.type) !== -1;
      const isLt3M = file.size / 1024 / 1024 < 3;
      if (!isJPG) {
        this.$message.error("只能是图片!");
        this.files = null;
        return;
      }
      if (!isLt3M) {
        this.$message.error("上传图片大小不能超过 3MB!");
        this.files = null;
        return;
      }
      this.files = file;
      // FormData 对象
      var formData = new FormData();
      // 文件对象
      formData.append("file", this.files);
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        methods: "post",
      };
      this.$http.post("uploadAboutBannerFile", formData, config).then((res) => {
        if (res.status == 200) {
          this.form.bookListImage = res.data.data;
        } else {
          this.$message.error(" 上传图片失败");
        }
      });
    },

    handleAvatarSuccess(res, file) {
      this.$refs.upload.clearFiles(); //上传成功之后清除历史记录
      this.handleInitTableData(); //初始化表格数据
    },

    // 2.编辑书单--- 排序编辑
    handleClick(item) {
      this.dialogVisible = true;
      this.SerialNumber = item.bookSort;
      let items =JSON.parse(JSON.stringify(item));
      this.numbersolt = items;
    },

    // 2.0排序提交
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let params = {};
          params.id = this.numbersolt.id;
          params.sort = this.numbersolt.bookSort;
          const { data } = await this.$http.post("updateBookModule", params);
          if (data.ok) {
            this.dialogVisible = false;
            this.getBookModuleByBookList(this.form.id);
          }
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    // 2.0-1重置
    resetForm(formName) {
    this.numbersolt.bookSort = this.SerialNumber;
        this._watcher.run()
    },

    //3.0 添加书籍 弹出层
    addBook() {
      this.table = true;
    },

    // 3.0-2 //添加
    async upBook() {
      let multipleSelection = this.multipleSelection;
      let params = {};
      params.bookModuleList = [];
      for (let i = 0; i < multipleSelection.length; i++) {
        const item = multipleSelection[i];
        let intems = {};
        intems.bookAuthor = item.author;
        intems.listModuleId = this.form.id;
        intems.listOrModule = 0; // 书单还是模块 0书单 1模块
        intems.bookPublisher = item.publisher;
        intems.bookPrice = item.price;
        intems.bookName = item.title;
        intems.bookImage = item.img;
        intems.bookId = item.id;
        intems.bookAlias = item.bookAlias;
        intems.bookSort = 0;
        params.bookModuleList.push(intems);
      }
      params.listModuleId=this.form.id; //模板或者书单id
      params.listOrModule=0; // 书单还是模块 0书单 1模块
      const { data } = await this.$http.post("addBookToModule", params);
      if (data.ok) {
        this.$message({
          message: "添加成功",
          type: "success",
          duration: 1000,
        });
        this.getBookModuleByBookList(this.form.id);
        this.queryBook();
      }
    },

    cancal() {
      this.table = false;
    },

    //3.0-1 添加书籍  --  右侧弹出层书籍
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    //点击行触发，选中或不选中复选框
    handleRowClick(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },

    // 3.0-1 模糊查询 获取到书籍
    async queryBook() {
      const { data } = await this.$http.get(
        "getBannerModuleBook/" + this.input3 + "/" + this.BookListID + "/" + "0" // 0 书单; 1模块；
      );
      this.gridData = data.data;
    },

    // 3.0-2 //关闭书籍
    hideDrawer() {
      this.input3 = "";
      this.gridData = [];
      this.multipleSelection = [];
    },

    // 删除 书籍
    async deleteRow(item) {
      this.$confirm("是否删除该书籍?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await this.$http.get("removeBookModule/" + item.id);
          if (data.ok) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getBookModuleByBookList(this.form.id);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    // 提交
    onSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.form.bookListSort < 1) {
            return this.$message.error("序号不能小于1");
          }
          if (this.form.bookListImage == "") {
            return this.$message.error("主图不能为空");
          }
          this.loadingBut = true;
          this.loadingButtext = "保存中";
          if (this.BookStatus == 0) {
            this.form.isShow = 1;
            const { data } = await this.$http.post("createBookList", this.form); //创建书单
            this.loadingBut = false;
            this.loadingButtext = "保存";
            this.BookStatus = 1; // 新增过后页面数据替换
            this.form.id = data.data;
            this.getBookModuleByBookList(data.data);
          } else {
            const { data } = await this.$http.post("updateBookList", this.form); //编辑书单
            if (data.ok) {
              this.$message.success("该书单保存成功");
            }
            this.loadingBut = false;
            this.loadingButtext = "保存";
          }
        }
      });
    },

    //添加一个监听enter事件
    keyupSubmit() {
      document.onkeydown = (e) => {
        let _key = window.event.keyCode;
        if (_key === 13) {
          this.queryBook();
        }
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.img_top {
  margin-bottom: 20px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  line-height: 96px;
  text-align: center;
  width: 118px;
  height: 96px;
  background: rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  border: 1px dotted rgba(0, 0, 0, 0.15);
}
.avatar {
  width: 118px;
  height: 96px;
  background: rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  border: 1px dotted rgba(0, 0, 0, 0.15);
  display: block;
}

.edit_btn_Wrap {
  display: flex;
  justify-content: space-between;

  .EB_Style {
    font-weight: 700;
    padding: 5px 0;
  }

  .upbook {
    padding: 5px 14px;
    color: #fff;
    text-align: center;
    background: #ff1932;
  }
}

.wrap_cx {
  display: flex;
  align-content: center;
  justify-content: space-between;
  .addupbook {
    display: flex;
    .upbook {
      padding: 0 14px;
      line-height: 32px;
      height: 32px;
      color: #fff;
      border-radius: 2px;
      text-align: center;
      background: #ff1932;
    }

    .cancel {
      padding: 0 14px;
      line-height: 32px;
      height: 32px;
      border-radius: 2px;
      text-align: center;
      border: 1px solid #ccc;
    }
  }
}
</style>