  <template>
  <div>
    <!-- 新建 -->
    <!-- <div class="btn_weap">
      <div class="BGC_RED btn_ant" @click="ShowRt">新建规则</div>
    </div> -->
     <el-button
      @click="ShowRt"
      style="background-color: #ff1932; color: #fff"
      >新建规则</el-button
    >
    <!-- 书籍列表 -->
    <el-card style="margin-top: 10px">
      <el-table
        :data="tableData"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        style="width: 100%"
        ref="table"
        :highlight-current-row="true"
      >
        <el-table-column type="index" width="50" label="序号">
        </el-table-column>
        <el-table-column prop="ruleTitle" label="规则标题"> </el-table-column>

        <el-table-column prop="ruleType" label="展示位置">
          <template slot-scope="scope">
            <div v-if="scope.row.ruleType == 0">暂不发布</div>
            <div v-else-if="scope.row.ruleType == 1">借阅规则</div>
            <div v-else-if="scope.row.ruleType == 2">预估退款规则</div>
            <div v-else-if="scope.row.ruleType == 3">支付规则</div>
             <div v-else-if="scope.row.ruleType == 4">运费规则</div>
          </template>
        </el-table-column>

        <el-table-column prop="address" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="mini"
              style="color: #2689f7"
              @click="handleEdit(scope.row, scope.$index)"
              >编辑</el-button
            >
            <el-button
              v-if="scope.row.ruleType != 4"
              size="mini"
              type="text"
              @click="handleDelete(scope.row, scope.$index)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { setRuleitem, setRuleList } from "../../utils/auth.js";
export default {
  data() {
    return {
      tableData: [
      ],

      loading: false, //加载中

      dia: false,

      ruleTypelist: [], //已经创建的借阅规则
    };
  },

  created() {
    this.getBorrowRuleInfo();
  },

  methods: {
    // -----------借阅规则 0 添加 1编辑

    //获取借阅规则详情
    async getBorrowRuleInfo() {
      const { data } = await this.$http.get("getBorrowRuleList");
      this.tableData = data.data;
      let ruleTypelist = this.tableData.map((v) => {
        return v.ruleType;
      });
      this.ruleTypelist = ruleTypelist;
    },

    // 编辑
    handleEdit(item, i, status) {
      setRuleitem(item);
      let params = {};
      (params.status = 1), (params.ruleTypelist = this.ruleTypelist);
      setRuleList(params);
      this.$router.push({
        path: "/rule",
        name: "rule",
      });
    },

    // 删除
    handleDelete(item, i) {
      // 1借书 2还书 3支付
      let title=''
      if(item.ruleType>0){
        title='该规则正在使用中，确认要删除吗？'
      }else{
        title='是否删除该规则?'
      }
      this.$confirm(title, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await this.$http.get(
            "removeBorrowRuleById/" + item.id
          );
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getBorrowRuleInfo();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    // 显示添加
    ShowRt() {
      let params = {};
      (params.status = 0), (params.ruleTypelist = this.ruleTypelist);
      setRuleList(params);
      this.$router.push({
        path: "/rule",
        name: "rule",
      });
    },
  },
};
</script>

<style scoped>
.btn_weap {
  width: 100%;
}

.btn_ant {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid #dcdfe6;
  color: #fff;
  -webkit-appearance: none;
  text-align: center;
  /* box-sizing: border-box; */
  outline: 0;
  margin: 0;
  -webkit-transition: 0.1s;
  transition: 0.1s;
  font-weight: 500;
  padding: 10px 20px;
  font-size: 12px;
  border-radius: 6px;
}


</style>