<template>
  <el-card>
    <div class="booklid_wrap">
      <div>
        <el-input
          placeholder="搜索书籍名称/ISBN码"
          v-model.trim="queryInfo.keyword"
          class="ipt_Submit ipt1"
        ></el-input>
        <button
          class="btn_Submit"
          @click="getDataList(1)"
          v-on:keyup.enter="getDataList(1)"
        >
          查询
        </button>
      </div>

      <div>
        <button class="btn_Submit" @click="allshowList()">批量售卖</button>
        <button class="btn_Submit" @click="allhideList()">批量下架</button>
      </div>
    </div>

    <!-- 一级 标签 -->
    <el-row :gutter="24" class="laber_wrap">
      <el-col :span="24" style="display: flex">
        <div
          class="laber_style"
          :class="{ checkoutREd: checkout == 1 }"
          @click="switchTag(1)"
        >
          <div>一级标签</div>
          <div>>></div>
        </div>

        <div
          class="laber_style"
          :class="{ checkoutREd: checkout == 2 }"
          @click="switchTag(2)"
        >
          <div>二级标签</div>
          <div>>></div>
        </div>
        <div
          class="laber_style"
          :class="{ checkoutREd: checkout == 3 }"
          @click="switchTag(3)"
        >
          三级标签
        </div>
      </el-col>
    </el-row>
    <!-- 二级 赛选层 -->
    <div v-if="bookTAgList">
      <el-tabs type="border-card">
        <el-tab-pane
          :label="item.name"
          v-for="item in showTag"
          :key="item.id"
          class="one_tag_wrap"
        >
          <div
            class="itemTag"
            v-for="item_tag in item.bookTagChildrenList"
            :key="item_tag.id"
            :class="{ check_item_Tag: item_tag.checkout }"
            @click="cheTAg(item_tag, item)"
          >
            {{ item_tag.name }}
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <!-- 三级 选中的标签 -->
    <div :gutter="24" class="Confirm_Wrap">
      <div class="itemTag_wrap">
        <div
          class="itemTag_within"
          v-for="(item, index) in ChekTertiaryList"
          :key="item.id"
        >
          <div>{{ item.name }}</div>
          <div class="delTag" @click="del_item_Tag2(item)">×</div>
        </div>
      </div>
      <button class="btn_Submit ConfirmTag" @click="ConfirmTag(1, 1)">
        确认提交
      </button>
    </div>

    <el-table
      :data="tableData"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      style="width: 100%"
      ref="table"
      :highlight-current-row="true"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column
        class="custom-tree-node"
        prop="image"
        label="图书封面"
        width="120"
      >
        <template slot-scope="scope">
          <img :src="scope.row.img" alt="" srcset="" style="width: 60px" />
        </template>
      </el-table-column>
      <el-table-column prop="title" label="书籍名称"> </el-table-column>
      <el-table-column prop="inventory" label="库存" width="140">
      </el-table-column>
      <el-table-column prop="vendibilityInventory" label="可售库存" width="140">
      </el-table-column>
      <el-table-column label="是否售卖" width="140">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.BookIsShow"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="BookIsShowChang(scope.row, scope.$index)"
          >
          </el-switch>
        </template>
      </el-table-column>

      <el-table-column prop="isbn" label="ISBN码"> </el-table-column>
      <el-table-column prop="lastEntryTime" label="入库时间"> </el-table-column>
      <el-table-column prop="author" label="操作">
        <template slot-scope="scope">
          <el-button
            class="btn_right"
            type="text"
            icon="el-icon-edit"
            size="mini"
            @click="showloeding(scope.row)"
          >
            修改</el-button
          >

          <!-- <el-button
            v-if="scope.row.bookInfoChildrenList.length != 0"
            type="text"
            size="mini"
            @click="handleToogleExpand(scope.row)"
            >查看子书</el-button
          > -->
          <el-button
            v-if="
              scope.row.bookInfoChildrenList &&
              scope.row.bookInfoChildrenList.length != 0
            "
            type="text"
            size="mini"
            @click="handleToogleExpand(scope.row)"
            >查看子书</el-button
          >
        </template>
      </el-table-column>
      <el-table-column type="expand" width="1">
        <template>
          <el-table
            :data="childrenData"
            :show-header="false"
            style="width: 100%"
          >
            <el-table-column class="custom-tree-node" prop="image" width="200">
              <template slot-scope="scope">
                <img
                  :src="scope.row.img"
                  alt=""
                  srcset=""
                  style="width: 60px"
                />
              </template>
            </el-table-column>
            <el-table-column prop="title"> </el-table-column>
            <el-table-column prop="inventory"> </el-table-column>
            <el-table-column prop="isbn"> </el-table-column>
            <el-table-column prop="lastEntryTime"> </el-table-column>
            <el-table-column prop="author">
              <template slot-scope="scope">
                <el-button
                  class="btn_right"
                  type="text"
                  icon="el-icon-edit"
                  size="mini"
                  @click="showloeding(scope.row)"
                >
                  修改</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="block">
      <el-pagination
        v-if="pageShow"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.current"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="queryInfo.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <div class="block">
      <el-pagination
        v-if="pageShow1"
        @size-change="handleSizeChange1"
        @current-change="handleCurrentChange1"
        :current-page="queryInfo1.current"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="queryInfo1.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </el-card>
</template>
<script>
// 存储当前修改项的id, 防止详情页刷新时， 页面出现空屏
import { setbookid } from "../../utils/auth.js";
export default {
  data() {
    return {
      tableData: [], // 列表数据
      childrenData: [], //子书数据
      // 查询参数对象----分页
      queryInfo: {
        current: 1,
        size: 10,
        keyword: "",
      },

      queryInfo1: {
        current: 1,
        size: 10,
      },
      // 遮罩层
      loading: true,
      total: 0,

      multipleSelection: [], //勾选中的数据

      // 标签查询数据
      taglist: [], //查询出来的所有数据
      checkout: 1, //默认选中一级大类
      showTag: [], // 页面显示的数据
      oneTag: [], //一级标签大类
      secondTag: [], //二级标签大类
      TertiaryTag: [], //三级标签大类

      ChekTertiaryList: [], //三级选中项标签集合
      firstIds: [], //三级选中项标签集合 一级标签集合
      secondIds: [], //三级选中项标签集合 二级标签集合
      ids: [], //三级选中项标签集合 三级标签集合
      EchoTAg: [], // 回显选中的标签
      pageShow: false,
      pageShow1: false,
      bookTAgList: false,
      // end
    };
  },
  created() {
    this.keyupSubmit();
    this.getBookTags(); //获取标签集合
  },

  mounted() {
    let bookseach = sessionStorage.getItem("bookseach"); // 默认显示关键字查询，则使用跳转使用的插询 关键字查询1;标签查询2
    let queryInfo = JSON.parse(sessionStorage.getItem("booKlistQuery"));
    if (bookseach == 1) {
      if (JSON.parse(queryInfo)) {
        this.queryInfo = JSON.parse(queryInfo);
        this.getDataList();
      } else {
        this.getDataList();
      }
    } else {
      // let params = JSON.parse(JSON.parse(sessionStorage.getItem("getBookTAgParams"))); //点击根据标签查询
      let params = JSON.parse(sessionStorage.getItem("getBookTAgParams")); //点击根据标签查询
      let EchoTAg = JSON.parse(sessionStorage.getItem("EchoTAg")); //回显选中的标签
      if (params) {
        this.EchoTAg = EchoTAg; // 回显选中的标签
        this.ConfirmTag(params);
      } else {
        this.getDataList();
      }
    }
  },

  methods: {
    // 1.获取书籍数据
    async getDataList(e) {
      sessionStorage.setItem("bookseach", 1); //默认根据关键字全部查询
      if (e == 1) {
        this.queryInfo.current = 1;
      }
      console.log(this.queryInfo);
      const { data } = await this.$http.get(
        "getAllBookList/" +
          this.queryInfo.current +
          "/" +
          this.queryInfo.size +
          "?keyword=" +
          this.queryInfo.keyword
      );
      this.tableData = data.data.records;
      this.total = data.data.total;
      this.loading = false;
      // 2。是否售卖
      this.tableData.forEach((element) => {
        if (element.isShow == 1) {
          element.BookIsShow = true;
        } else {
          element.BookIsShow = false;
        }
      });
      // end
      this.$nextTick(() => {
        this.bookTAgList = true;
        this.pageShow1 = false;
        this.pageShow = true;
      });
      this.bookTAgList = true;
      this.pageShow1 = false;
      this.pageShow = true;
      this.remove(); //清空标签

      sessionStorage.removeItem("booKlistQuery");
    },

    remove() {
      let oneTag = []; //一级标签大类
      let secondTag = []; //二级标签大类
      let TertiaryTag = []; //三级标签大类
      for (let i = 0; i < this.taglist.length; i++) {
        const ItemTag = this.taglist[i];
        for (let i = 0; i < ItemTag.bookTagChildrenList.length; i++) {
          const element = ItemTag.bookTagChildrenList[i];
          element.checkout = false;
        }
        if (ItemTag.level == 1) {
          oneTag.push(ItemTag);
        } else if (ItemTag.level == 2) {
          secondTag.push(ItemTag);
        } else {
          TertiaryTag.push(ItemTag);
        }
      }
      this.firstIds.length = 0; //三级选中项标签集合 一级标签集合
      this.secondIds.length = 0; //三级选中项标签集合 二级标签集合
      this.ids.length = 0; //三级选中项标签集合 三级标签集合
      console.log(this.ChekTertiaryList);
      this.ChekTertiaryList = [];
      this.EchoTAg.length = 0; // 回显选中的标签
    },

    //监听回车事件
    keyupSubmit() {
      document.onkeydown = (e) => {
        let _key = window.event.keyCode;
        if (_key === 13) {
          this.getDataList(1);
        }
      };
    },

    //新增 获取标签集合
    async getBookTags() {
      const { data: data } = await this.$http.get("getAllBookTags");
      let records = data.data;
      this.taglist = records; //所有标签大类
      let oneTag = []; //一级标签大类
      let secondTag = []; //二级标签大类
      let TertiaryTag = []; //三级标签大类
      for (let i = 0; i < records.length; i++) {
        const ItemTag = records[i];
        for (let i = 0; i < ItemTag.bookTagChildrenList.length; i++) {
          const element = ItemTag.bookTagChildrenList[i];
          element.checkout = false;
        }
        if (ItemTag.level == 1) {
          oneTag.push(ItemTag);
        } else if (ItemTag.level == 2) {
          secondTag.push(ItemTag);
        } else {
          TertiaryTag.push(ItemTag);
        }
      }
      this.oneTag = oneTag; //一级标签大类
      this.secondTag = secondTag; //二级标签大类
      this.TertiaryTag = TertiaryTag; //三级标签大类
      this.showTag = oneTag; //页面首次加载时，优先显示一级大类下的标签数据
      this.getEchoTAg(this.EchoTAg);
    },

    // 新增 切换分类
    switchTag(value) {
      this.checkout = value;
      if (value == 1) {
        this.showTag = this.oneTag; //页面首次加载时，优先显示一级大类下的标签数据
      } else if (value == 2) {
        this.showTag = this.secondTag; //二级标签数据
      } else if (value == 3) {
        this.showTag = this.TertiaryTag; //三级标签数据
      }
    },

    // 新增 添加选中标签
    cheTAg(item, ParentItem) {
      item.checkout = !item.checkout;
      if (item.checkout) {
        this.ChekTertiaryList.push(item);
        if (ParentItem.level == 1) {
          this.firstIds.push(item.id);
        } else if (ParentItem.level == 2) {
          this.secondIds.push(item.id);
        } else if (ParentItem.level == 3) {
          this.ids.push(item.id);
        }
      } else {
        let index1 = this.firstIds.findIndex((v) => v == item.id);
        if (index1 == -1) {
          let index2 = this.secondIds.findIndex((v) => v == item.id);
          if (index2 == -1) {
            let index3 = this.ids.findIndex((v) => v == item.id);
            if ((index3 = -1)) {
              this.ids.splice(index3, 1);
              this.del_item_Tag(item);
            }
          } else {
            this.secondIds.splice(index2, 1);
            this.del_item_Tag(item);
          }
        } else {
          this.firstIds.splice(index1, 1);
          this.del_item_Tag(item);
        }
        let params1 = {};
        params1.current = this.queryInfo.current;
        params1.size = this.queryInfo.size;
        params1.firstIds = this.firstIds;
        params1.secondIds = this.secondIds;
        params1.ids = this.ids;
        sessionStorage.setItem("getBookTAgParams", JSON.stringify(params1)); //点击根据标签查询
      }
    },

    // 新增 添加选中标签 回调，移除页面上的显示内容
    del_item_Tag(item) {
      // 删除选中列表中 选中项
      for (let i = 0; i < this.ChekTertiaryList.length; i++) {
        const item_tag = this.ChekTertiaryList[i];
        if (item_tag.id == item.id) {
          this.ChekTertiaryList.splice(i, 1);
        }
      }

      for (let i = 0; i < this.taglist.length; i++) {
        const element = this.taglist[i];
        //赛选出对应的一级标签
        if (element.id == item.parentId) {
          for (let j = 0; j < element.bookTagChildrenList.length; j++) {
            const item_tag = element.bookTagChildrenList[j];
            if (item_tag.id == item.id) {
              item_tag.checkout = false;
            }
          }
        }
      }
    },

    // 新增 删除选中的标签，同时，清空一级 二级 三级中的标签id
    del_item_Tag2(item) {
      for (let i = 0; i < this.ChekTertiaryList.length; i++) {
        const item_tag = this.ChekTertiaryList[i];
        if (item_tag.id == item.id) {
          this.ChekTertiaryList.splice(i, 1);
        }
      }

      let index1 = this.firstIds.findIndex((v) => v == item.id);
      if (index1 == -1) {
        let index2 = this.secondIds.findIndex((v) => v == item.id);
        if (index2 == -1) {
          let index3 = this.ids.findIndex((v) => v == item.id);
          if ((index3 = -1)) {
            this.ids.splice(index3, 1);
            this.del_item_Tag(item);
          }
        } else {
          this.secondIds.splice(index2, 1);
          this.del_item_Tag(item);
        }
      } else {
        this.firstIds.splice(index1, 1);
        this.del_item_Tag(item);
      }
      let params1 = {};
      params1.current = this.queryInfo.current;
      params1.size = this.queryInfo.size;
      params1.firstIds = this.firstIds;
      params1.secondIds = this.secondIds;
      params1.ids = this.ids;
      sessionStorage.setItem("getBookTAgParams", JSON.stringify(params1)); //点击根据标签查询
    },

    // 新增 第二次加载，显示原先赛选出来的标签
    getEchoTAg(EchoTAg) {
      if (EchoTAg) {
        for (let j = 0; j < EchoTAg.length; j++) {
          const element = EchoTAg[j];
          for (let i = 0; i < this.taglist.length; i++) {
            const items = this.taglist[i];
            for (let s = 0; s < items.bookTagChildrenList.length; s++) {
              const value = items.bookTagChildrenList[s];
              if (element.id == value.id) {
                this.$nextTick(function () {
                  value.checkout = true;
                });
              }
            }
          }
        }
        this.ChekTertiaryList = EchoTAg;
        this.$nextTick(() => {
          this.bookTAgList = true;
        });
      }
    },

    // 新增根据标签查询
    async ConfirmTag(params = 1, current = 0) {
      sessionStorage.setItem("bookseach", 2); //点击根据标签查询
      if (params == 1) {
        let params1 = {};
        if (current == 1) {
          params1.current = current;
          this.queryInfo1.current = 1;
        } else {
          params1.current = this.queryInfo1.current;
        }
        params1.size = this.queryInfo1.size;
        params1.firstIds = this.firstIds;
        params1.secondIds = this.secondIds;
        params1.ids = this.ids;
        sessionStorage.setItem("getBookTAgParams", JSON.stringify(params1)); //点击根据标签查询
        sessionStorage.setItem(
          "EchoTAg",
          JSON.stringify(this.ChekTertiaryList)
        ); //点击根据标签查询
        let { data } = await this.$http.post("getBookListByTag", params1);
        // 2。是否售卖
        let list = data.data.list;
        list.forEach((element) => {
          if (element.isShow == 1) {
            element.BookIsShow = true;
          } else {
            element.BookIsShow = false;
          }
        });
        this.tableData = list;
        this.total = data.data.total;
        this.loading = false;
        this.queryInfo.keyword = "";
      } else {
        console.log(typeof(params));
        this.firstIds = params.firstIds;
        this.secondIds = params.secondIds;
        this.ids = params.ids;
        this.queryInfo1.current = params.current;
        this.queryInfo1.size = params.size;
        let { data } = await this.$http.post("getBookListByTag", params);
        let list = data.data.list;
        list.forEach((element) => {
          if (element.isShow == 1) {
            element.BookIsShow = true;
          } else {
            element.BookIsShow = false;
          }
        });
        this.tableData = list;
        this.total = data.data.total;
        this.loading = false;
        sessionStorage.removeItem("getBookTAgParams");
      }
      this.$nextTick(() => {
        this.pageShow = false;
        this.pageShow1 = true;
      });
    },

    // 展开子书
    handleToogleExpand(row) {
      let $table = this.$refs.table;
      this.childrenData = row.bookInfoChildrenList;
      this.tableData.map((item) => {
        if (row.id != item.id) {
          $table.toggleRowExpansion(item, false);
        }
      });
      $table.toggleRowExpansion(row);
    },

    // 点击修改进行页面跳转
    showloeding(item) {
      let params1 = {};
      params1.firstIds = this.firstIds;
      params1.secondIds = this.secondIds;
      params1.ids = this.ids;
      params1.size = this.queryInfo1.size;
      params1.current = this.queryInfo1.current;
      console.log(this.queryInfo);
      this.$router.push({
        path: "/bookdetails",
        query: {
          id: item.id,
          queryInfo:JSON.stringify(this.queryInfo) ,
          queryInfo1:JSON.stringify(params1) ,
        },
      });
      setbookid(item.id);
    },

    // 是否售卖
    async BookIsShowChang(item, index) {
      let isShow;
      if (item.BookIsShow) {
        isShow = 1;
      } else {
        isShow = 0;
      }
      let params = {};
      params.bookIds = [];
      params.bookIds[0] = item.id;
      params.isShow = isShow;
      const { data } = await this.$http.post("updateBookIsShow", params);
      if (data.ok) {
        this.$message.success(data.message);
        this.$set(this.tableData, index, item);
      } else {
        this.$message.error("书籍资料不全，未能打开");
        item.BookIsShow = false;
        this.$set(this.tableData, index, item);
      }
    },

    //保存选中结果
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    // 一键售卖
    async allshowList() {
      let all = this.multipleSelection.map((v) => v.id);
      let params = {};
      params.bookIds = all;
      params.isShow = 1;
      const { data } = await this.$http.post("updateBookIsShow", params);
      let bookseach = sessionStorage.getItem("bookseach");
      if (data.ok) {
        this.$message.success("操作成功");
        if (bookseach == 2) {
          let params1 = {}; //查询时，提换缓存中存储的标签数据，以免查询过后。清空缓存后，下架没有依赖的数据
          params1.current = this.queryInfo1.current;
          params1.size = this.queryInfo1.size;
          params1.firstIds = this.firstIds;
          params1.secondIds = this.secondIds;
          params1.ids = this.ids;
          this.ConfirmTag(params1);
          // sessionStorage.setItem("bookseach", 2); //点击根据标签查询
          // sessionStorage.setItem("getBookTAgParams", JSON.stringify(params1)); //点击根据标签查询
        } else {
          this.getDataList();
        }
      } else {
        this.$message.error("部分书籍资料不全,未能打开");
        if (bookseach == 2) {
          let params1 = {}; //查询时，提换缓存中存储的标签数据，以免查询过后。清空缓存后，下架没有依赖的数据
          params1.current = this.queryInfo1.current;
          params1.size = this.queryInfo1.size;
          params1.firstIds = this.firstIds;
          params1.secondIds = this.secondIds;
          params1.ids = this.ids;
          this.ConfirmTag(params1);
          // sessionStorage.setItem("bookseach", 2); //点击根据标签查询
          // sessionStorage.setItem("getBookTAgParams", JSON.stringify(params1)); //点击根据标签查询
        } else {
          this.getDataList();
        }
      }
    },

    // 一键下架
    async allhideList() {
      let all = this.multipleSelection.map((v) => v.id);
      let params = {};
      params.bookIds = all;
      params.isShow = 0;
      const { data } = await this.$http.post("updateBookIsShow", params);
      let bookseach = sessionStorage.getItem("bookseach");
      if (data.ok) {
        this.$message.success("操作成功");
        if (bookseach == 2) {
          let params1 = {}; //查询时，提换缓存中存储的标签数据，以免查询过后。清空缓存后，上架没有依赖的数据
          params1.current = this.queryInfo1.current;
          params1.size = this.queryInfo1.size;
          params1.firstIds = this.firstIds;
          params1.secondIds = this.secondIds;
          params1.ids = this.ids;
          this.ConfirmTag(params1);
          // sessionStorage.setItem("bookseach", 2); //点击根据标签查询
          // sessionStorage.setItem("getBookTAgParams", JSON.stringify(params1)); //点击根据标签查询
        } else {
          this.getDataList();
        }
      } else {
        this.$message.error("操作失败");
        if (bookseach == 2) {
          let params1 = {}; //查询时，提换缓存中存储的标签数据，以免查询过后。清空缓存后，上架没有依赖的数据
          params1.current = this.queryInfo1.current;
          params1.size = this.queryInfo1.size;
          params1.firstIds = this.firstIds;
          params1.secondIds = this.secondIds;
          params1.ids = this.ids;
          this.ConfirmTag(params1);
          // sessionStorage.setItem("bookseach", 2); //点击根据标签查询
          // sessionStorage.setItem("getBookTAgParams", JSON.stringify(params1)); //点击根据标签查询
        } else {
          this.getDataList();
        }
      }
    },

    // 监听时间变化
    itemChang() {
      this.Item = getdate(this.Item);
    },

    // 监听分页跳转
    handleSizeChange(val) {
      this.queryInfo.size = val;
      let bookseach = sessionStorage.getItem("bookseach"); // 默认显示关键字查询。但是如果从详情页返回，则使用跳转使用的插询
      if (bookseach == 1) {
        this.getDataList();
      }
    },

    handleCurrentChange(val) {
      this.queryInfo.current = val;
      let bookseach = sessionStorage.getItem("bookseach"); // 默认显示关键字查询。但是如果从详情页返回，则使用跳转使用的插询
      if (bookseach == 1) {
        sessionStorage.setItem("booKlistQuery", JSON.stringify(this.queryInfo)); //实时更新缓存中页码的数据
        this.getDataList();
      }
    },

    // 监听分页跳转
    handleSizeChange1(val) {
      this.queryInfo1.size = val;
      let bookseach = sessionStorage.getItem("bookseach"); // 默认显示关键字查询。但是如果从详情页返回，则使用跳转使用的插询
      if (bookseach != 1) {
        this.ConfirmTag(1);
      }
    },

    handleCurrentChange1(val) {
      this.queryInfo1.current = val;
      this.ConfirmTag(1);
    },
  },
};
</script>




<style lang="scss" scoped>
.booklid_wrap {
  width: 100%;

  display: flex;
  justify-content: space-between;
}

.demo-table-expand {
  font-size: 0;
}

.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}

::v-deep .el-table__expanded-cell {
  padding: 0 0 0 110px;
}
::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: #dd021b;
}

::v-deep
  .el-tabs--border-card
  > .el-tabs__header
  .el-tabs__item:not(.is-disabled):hover {
  color: #f34b5e;
}

.ipt_Submit {
  width: 200px;
}

.ipt_Submit ::v-deep input {
  height: 35px;
}

.btn_Submit {
  margin-left: 10px;
}

.ipt1 {
  margin-right: 10px;
}

// 标签
.laber_wrap {
  margin: 10px 0;
  border-bottom: 1px solid #ebeef5;
}
.laber_style {
  min-width: 100px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  cursor: default;
}
.laber_style:nth-child(2),
.laber_style:nth-child(3) {
  margin-left: 10px;
}

.checkoutREd {
  font-size: 14px;
  font-weight: 600;
  color: #dd021b;
}

.itemTag_within {
  display: flex;
  padding: 6px 11px;
  border-radius: 17px;
  border: 1px solid #dd021b;
  margin-right: 10px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #dd021b;
  line-height: 22px;
}

.delTag {
  width: 14px;
  height: 100%;
  text-align: right;
}

.one_tag_wrap {
  display: flex;
  .itemTag {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    margin-right: 28px;
    line-height: 20px;
    cursor: default;
  }

  .check_item_Tag {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #f50000;
    line-height: 20px;
  }
}

.Confirm_Wrap {
  margin: 20px 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .itemTag_wrap {
    display: flex;
    cursor: default;
  }
  .ConfirmTag {
    padding: 6px 12px;
    background: #ff1932;
    text-align: center;
    border-radius: 3px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>