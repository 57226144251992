import { set } from 'js-cookie';
import { getbookid, setQueryInfo, getQueryInfo } from '../../utils/auth.js'
export default {
  data() {
    return {
      // mask
      mask: false,
      // 上部内容区域
      formLabelAlign: {},
      labelPosition: 'right',

      // 1 模糊查询总数据
      restaurants: [],

      // 2 模糊查询出的总数据
      JsonsData: [],

      // 3 模糊查询选中值 || 新增值
      input_value: "",

      // 4 当前弹出层选中值
      isactive: -1,

      //  4.1 模糊查询总数据 弹出层 标签栏 选中项 cehcked
      check: false,

      // 5 选中的标签
      labels: [],

      // 6 显示 隐藏 历史记录标签框
      visible: false,



      // dialogFormVisible 弹出层  添加子书
      dialogFormVisible: false,



      // id 上一页传递来的 书籍 id
      id: 0,

      // ***************************************************************************************
      // 级联选择器
      options: [

      ],
      value: [],

      // 品相
      num: {
        brandNew: 3.5, //全新
        ordinary: 2.5, //普通
        general: 1.5, //一般
        buyOut: 6.0 //买断
      },

      discountNum: {
        brandNewPrice: 0, //全新 折扣金额
        ordinaryPrice: 0, // 普通
        generalPrice: 0, //一般
        buyOutPrice: 0, //买断
      },

      // *************************************************************************************
      // 中间表单区域
      ruleForm: {},
      // 中间部分的表单数据验证
      rules: {
        title: [
          { required: true, message: '请输入书籍名称', trigger: 'blur' },
        ],
        author: [
          { required: true, message: '请输入作者名称', trigger: 'blur' },
        ],

        pubdate: [
          { required: true, message: '请输入时间', trigger: 'blur' },
        ],

        price: [
          { required: true, message: '请输入定价', trigger: 'blur' },
        ],
        publisher: [
          { required: true, message: '请输入出版社', trigger: 'blur' },
        ],
        binding: [
          { required: true, message: '请输入装帧方式', trigger: 'blur' },
        ],
        page: [
          { required: true, message: '请输入页数', trigger: 'blur' },
        ],

      },

      // ***********************************************************************
      // 1 监听页面宽度的变化 实时更新页面宽度 
      screenWidth: document.body.clientWidth,
      // 2 标签栏的width
      label_w: "",
      wh_width: false,
      // 3 变量 通过v-if 使页面及时更新
      // **************************************************************************
      tableData: [],

      // 弹出框对象
      formData: {},

      // **************************** 上一级 传递来 的页码数 
      queryInfo: {},

      // 新增 或者 修改子书 时文字描述
      deleteRowL_Text: "添加子书",

      // *****************上传图片
      files: {},
      photo: '',
      photoObj: '',

    };
  },
  async created() {
    let id = this.$route.params.id;
    this.id = id
    let queryInfo = this.$route.params.queryInfo;
    this.queryInfo = queryInfo

    let scrolltp = document.getElementsByClassName("el-main")[0]
    if (scrolltp) {
      scrolltp.scrollTop = 0
    }

    if (id == undefined) {
      id = getbookid()
      this.id = id
    }
    this.getbooklist(id)



  },
  mounted() {
    // 1.3 页面加载完成调用数据 获取 标签
    this.loadAll()

    // 1.3.1 页面加载完成调用数据 获取 分类
    this.getCategoryList()

    // 屏幕跨度的变化
    this.screenWidth = document.body.clientWidth;
    if (this.screenWidth < 1200) {
      this.label_w = "435px"
      this.JsonsData = this.JsonsData

    } else {
      this.label_w = "800px"
      this.JsonsData = this.JsonsData
    }
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
  },


  // 监听
  watch: {
    input_value: {
      handler(newValue, oldValue) {
        // console.log(oldValue); //更改前
        // 1.4重新置回-1
        this.isactive = -1;
        // 1.1 console.log(newValue); //更改后  
        if (newValue) {
          this.search(newValue)
        } else {
          this.JsonsData = this.restaurants;
        }

      },

    },

    screenWidth: {
      immediate: true,
      handler(newValue) {
        if (newValue < 1200) {
          this.label_w = "435px"
          this.wh_width = true
          this.JsonsData = this.JsonsData
        } else {
          this.label_w = "800px"
          this.wh_width = false
          this.JsonsData = this.JsonsData
        }
      },
    },


  },


  // 方法
  methods: {
    handlePreview(file) {
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    uploadSectionFile(file, up) {
      const typeArr = ["image/png", "image/gif", "image/jpeg", "image/jpg"];
      const isJPG = typeArr.indexOf(file.raw.type) !== -1;
      const isLt3M = file.size / 1024 / 1024 < 3;
      if (!isJPG) {
        this.$message.error("只能是图片!");
        // this.$refs.upload.clearFiles();
        this.files = null;
        return;
      }
      if (!isLt3M) {
        this.$message.error("上传图片大小不能超过 3MB!");
        // this.$refs.upload.clearFiles();
        this.files = null;
        return;
      }
      this.files = file.raw;
      // FormData 对象
      var formData1 = new FormData();
      // 文件对象
      formData1.append("file", this.files);
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        methods: "post",
      };
      // console.log(formData1);
      this.$http.post("uploadFile", formData1, config).then((res) => {
        if (res.data.ok) {
          this.formData.img = res.data.data;

        } else {
          this.$message.error(" 上传图片失败");
        }
      });
    },
    handleAvatarSuccess(res, file) {
      // this.myData= file;
      this.$refs.upload.clearFiles(); //上传成功之后清除历史记录
      this.handleInitTableData();  //初始化表格数据
    },

    uploadSectionFile2(file) {
      const typeArr = ["image/png", "image/gif", "image/jpeg", "image/jpg"];
      const isJPG = typeArr.indexOf(file.raw.type) !== -1;
      const isLt3M = file.size / 1024 / 1024 < 3;
      if (!isJPG) {
        this.$message.error("只能是图片!");
        // this.$refs.upload.clearFiles();
        this.files = null;
        return;
      }
      if (!isLt3M) {
        this.$message.error("上传图片大小不能超过 3MB!");
        // this.$refs.upload.clearFiles();
        this.files = null;
        return;
      }
      this.files = file.raw;
      // FormData 对象
      var formData2 = new FormData();
      // 文件对象
      formData2.append("file", this.files);
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        methods: "post",
      };

      this.$http.post("uploadFile", formData2, config).then((res) => {
        if (res.data.ok) {
          this.formLabelAlign.img = res.data.data;
        } else {
          this.$message.error(" 上传图片失败");
        }
      });
    },




    // *********************************************
    // 获取书籍信息
    async getbooklist(id) {
      const { data } = await this.$http.get('getBookDetailsById/' + id)
      // console.log(data);
      this.formLabelAlign = data.data;
      this.num = data.data.bookDiscountVO //品相
      if (this.formLabelAlign.page == 0) {
        this.formLabelAlign.page = ""
      }
      if (data.data.categoryIds) {
        this.value = JSON.parse(data.data.categoryIds)
        this.updatalist()
      }
      this.labels = data.data.bookTagList
      // replace
      let pubdate = ""
      if (this.formLabelAlign.pubdate == null || this.formLabelAlign.pubdate == "") {
        pubdate = ""
      } else {
        pubdate = this.formLabelAlign.pubdate.replace("-", ".")
      }
      this.formLabelAlign.pubdate = pubdate
      this.tableData = this.formLabelAlign.bookInfoChildrenList

      // 调用品相相关金额折扣值计算
      this.originalPrice(this.formLabelAlign.price)
    },

    // 监听日期变化
    riqdata(e) {
      var str = e;
      var parent1 = /^\d{4}-\d{1,2}/;
      var parent2 = /^\d{4}\.\d{1,2}/;
      var parent3 = /^\d{4}-\d{1,2}-\d{1,2}/;
      let regular1 = parent1.test(str)
      let regular2 = parent2.test(str)
      let regular3 = parent3.test(str)
      let lefy = {}

      if (regular1 || regular2 || regular3) {
        lefy.str = str;
        lefy.toglt = true;
        return lefy
      } else {
        lefy.str = e;
        lefy.toglt = false;
        return lefy
      }
    },



    //1.2 模糊查询
    search(newValue) {
      // 支持模糊查询
      // this.xmgcqkJsonsData：用于搜索的总数据
      //　toLowerCase():用于把字符串转为小写，让模糊查询更加清晰
      let _search = newValue.toLowerCase();
      let newListData = []; // 用于存放搜索出来数据的新数组
      if (_search) {
        this.restaurants.filter(item => {
          if (item.name.toLowerCase().indexOf(_search) !== -1) {
            newListData.push(item);
          }
        })
      }
      if (newListData.length != 0) {
        this.JsonsData = newListData
      } else {
        // let upItem = [{ "id": "1", "name": "新增", "address": "updata" }]
        this.JsonsData = []
      }

    },

    //1.3 筛选标签选中项  ********** labels 为选中标签栏 数组 == 集合
    async screen(i, item) {
      item.check = !item.check
      let item_key = item
      this.$set(this.JsonsData, i, item_key)
      // if (item.address == "updata") {
      //   // 该标签只是判断是否是新增 标签
      //   let prams = {}
      //   prams.name = this.input_value
      //   const { data: res } = await this.$http.post("addTag", prams)
      //   if (res.ok) {
      //     prams.id = res.data
      //     //选中的标签项 
      //     this.labels.push(prams)
      //     // 筛选出的标签项
      //     this.JsonsData.push(prams)
      //     this.$message.success("新增标签成功")
      //   } else {
      //     this.$message.error("新增标签失败");
      //   }

      // } else {
      // **************判断当前标签是否存在; 这些都已经存在库中 ******* 废弃
      // let index = this.labels.indexOf(item)
      // // ****************存在时提示用户已存在该标签
      // if (index > -1) {
      //   // return this.$message.success("该标签已经存在,请从新添加")
      //   // *****************不存在时，添加到标签栏一个 标签
      // } else {
      //   return this.labels.push(item)
      // }  
      // **************************  end
      // 光标重新回到inupt框内
      setTimeout(() => {
        this.$refs["getFocus"].focus();
      }, 200);


      let index_item = this.labels.findIndex(v => {
        return v.id == item_key.id
      })
      if (item.check) {
        // console.log(item);
        return this.labels.push(item)
      } else {
        return this.labels.splice(index_item, 1)
      }
    },


    // 3.0编辑丛书
    deleteRow(index, item) {
      this.deleteRowL_Text = "保存"
      const formData = JSON.parse(JSON.stringify(item))
      this.formData = formData;
      if (this.formData.page == 0) {
        this.formData.page = ""
      }
      this.dialogFormVisible = true
    },



    // 根据id删除子书
    romoveRow(index, item) {
      this.$confirm('是否删除子书?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { data: res } = await this.$http.get("deleteBookDetails/" + item.id)

        if (res.code == "-1") {
          this.$message.error("删除书籍失败")
        } else {
          this.$message.success("删除书籍成功")
        }



        this.getbooklist(this.id)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },



    // 4.0 添加子书弹出层
    addChildren() {
      this.deleteRowL_Text = "添加子册"
      this.formData = this.formLabelAlign
      if (this.formData.page == 0) {
        this.formData.page = ""
      }
      this.dialogFormVisible = true
    },



    // 新增/修改子书  @**@**** 此处需要处理img问题 *******
    async updataChildrenList() {
      if (this.formData.title == null || this.formData.title == "" || this.formData.title == undefined)
        return this.$message.error("请输入书名")
      // if (this.formData.bookAlias == null || this.formData.bookAlias == "" || this.formData.bookAlias == undefined)
      //   return this.$message.error("请输入书籍别名")

      if (this.formData.author == null || this.formData.author == "" || this.formData.author == undefined)
        return this.$message.error("请输入作者")

      let suces; let eror;
      // 判断是否包含 携带子书字段 bookInfoChildrenList
      let bookInfoChildrenList = this.formData.hasOwnProperty("bookInfoChildrenList")

      if (bookInfoChildrenList) {
        // 新增子书
        let form = {}
        form = this.item(form)
        form.parentId = this.formData.id

        let rulesTime = this.riqdata(this.formData.pubdate);
        if (rulesTime.toglt == true) {
          this.formData.pubdate = rulesTime.str
          form.isChildren = 1; // ******** 添加丛书标记 1为丛书   0为单书
          const { data: res } = await this.$http.post("saveChildrenBook", form)
          let suces = "添加成功"
          let eror = "添加失败"
          this.message(res, suces, eror)

        } else {
          this.formData.pubdate = rulesTime.str
          return this.$message.error("日期输入错误");
        }

      } else {
        // 修改子书
        let rulesTime = this.riqdata(this.formData.pubdate);
        if (rulesTime.toglt == true) {
          let form = {}
          form = this.item(form)
          form.id = this.formData.id
          this.formData.pubdate = rulesTime.str
          form.isChildren = 1; // ******** 添加丛书标记 1为丛书   0为单书
          const { data: res } = await this.$http.post("updateChildrenBook", form)
          suces = "修改成功"
          eror = "修改失败"
          this.message(res, suces, eror)
        } else {
          this.formData.pubdate = rulesTime.str
          return this.$message.error("日期输入错误");
        }
      }
    },



    //键盘按回车 新增一个标签
    async upladel() {
      let prams = {}
      prams.name = this.input_value
      let index = this.restaurants.findIndex(v => {
        return v.name == this.input_value
      });
      // 输入框为空时，提示语句
      if (prams.name == "") return this.$message.error("请输入标签名")
      if (index > -1) {
        this.restaurants[index].check = true;
        let LES_key = this.labels.findIndex(v => {
          return v.name == this.input_value
        });
        // if (LES_key > -1) return this.$message.error("该标签已至标签栏，请勿重复添加")
        if (LES_key > -1) return this.$message.error("该标签已添加")
        let item = this.restaurants[index]
        this.labels.push(item)
        this.$set(this.restaurants, index, item)
      } else {
        // 新增一个标签
        const { data: res } = await this.$http.post("addTag", prams)
        if (res.ok) {
          prams.id = res.data
          prams.check = true
          //选中的标签项 
          this.labels.push(prams)
          // 从新拉取 所有 标签
          // console.log(res);
          this.loadAll()
          // 置空inoput框
          this.input_value = ""
          // this.updatalist()
          // 筛选出的标签项
          this.$message.success("新增标签成功")
        } else {
          this.$message.error("新增标签失败");
        }
      }

    },



    // 1.3.1 页面加载获取所有书籍分类
    async getCategoryList() {
      const { data: res } = await this.$http.get("getCategoryList")
      let opt = res.data
      for (let i = 0; i < res.data.length; i++) {
        const element = res.data[i];
        opt[i].children = element.categoryChildrenList
        delete opt[i].categoryChildrenList;
      }

      this.options = opt

    },

    // 1.4清除历史记录
    dellist() {
      this.JsonsData = [];
      this.restaurants = [];
    },

    // 1.5 删除单条已添加标签
    delitem(i, item) {
      let index = this.restaurants.findIndex(v => {
        return v.id == item.id
      });
      // 如果点击了清空数据，那么将拿不到 this.restaurants 总数据  dess 将为 undefinde
      let dess = this.restaurants[index]
      if (dess) {
        dess.check = false
      }
      this.labels.splice(i, 1)
      this.$set(this.restaurants, index, dess)
    },



    // 获取所有的标签
    async loadAll() {
      const { data: res } = await this.$http.get("getBookTagList")
      // 标签栏的赋值
      this.restaurants = res.data;
      this.JsonsData = res.data;
      this.restaurants.forEach(v => {
        v.check = false
      });
      this.updatalist()
      // console.log(this.restaurants);
    },


    labelShow() {
      this.input_value = ""
      this.visible = !this.visible;
      this.mask = true;
      if (this.visible == true) {
        setTimeout(() => {
          this.$refs["getFocus"].focus();
        }, 200);
      } else {
        setTimeout(() => {
          this.$refs["getFocus"].blur();
        }, 200);
      }
    },


    // 遮罩层
    delMask() {
      this.visible = false;
      this.mask = false;
    },

    clickOverlay(e) {

      let tree = this.$refs.contentWrapper.contains(e.target) // 这最关键的代码,判断是否点击了内部区域
      if (!tree) {
        this.$emit('close')
      }
    },

    handleChange(value) {
      this.value = value
    },


    // 提交表单
    async SubmitForm() {
      let num = this.num;

      if (
        this.formLabelAlign.title == null ||
        this.formLabelAlign.title == "" ||
        this.formLabelAlign.title == undefined
      )
        return this.$message.error("请输入书名")
      if (this.formLabelAlign.author == "" || this.formLabelAlign.author == null || this.formLabelAlign.author == undefined)

        return this.$message.error("请输入作者")
      // 页面加载时，获取的总数据, 这地方需要 处理 标签 。分类选中项
      // 选中的分类最后一级项id
      let categoryIds = this.value
      let categoryId = this.value[this.value.length - 1]
      let labels = this.labels
      // 删除冗余字段
      for (let i = 0; i < labels.length; i++) {
        const item = labels[i];
        item.bookId = this.formLabelAlign.id
        if (!item.id) {
          item.categoryTagId = item.categoryTagId
        } else {
          item.categoryTagId = item.id;
        }

        if (!item.name) {
          item.categoryTagName = item.categoryTagName
        } else {
          item.categoryTagName = item.name;
        }
        delete item.id;
        delete item.name;
        delete item.check;
        delete item.createTime;
        delete item.createUser;
        delete item.modifiedTime;
        delete item.updateUser
      }
      this.formLabelAlign.categoryTagList = labels;
      // 将分类集合转为字符串
      if (categoryIds.length != 0) {
        this.formLabelAlign.categoryIds = JSON.stringify(categoryIds)
        this.formLabelAlign.categoryId = categoryId;
      }


      //如果传空，则修改为0 。
      if (this.formLabelAlign.page == "") {
        this.formLabelAlign.page = 0
      }

      // 验证日期是否正确
      let rulesTime = this.riqdata(this.formLabelAlign.pubdate);

      // 品相判断
      this.formLabelAlign.bookDiscountDTO = this.formLabelAlign.bookDiscountVO
      if (num.brandNew - 0 >= 10 || num.brandNew - 0 <= 0) return this.$message.error("折扣数值不正确，保存失败")
      if (num.buyOut - 0 >= 10 || num.buyOut - 0 <= 0) return this.$message.error("折扣数值不正确，保存失败")
      if (num.general - 0 >= 10 || num.general - 0 <= 0) return this.$message.error("折扣数值不正确，保存失败")
      if (num.ordinary - 0 >= 10 || num.ordinary - 0 <= 0) return this.$message.error("折扣数值不正确，保存失败")

      this.formLabelAlign.bookDiscountDTO.brandNew = num.brandNew + ""
      this.formLabelAlign.bookDiscountDTO.brandNewPrice = this.discountNum.brandNewPrice
      this.formLabelAlign.bookDiscountDTO.buyOut = num.buyOut + ""
      this.formLabelAlign.bookDiscountDTO.buyOutPrice = this.discountNum.buyOutPrice
      this.formLabelAlign.bookDiscountDTO.general = num.general + ""
      this.formLabelAlign.bookDiscountDTO.generalPrice = this.discountNum.generalPrice
      this.formLabelAlign.bookDiscountDTO.ordinary = num.ordinary + ""
      this.formLabelAlign.bookDiscountDTO.ordinaryPrice = this.discountNum.ordinaryPrice
      if (this.formLabelAlign.price <= 0) return this.$message.error("定价金额不正确，保存失败")

      if (rulesTime.toglt == true) {
        this.formLabelAlign.pubdate = rulesTime.str
        const { data: res } = await this.$http.post("editBookInfo", this.formLabelAlign)
        if (res.ok) {
          this.$message.success("修改书籍信息成功")
        }
        this.getbooklist(this.id)
      } else {
        this.formLabelAlign.pubdate = rulesTime.str
        return this.$message.error("日期输入错误");
      }
    },

    // 精确到小数点后两位
    // 全新 良品 普通 买断 折扣
    handleInput1(num) {
      let brand = this.px(num)
      let originalPrice = this.formLabelAlign.price
      if (num == '') {
        this.formLabelAlign.brandNew = 0
        this.discountNum.brandNewPrice = (originalPrice * Number(this.num.brandNew) / 10).toFixed(2)//全新 折扣金额
      } else {
        this.num.brandNew = brand
        this.discountNum.brandNewPrice = (originalPrice * Number(this.num.brandNew) / 10).toFixed(2) //全新 折扣金额
      }
    },
    handleInput2(num) {
      let brand = this.px(num)
      let originalPrice = this.formLabelAlign.price
      if (num == '') {
        this.num.ordinary = 0
        this.discountNum.ordinaryPrice = (originalPrice * Number(this.num.ordinary) / 10).toFixed(2) //良好 折扣金额
      } else {
        this.num.ordinary = brand
        this.discountNum.ordinaryPrice = (originalPrice * Number(this.num.ordinary) / 10).toFixed(2) //良好 折扣金额
      }
    },
    handleInput3(num) {
      let brand = this.px(num)
      let originalPrice = this.formLabelAlign.price
      if (num == '') {
        this.num.general = 0
        this.discountNum.generalPrice = (originalPrice * Number(this.num.general) / 10).toFixed(2) //普通 折扣金额
      } else {
        this.num.general = brand
        this.discountNum.generalPrice = (originalPrice * Number(this.num.general) / 10).toFixed(2) //普通 折扣金额
      }
    },
    handleInput4(num) {
      let brand = this.px(num)
      let originalPrice = this.formLabelAlign.price
      if (num == '') {
        this.num.buyOut = 0
        this.discountNum.buyOutPrice = (originalPrice * Number(this.num.buyOut) / 10).toFixed(2) //普通 折扣金额
      } else {
        this.num.buyOut = brand
        this.discountNum.buyOutPrice = (originalPrice * Number(this.num.buyOut) / 10).toFixed(2) //普通 折扣金额
      }
    },

    // 全新 良品 普通 买断 折扣金额
    discountPrice1(num) {
      let originalPrice = this.formLabelAlign.price
      let brandNum = this.pxPrice(num)
      this.discountNum.brandNewPrice = brandNum;
      if (Number(brandNum) >= originalPrice) {
        this.$message.error("折扣金额不能超过原价");
        this.discountNum.brandNewPrice = 0
        return;
      }
      else {
        let brand = Number(brandNum) / Number(originalPrice) * 10
        brand = brand.toFixed(2)
        if (brand == 0) {
          this.num.brandNew = 0
        } else {
          this.num.brandNew = brand
        }
      }
    },
    discountPrice2(num) {
      let originalPrice = this.formLabelAlign.price
      let brandNum = this.pxPrice(num)
      this.discountNum.ordinaryPrice = brandNum;
      if (Number(brandNum) >= originalPrice) {
        this.$message.error("折扣金额不能超过原价");
        this.discountNum.ordinaryPrice = 0
        return;
      }
      else {
        let brand = Number(brandNum) / Number(originalPrice) * 10
        brand = brand.toFixed(2)
        if (brand == 0) {
          this.num.ordinary = 0
        } else {
          this.num.ordinary = brand
        }
      }
    },

    discountPrice3(num) {
      let originalPrice = this.formLabelAlign.price
      let brandNum = this.pxPrice(num)
      this.discountNum.generalPrice = brandNum
      if (Number(brandNum) >= originalPrice) {
        this.$message.error("折扣金额不能超过原价");
        this.discountNum.generalPrice = 0
        return;
      }
      else {
        let brand = Number(brandNum) / Number(originalPrice) * 10
        brand = brand.toFixed(2)
        if (brand == 0) {
          this.num.general = 0
        } else {
          this.num.general = brand
        }
      }
    },

    discountPrice4(num) {
      let brandNum = this.pxPrice(num)
      this.discountNum.buyOutPrice = brandNum
      let originalPrice = this.formLabelAlign.price
      if (Number(brandNum) >= originalPrice) {
        this.$message.error("折扣金额不能超过原价");
        this.discountNum.buyOutPrice = 0
        return;
      }
      else {
        let brand = Number(brandNum) / Number(originalPrice) * 10
        brand = brand.toFixed(2)
        if (brand == 0) {
          this.num.buyOut = 0
        } else {
          this.num.buyOut = brand
        }
      }
    },


    // 重置品相
    reset() {
      this.num.brandNew = 3.5, //全新
        this.num.ordinary = 2.5, //普通
        this.num.general = 1.5, //一般
        this.num.buyOut = 6.0 //买断
      this.handleInput1(this.num.brandNew)
      this.handleInput2(this.num.ordinary)
      this.handleInput3(this.num.general)
      this.handleInput4(this.num.buyOut)
    },

    //原价。改变原价 
    originalPrice(num) {
      let originalPrice = this.pxPrice(num)
      this.formLabelAlign.price = originalPrice;
      this.discountNum.brandNewPrice = (originalPrice * Number(this.num.brandNew) / 10).toFixed(2) //全新 折扣金额
      this.discountNum.ordinaryPrice = (originalPrice * Number(this.num.ordinary) / 10).toFixed(2) //良品
      this.discountNum.generalPrice = (originalPrice * Number(this.num.general) / 10).toFixed(2) //普通
      this.discountNum.buyOutPrice = (originalPrice * Number(this.num.buyOut) / 10).toFixed(2) //买断
    },






    // ******************************************************* 共用的代码段  ****************** 
    updatalist() {
      setTimeout(() => {
        // ***********废弃 该段代码只能筛选出单选
        // let index = this.restaurants.findIndex(v => {
        //  return v.id == prams.id
        // });
        // let item =this.restaurants[index]
        // item.check = true
        // this.$set(this.restaurants, index, item)
        // *****************end
        for (var a = 0; a < this.labels.length; a++) {
          for (var i = 0; i < this.restaurants.length; i++) {
            if (this.labels[a].id == this.restaurants[i].id) {
              let item = this.restaurants[i]
              item.check = true
              this.$set(this.restaurants, i, item)
            }
          }
        }
      }, 100);
    },


    // 赋值数据，通用了
    item(form) {
      form.author = this.formData.author
      form.cip = this.formData.cip
      form.binding = this.formData.binding
      form.img = this.formData.img
      form.modifiedTime = this.formData.modifiedTime
      form.format = this.formData.format
      form.bookAlias = this.formData.bookAlias
      form.page = this.formData.page - 0
      form.price = this.formData.price
      form.publisher = this.formData.publisher
      form.title = this.formData.title
      form.gist = this.formData.gist
      form.classify = this.formData.classify  //需要新增一个中图法分类
      form.pubdate = this.formData.pubdate  //需要新增一个出版时间
      return form;
    },

    message(res, suces, eror) {
      if (res.ok) {
        this.getbooklist(this.id)
        this.$message.success(suces)
        this.dialogFormVisible = false
      } else {
        this.$message.success(eror)
      }
    },

    // 品相
    px(num) {
      if (0 <= num && num <= 10) {
        num = JSON.stringify(num)
        num = num.replace(/[^\d.]/g, "");  //清除“数字”和“.”以外的字符  
        num = num.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的  
        num = num.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
        num = num.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');//只能输入两个小数  
        if (num.indexOf(".") < 0 && num != "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额 
          num = parseFloat(num);
        }
        return num
      } else {
        return 0
      }

    },

    // 品相折扣金额
    pxPrice(num) {
      num = JSON.stringify(num)
      num = num.replace(/[^\d.]/g, "");  //清除“数字”和“.”以外的字符  
      num = num.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的  
      num = num.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      num = num.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');//只能输入两个小数  
      if (num.indexOf(".") < 0 && num != "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额 
        num = parseFloat(num);
      }
      return num
    }
  },
};