<template>
  <div>
    <el-card style="padding: 25px 50px 21px">
      <div class="img_top">编辑模块</div>
      <el-row :gutter="20">
        <el-col :span="16">
          <el-col :span="8">
            <div v-if="value == 3">
              <img :src="options[0].moduleImage" alt="" style="width: 70%" />
            </div>
            <div v-if="value == 4">
              <img :src="options[1].moduleImage" alt="" style="width: 70%" />
            </div>
            <div v-if="value == 5">
              <img :src="options[2].moduleImage" alt="" style="width: 70%" />
            </div>

            <div class="mg_top">
              <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.moduleType"
                  :label="item.moduleTitle"
                  :value="item.moduleType"
                >
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="12">
            <el-form ref="form" :rules="rules" :model="form" label-width="80px">
              <el-form-item label="模块名称" prop="moduleTitle">
                <el-input v-model="form.moduleTitle"></el-input>
              </el-form-item>
              <el-form-item label="序号" prop="moduleSort">
                <el-input v-model.number="form.moduleSort"></el-input>
              </el-form-item>
              <el-form-item label="是否启用" v-if="items.Editstatus == 1">
                <el-switch
                  v-model="isShow"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  @change="isSHOW"
                >
                </el-switch>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  class="btn_Submit"
                  style="background: #ff1932"
                  @click="onSubmit('form')"
                  :loading="loadingBut"
                  >{{ loadingButtext }}</el-button
                >
              </el-form-item>
            </el-form>
          </el-col>
        </el-col>
      </el-row>
    </el-card>

    <el-card style="margin-top: 20px" v-if="items.Editstatus == 1">
      <el-row>
        <el-col :span="24" class="edit_btn_Wrap">
          <div class="EB_Style">模块内书籍</div>
          <div class="upbook" @click="addBook">添加书籍</div>
        </el-col>
      </el-row>
      <template class="table_top">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column label="书籍封面">
            <template slot-scope="scope">
              <img :src="scope.row.bookImage" style="width: 80px" alt="" />
            </template>
          </el-table-column>
          <el-table-column prop="bookName" label="书籍名称"> </el-table-column>
          <el-table-column prop="bookSort" label="序号"> </el-table-column>
          <el-table-column prop="address" label="操作">
            <template slot-scope="scope">
              <el-button
                @click="handleClick(scope.row)"
                type="text"
                size="small"
                style="color: #0bd46a"
                >编辑</el-button
              >
              <el-button
                v-if="Disable == 0"
                @click.native.prevent="deleteRow(scope.row)"
                type="text"
                size="small"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </el-card>

    <!-- 右侧弹出层 -->
    <el-drawer
      :with-header="false"
      :visible.sync="table"
      direction="rtl"
      size="50%"
      @close="hideDrawer"
    >
      <el-card>
        <el-col :span="24">
          <div class="wrap_cx">
            <div>
              <el-input
                placeholder="请输入内容"
                v-model="input3"
                class="input-with-select"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="queryBook"
                ></el-button>
              </el-input>
            </div>
            <el-row class="addupbook">
              <div class="cancel" style="margin-right: 10px" @click="cancal">
                取消
              </div>
              <div class="upbook" @click="upBook">添加</div>
            </el-row>
          </div>
        </el-col>
      </el-card>
      <el-card>
        <el-table
          :data="gridData"
          :show-header="false"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          @row-click="handleRowClick"
          ref="multipleTable"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column property="title" label="姓名"></el-table-column>
          <el-table-column property="isbn" label="isbn"></el-table-column>
          <el-table-column property="bookAlias" label="别名"></el-table-column>
        </el-table>
      </el-card>
    </el-drawer>

    <!-- 编辑排序 -->
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <el-form
        :model="numbersolt"
        ref="numberValidateForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item
          label="序号"
          prop="bookSort"
          :rules="[
            { required: true, message: '序号不能为空' },
            { type: 'number', message: '序号必须为数字值' },
          ]"
        >
          <el-input
            type="bookSort"
            v-model.number="numbersolt.bookSort"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('numberValidateForm')"
            >提交</el-button
          >
          <el-button @click="resetForm('numberValidateForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ["status", "item"],
  data() {
    var BannerModuleDetails = this.$store.state.BannerModuleDetails;
    return {
      form: {
        moduleTitle: "",
        moduleSort: 1,
      },

      isShow: false, //是否启用
      loadingBut: false,
      loadingButtext: "保存",

      table: false, //抽屉
      dialogVisible: false, //排序弹出层

      // 数组数据
      tableData: [],
      rules: {
        moduleTitle: [
          { required: true, message: "请输入模块名称", trigger: "blur" },
        ],
        moduleSort: [
          { required: true, message: "序号不能为空" },
          { type: "number", message: "序号必须为数字值" },
        ],
      },

      // 遮罩层显示数组数据
      gridData: [],

      // 选中项
      options: [
        {
          moduleType: "3",
          moduleTitle: "书籍模块一",
        },
        {
          moduleType: "4",
          moduleTitle: "书籍模块二",
        },
        {
          moduleType: "5",
          moduleTitle: "书籍模块三",
        },
      ],
      value: this.status, //选中项

      items: this.item, //父级页面传递来的值

      // 排序
      numbersolt: {
        bookSort: 0,
      },

      input3: "",

      multipleSelection: [], //选中书籍
      BookListID: 0, //查询书籍所需模块id

      BannerModuleDetails: BannerModuleDetails, // vuex数据
      Disable: 0,
      SerialNumber: 0, //存储下来的序号。重置时，使用
    };
  },

  mounted() {
    if (this.items.Editstatus == 1) {
      this.form = this.items;
      this.items.moduleSort = Number(this.items.moduleSort) || 1;
      this.getBookModuleByBookList(this.items.id);
    } else {
      let items = JSON.parse(JSON.stringify(this.items));
      items.moduleSort = 1;
      this.form = items;
      this.form.moduleTitle = "";
    }

    this.getModuleList();
    this.keyupSubmit(); //监听回车
  },

  methods: {
    // 获取书单中的书籍 为编辑时。获取
    async getBookModuleByBookList(id) {
      const { data } = await this.$http.get("getBannerModuleDetails/" + id);
      let bookModuleList = data.data.bookModuleList;
      this.form.moduleTitle = data.data.moduleTitle;
      this.form.moduleSort = data.data.moduleSort;
      this.BookListID = data.data.id;
      this.form.isShow = data.data.isShow;
      if (data.data.isShow == 0) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
      if (bookModuleList.length == 1) {
        this.Disable = 1; //剩余一本时，不能删除
      } else {
        this.Disable = 0;
      }
      this.tableData = bookModuleList;
    },

    // 获取模块信息
    async getModuleList() {
      const { data } = await this.$http.get("getModuleList/1");
      this.options = data.data;
    },

    // 关闭排序弹出层
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },

    // 2.0-1重置
    resetForm(formName) {
        this.numbersolt.bookSort = this.SerialNumber;
        this._watcher.run()
    },

    //3.0 添加书籍
    addBook() {
      this.table = true;
    },

    //3.0-1 添加书籍  --  右侧弹出层书籍
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    // 3.0-2 //关闭书籍
    hideDrawer() {
      this.input3 = "";
      this.gridData = [];
      this.multipleSelection = [];
    },

    // 3.0-1 模糊查询 获取到书籍
    async queryBook() {
      const { data } = await this.$http.get(
        "getBannerModuleBook/" + this.input3 + "/" + this.BookListID + "/" + "1" //0 书单 1模块
      );
      this.gridData = data.data;
    },

    cancal() {
      this.table = false;
    },

    // 3.0-2 //添加书籍
    async upBook() {
      let multipleSelection = this.multipleSelection;
      let params = {};
      params.bookModuleList = [];
      for (let i = 0; i < multipleSelection.length; i++) {
        const item = multipleSelection[i];
        let intems = {};
        intems.bookAuthor = item.author;
        intems.listModuleId = this.items.id; //书单或模块的id
        intems.id = this.items.id;
        intems.listOrModule = 1; //模块书单传1 ； 书单传0
        intems.bookPublisher = item.publisher;
        intems.bookPrice = item.price;
        intems.bookName = item.title;
        intems.bookImage = item.img;
        intems.bookId = item.id;
        intems.bookAlias = item.bookAlias;
        intems.bookSort = 0;
        params.bookModuleList.push(intems);
      }
      params.listModuleId = this.items.id; //模板或者书单id
      params.listOrModule = 1; // 书单还是模块 0书单 1模块
      const { data } = await this.$http.post("addBookToModule", params);
      if (data.ok) {
        this.$message({
          message: "添加成功",
          type: "success",
          duration: 1000,
        });
        this.getBookModuleByBookList(this.items.id);
        this.queryBook();
      }
    },

    // 2.编辑书单--- 排序编辑
    handleClick(item) {
      this.dialogVisible = true;
      this.SerialNumber = item.bookSort;
      let items = JSON.parse(JSON.stringify(item));
      this.numbersolt = items;
    },

    // 2.0排序提交
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let params = {};
          params.id = this.numbersolt.id;
          params.sort = this.numbersolt.bookSort;
          const { data } = await this.$http.post("updateBookModule", params);
          // console.log(data);
          if (data.ok) {
            this.dialogVisible = false;
            this.getBookModuleByBookList(this.items.id);
          }
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },

    // 是否可以启用
    isSHOW() {
      // console.log();
      if (this.tableData.length < 6) {
        this.isShow = false;
        this.$message.error("启用书籍模块时，书籍不能少于6本");
      }
    },

    //点击行触发，选中或不选中复选框
    handleRowClick(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    // 删除 书籍
    deleteRow(item) {
      // console.log(this.isShow);
      if (this.isShow) {
        if (this.tableData.length == 6) {
          this.$confirm(
            "删除后书籍数量低于6本，该模块将关闭启用。是否继续删除?",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(async () => {
              this.isShow = false; //禁止启用
              const { data } = await this.$http.get(
                "removeBookModule/" + item.id
              );
              if (data.ok) {
                this.isShow = false;
                this.items.Editstatus = 1;
                // 保存修改过后的资料
                let params = {};
                params.moduleTitle = this.form.moduleTitle; //模块名称
                params.moduleType = this.value - 0; //模块
                params.moduleSort = this.form.moduleSort - 0; //序号
                params.isShow = 1;
                params.id = this.items.id;
                params.loadingBut = true;
                this.loadingButtext = "保存中";
                const { data } = await this.$http.post(
                  "updateBannerModule",
                  params
                ); //编辑
                this.loadingBut = false;
                this.loadingButtext = "保存";
                if (data.ok) {
                  this.getBookModuleByBookList(this.items.id);
                }
                // 保存修改过后的资料  end
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
              }
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
        } else {
          this.deleteItem(item);
        }
      } else {
        this.deleteItem(item);
      }
    },

    // 删除
    deleteItem(item) {
      this.$confirm("是否删除该书籍?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await this.$http.get("removeBookModule/" + item.id);
          if (data.ok) {
            this.getBookModuleByBookList(this.items.id);
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    // 提交
    async onSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let params = {};
          params.moduleTitle = this.form.moduleTitle; //模块名称
          params.moduleType = this.value - 0; //模块
          params.moduleSort = this.form.moduleSort - 0; //序号
          if (this.isShow == true) {
            params.isShow = 0;
          } else {
            params.isShow = 1;
          }

          if (this.form.moduleSort < 1) {
            return this.$message.error("序号不能小于1");
          }

          if (this.items.Editstatus == 0) {
            params.moduleId = this.items.id;
            params.moduleInfo = this.items.moduleInfo;
            this.loadingBut = true;
            this.loadingButtext = "保存中";

            const { data } = await this.$http.post(
              "createBannerModule",
              params
            ); //新增
            this.loadingBut = false;
            this.loadingButtext = "保存";
            this.items.Editstatus = 1; //0位新增。将0变为1。改为编辑
            this.form.id = data.data;
            this.items.id = data.data;
            this.getBookModuleByBookList(data.data);
          } else {
            params.id = this.items.id;
            params.loadingBut = true;
            // params.isShow=this.form.isShow
            if (this.isShow) {
              params.isShow = 0;
            } else {
              params.isShow = 1;
            }
            this.loadingButtext = "保存中";
            const { data } = await this.$http.post(
              "updateBannerModule",
              params
            ); //编辑
            this.loadingBut = false;
            this.loadingButtext = "保存";
            if (data.ok) {
              this.$message.success("保存成功");
              this.getBookModuleByBookList(this.items.id);
            }
          }
        }
      });
    },

    //添加一个监听enter事件
    keyupSubmit() {
      document.onkeydown = (e) => {
        let _key = window.event.keyCode;
        if (_key === 13) {
          this.queryBook();
        }
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.img_top {
  margin-bottom: 20px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.edit_btn_Wrap {
  display: flex;
  justify-content: space-between;

  .EB_Style {
    font-weight: 700;
    padding: 5px 0;
  }

  .upbook {
    padding: 5px 14px;
    color: #fff;
    text-align: center;
    background: #ff1932;
  }
}

.wrap_cx {
  display: flex;
  align-content: center;
  justify-content: space-between;
  .addupbook {
    display: flex;
    .upbook {
      padding: 0 14px;
      line-height: 32px;
      height: 32px;
      color: #fff;
      border-radius: 2px;
      text-align: center;
      background: #ff1932;
    }

    .cancel {
      padding: 0 14px;
      line-height: 32px;
      height: 32px;
      border-radius: 2px;
      text-align: center;
      border: 1px solid #ccc;
    }
  }
}

.mg_top {
  // text-align: center;
  margin-top: 20px;
}
</style>