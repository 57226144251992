<template>
  <div>
    <el-button
      @click="upBookShelf"
      style="background-color: #ff1932; color: #fff"
      >新建分类</el-button
    >
    <el-card style="margin-top: 10px">
      <el-table
        :data="tableData"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        style="width: 100%"
        ref="table"
        :highlight-current-row="true"
        :row-key="getRowKeys"
        :expand-row-keys="expands"
      >
        <el-table-column type="index" label="序号" width="200">
        </el-table-column>
        <el-table-column prop="name" label="分类名称">
          <template slot-scope="scope">
            <div
              style="
                margin-top: 1px;
                margin-right: 10px;
                display: inline-block;
                vertical-align: sub;
              "
            >
              <img
                v-show="scope.row.ChirendShow"
                src="@/assets/img/push.svg"
                @click="pushitem(scope.row, scope.$index)"
                alt=""
              />
              <img
                v-show="!scope.row.ChirendShow"
                src="@/assets/img/del.svg"
                @click="pushitem(scope.row, scope.$index)"
                alt=""
              />
            </div>
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="author" label="操作">
          <template slot-scope="scope" style="padding-left: 10px">
            <el-button
              class="btn_right"
              type="text"
              size="mini"
              @click="showloeding(scope.row, scope.$index)"
            >
              编辑</el-button
            >
            <!--  v-if="scope.row.bookInfoChildrenList.length != 0" -->
            <el-button
              type="text"
              class="btn_right"
              size="mini"
              @click="handleToogleExpand(scope.row, scope.$index)"
              >添加子类</el-button
            >

            <el-button type="text" size="mini" @click="dellogding(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
        <el-table-column type="expand" width="1">
          <!-- 分区 -->
          <template>
            <el-table
              :data="childrenData"
              :show-header="false"
              style="width: 100%"
            >
              <el-table-column width="240"></el-table-column>
              <el-table-column prop="name"></el-table-column>
              <el-table-column prop="author">
                <template slot-scope="scope">
                  <el-button
                    class="btn_right"
                    type="text"
                    size="mini"
                    @click="upchirenloeding(scope.row)"
                  >
                    编辑</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    @click="delchirenloeding(scope.row)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </template>

          <!-- 分区 end-->
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <!-- <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.current"
          :page-sizes="[10, 15, 20]"
          :page-size="queryInfo.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div> -->

      <!-- 书架 -->
      <el-dialog :title="lodingtext" :visible.sync="dia" width="30%">
        <el-form :model="form">
          <el-form-item :label="inoputNane" label-width="80px">
            <el-input
              v-model="form.name"
              autocomplete="off"
              maxlength="8"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dia = false">取 消</el-button>
          <el-button type="primary" class="BGcolor" @click="updataBook()"
            >确 定</el-button
          >
        </span>
      </el-dialog>

      <!-- 分区 -->
      <el-dialog :title="lodingtext" :visible.sync="dia2" width="30%">
        <el-form :model="form2">
          <el-form-item :label="inoputNane" label-width="80px">
            <el-input
              v-model="form2.name"
              autocomplete="off"
              maxlength="8"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dia2 = false">取 消</el-button>
          <el-button type="primary" class="BGcolor" @click="updataBook2()"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>
<script>
// 存储当前修改项的id, 防止详情页刷新时， 页面出现空屏
export default {
  data() {
    return {
      tableData: [],

      // 遮罩层
      loading: true,
      // total: 0,

      // form 新整书架
      form: {},
      dia: false,

      // 书架 分区
      form2: {},
      dia2: false,

      // 弹窗标题
      lodingtext: "添加分类",
      // inupt名称
      inoputNane: "分类名称",
      // 1:编辑书架 2 添加分区  0 添加书架
      lodingStyle: 1,

      // 分区数组
      childrenData: [],

      // 当前点击项
      item: {},
      item_key: "",

      // 分区 当前点击的分区
      fen_delitem: {},
      getRowKeys(row) {
        return row.id;
      },
      expands: [],
    };
  },

  created() {
    //  let id = this.$route.params.id;
    let queryInfo = this.$route.params.queryInfo;
    if (queryInfo != undefined) {
      this.queryInfo = queryInfo;
    }
    this.getDataList();

    // 点击事件
    this.keyupSubmit();
  },

  mounted() {
    // 在这里你想初始化的时候展开哪一行都可以了
    let item_key = sessionStorage.getItem("class_key");

    if (!item_key) return false;

    setTimeout(() => {
      this.expands.push(this.tableData[item_key].id);
      this.childrenData = this.tableData[item_key].categoryChildrenList;
    }, 1000);
  },
  methods: {
    // 绑定Enter事件
    keyupSubmit() {
      document.onkeydown = (e) => {
        let _key = window.event.keyCode;
        if (_key === 13) {
          if (this.dia == true) {
            this.updataBook();
          } else if (this.dia2 == true) {
            this.updataBook2();
          }
        }
      };
    },

    // ******************************************************
    async getDataList() {
      const { data: res } = await this.$http.get("getCategoryList");
      this.tableData = res.data;
      this.tableData.forEach((v) => {
        v.ChirendShow = true;
      });
      this.loading = false;
    },

    upBookShelf() {
      this.lodingStyle = 0;
      this.lodingtext = "新建分类";
      this.inoputNane = "分类名称";
      this.form = {};
      this.dia = true;
    },

    // 5.0弹出层提交按钮
    async updataBook() {
      this.form.siteId = 1;
      sessionStorage.setItem("class_key", this.item_key);
      // 5.1编辑书架
      if (this.form.name == undefined || this.form.name == "")
        return this.$message.error("输入内容不能为空");
      if (this.lodingStyle == 1) {
        // this.form.siteId = 1;
        // delete this.form.ChirendShow;
        const { data: res } = await this.$http.post(
          "updateCategory",
          this.form
        );
        let msg = "编辑成功";
        let err = "失败";
        if (res.ok) {
         this.tableData[this.item_index].name=this.form.name
          this.$message.success(msg);
          this.dia = false;
        } else {
          this.$message.error(err);
        }

        // 添加子类
      } else if (this.lodingStyle == 2) {
        let prams = {};
        prams.parentCategoryId = this.form.id;
        prams.name = this.form.name;
        prams.code = "";
        const { data: res } = await this.$http.post(
          "insertSecondCategory",
          prams
        );
        let msg = "编辑成功";
        let err = "失败";
        let partition = "fenqu";
        prams.id = res.data;
        prams.inventory = res.inventory;
        prams.lastTime = res.lastTime;
        this.elmMessage(res, msg, err, partition, prams);

        // 新增分类
      } else if (this.lodingStyle == 0) {
        const { data: res } = await this.$http.post(
          "createCategory",
          this.form
        );
        let msg = "添加成功";
        let err = "失败";
        if (res.ok) {
          this.getDataList();
          this.dia = false;
          this.$message.success(msg);
          // this.tableData.push(item);
        } else {
          this.$message.error(err);
        }
      }

    },

    async updataBook2() {
      // 5.1编辑书架
      if (this.form2.name == undefined || this.form2.name == "")
        return this.$message.error("输入内容不能为空");
      const { data: res } = await this.$http.post("updateCategory", this.form2);
      let msg = "编辑成功";
      let err = "失败";

      if (res.ok) {
        let fen_key = this.childrenData.findIndex((v) => {
          return v.id == this.form2.id;
        });
        this.$set(this.childrenData, fen_key, this.form2);
        this.$message.success(msg);
      } else {
        this.$message.error(err);
      }

      this.dia2 = false;
    },

    // this.$message  方法
    elmMessage(res, msg, err, y = "Refresh", x = " ") {
      // let taht
      if (res.ok) {
        this.dia = false;
        if (y == "Refresh") {
          this.getDataList();
        } else {
          this.item.categoryChildrenList.push(x);
          this.$set(this.tableData, this.item_key, this.item);
        }

        this.$message.success(msg);
      } else {
        this.$message.error(err);
      }
    },

    // 编辑书架 1
    showloeding(item, index) {
      this.lodingtext = "编辑分类";
      this.inoputNane = "分类名称";
      this.lodingStyle = 1;
      this.item_index = index;
      this.form = JSON.parse(JSON.stringify(item));
      this.dia = true;
    },

    // 添加分区 2
    handleToogleExpand(item, index) {
      this.lodingtext = "添加子类";
      this.inoputNane = "子类名称";
      this.lodingStyle = 2;

      // 当前点击项
      this.item = item;
      this.item_key = index;

      this.form = JSON.parse(JSON.stringify(item));
      this.form.name = "";
      this.dia = true;
    },

    // 编辑分区
    upchirenloeding(item) {
      this.lodingtext = "编辑子类";
      this.inoputNane = "子类名称";
      this.lodingStyle = 3;
      this.form2 = JSON.parse(JSON.stringify(item));
      this.dia2 = true;
    },

    // 删除 节点
    delchirenloeding(item) {
      this.fen_delitem = item;
      this.$confirm("是否删除该子类?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        confirmButtonClass: "btn_clas",
      })
        .then(async () => {
          const { data: res } = await this.$http.get(
            "removeCategory/" + item.id
          );
          if (res.ok) {
            // this.getDataList();
            let fen_key = this.childrenData.findIndex((v) => {
              return v.id == this.fen_delitem.id;
            });
            this.childrenData.splice(fen_key, 1);
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          } else {
            this.$message({
              type: "error",
              message: "删除失败!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    // 删除 分类
    dellogding(item) {
      this.$confirm("是否删除该分类?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        confirmButtonClass: "btn_clas",
      })
        .then(async () => {
          const { data: res } = await this.$http.get(
            "removeCategory/" + item.id
          );
          if (res.ok) {
            this.getDataList();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          } else {
            this.$message({
              type: "error",
              message: "删除失败!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    //替换加减符号
    pushitem(row, index) {
      this.tableData.forEach((v) => {
        if (row.id == v.id) {
          v.ChirendShow = !v.ChirendShow;
        } else {
          v.ChirendShow = true;
        }
      });

      let $table = this.$refs.table;
      this.childrenData = row.categoryChildrenList;
      for (let i = 0; i < this.childrenData.length; i++) {
        if (!this.childrenData[i].inventory) {
          this.childrenData[i].inventory = 0;
        }
      }
      this.tableData.map((item) => {
        if (row.id != item.id) {
          $table.toggleRowExpansion(item, false);
        }
      });
      $table.toggleRowExpansion(row);
    },

  },
};
</script>


<style lang="scss" scoped>
.demo-table-expand {
  font-size: 0;
}

.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}

.btn_right {
  color: #2689f7;
}

::v-deep .el-table__expanded-cell {
  padding: 0;
  padding-left: 2%;
}
</style>