import axios from 'axios'

// cookie.js用来存储token
import { baseURL }  from './url.js'
import { getUserDetails } from "@/utils/auth";

//配置请求根路径
axios.defaults.withCredentials = true;
const instance = axios.create({
  baseURL: baseURL
  // timeout:5000,
})

export function get(url, params) {
  return instance.get(url, {
    params
  });
}

export function post(url, data) {
  return instance.post(url, data)
}

export function del(url, id) {
  return instance.delete(url, id)
}

// 请求拦截
instance.interceptors.request.use(config => {
  if (getUserDetails() == undefined || getUserDetails() == "null") {
    config.headers.token = ""
  } else {
    config.headers.token = JSON.parse(getUserDetails()).token
  }
  return config
}, error => {
  this.$message.error("登录失败");
})


// 响应拦截
instance.interceptors.response.use(config => {
  return config
})


const request = {
  get, post, del,
}

export default request