<template>
  <div>
    <!-- 书籍1、2、3 -->
    <div v-if="status == 3 || status == 4 || status == 5">
      <Book :status="status" :item="item"></Book>
    </div>
    <!-- 书单 -->
    <div v-else-if="status == 6">
      <BookList :status="status" :item="item"></BookList>
    </div>
    <!-- 标签 -->
    <div v-else-if="status == 7">
      <Label :status="status" :item="item"></Label>
    </div>
  </div>
</template>

<script>
import Book from "./CustomModule/book";
import BookList from "./CustomModule/bookList";
import Label from "./CustomModule/label";
export default {
  components: {
    Book,
    BookList,
    Label
  },
  data() {
    return {
      status: "",
      item:{},
    };
  },
  created() {
    let item = this.$route.query;
    this.status = item.moduleType;
    this.item=item;
  },

  methods: {},
};
</script>

<style>
</style>