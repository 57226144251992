<template>
  <div>
    <el-card class="box-card">
      <span class="header_img">编辑轮播</span>

      <div class="box-cent">主图</div>
      <div class="MainGraph">
        <el-upload
          class="avatar-uploader"
          action
          :show-file-list="false"
          :auto-upload="true"
          :limit="1"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
        >
          <img v-if="form.imageUrl" :src="form.imageUrl" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </div>

      <div class="SerialNumber">
        <span>序号</span
        ><el-input
          v-model.trim="input"
          class="max_width"
          oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
          placeholder="请输入序号"
        ></el-input>
      </div>

      <div style="margin-top: 50px">
        <div style="display: flex">
          <div
            class="hd_btn hd_btn1"
            :class="{ check_hd_btn: check_hd_btn }"
            @click="editImg"
          >
            <div
              class="hd_btn_round"
              :class="{ check_hd_round: check_hd_btn }"
            ></div>
            <span
              :class="{ check_hd_color: check_hd_btn }"
              style="font-size: 16px"
              >跳转图片</span
            >
          </div>
          <div
            class="hd_btn hd_btn2"
            :class="{ check_hd_btn: !check_hd_btn }"
            @click="editBook"
          >
            <div
              class="hd_btn_round"
              :class="{ check_hd_round: !check_hd_btn }"
            ></div>
            <span
              :class="{ check_hd_color: !check_hd_btn }"
              style="font-size: 16px"
              >跳转链接</span
            >
          </div>
        </div>
      </div>

      <div v-if="check_hd_btn" style="max-width: 400px; margin-top: 10px">
        <el-upload
          class="upload-demo"
          :limit="1"
          :action="uploadAboutBannerFile"
          :on-success="handleRemove1"
          :file-list="fileList"
          :on-remove="handleRemove"
          list-type="picture"
        >
          <div style="font-size: 15px; color: #409eff" v-if="uploadStatus">
            点击上传
          </div>
        </el-upload>
      </div>

      <div v-else class="SerialNumber">
        <span>跳转链接</span
        ><el-input
          v-model="link"
          class="max_width2"
          placeholder="请输入链接"
        ></el-input>
      </div>

      <div style="margin-top: 60px; display: flex">
        <div class="swrpie" style="margin-right: 20px" @click="cancal">
          取消
        </div>
        <el-button
          class="swrpie swrpieChek"
          @click="submitMap"
          :loading="loadingBut"
          >{{ loadingButtext }}</el-button
        >
      </div>
    </el-card>
  </div>
</template>

<script>
import { baseURL } from "../../axios/url.js";
export default {
  data() {
    return {
      form: {
        imageUrl: "",
        bannerModuleId: "",
      },
      uploadAboutBannerFile: "", //上传图片路径
      input: 1,
      check_hd_btn: true, //选中项 true 为图片
      check: false, //是否启用
      addStatus: 0, //0新增；1编辑
      fileList: [],
      imgall: {}, //集合
      link: "", // 链接

      loadingBut: false,
      loadingButtext: "确认",
      banner: {}, //轮播图
      uploadStatus: true, //判断组图上传按钮是否显示
      suo: true, //判断组图上传按钮是否显示
    };
  },

  mounted() {
    let query = this.$route.query;
    this.form.bannerModuleId = query.id;

    this.addStatus = query.addStatus; //判断字段；0 新增；1编辑
    if (query.addStatus == 1) {
      let allImg = {};
      // if (query.pictureOrLink == 0) {
      //   allImg.url = query.pictureLinkInfo; //跳转主题
      //   allImg.name = "跳转图片"; //标题
      //   this.fileList.push(allImg);
      // }
      this.form.imageUrl = query.slideshowFirst; //主图
      this.form.isShow = query.isShow;
      this.input = query.slideshowSort; //序号
      if (query.isShow == "0") {
        //是否展示
        this.check = true;
      } else {
        this.check = false;
      }

      if (query.pictureOrLink == 0) {
        //图片还是链接 0 图片； 1链接
        this.check_hd_btn = true;
        this.imgall.url = query.pictureLinkInfo; //跳转主题
        allImg.url = query.pictureLinkInfo; //跳转主题
        allImg.name = "跳转图片"; //标题
        this.fileList.push(allImg);
      } else {
        // 为编辑时，且编辑内容为“跳转图片时，将img赋值”
        this.check_hd_btn = false;
        this.link = query.pictureLinkInfo;
      }
      this.form.id = query.id; //轮播图主键id
    }
    this.uploadAboutBannerFile = baseURL + "/uploadAboutBannerFile"; //路径
  },

  methods: {
    // 1.顶部css切换
    editImg() {
      this.check_hd_btn = true;
    },
    editBook() {
      this.check_hd_btn = false;
      let allImg = {}; //轮播图
      if (this.fileList.length == 0 && this.banner.ok) {
        allImg.url = this.banner.data; //跳转主题
        allImg.name = "待上传"; //标题
        this.fileList.push(allImg);
        this.uploadStatus=false
        // console.log(this.fileList);
      }
    },

    handleAvatarSuccess(res, file) {
      this.$refs.upload.clearFiles(); //上传成功之后清除历史记录
      this.handleInitTableData(); //初始化表格数据
    },

    // 上传图片 可封装
    beforeAvatarUpload(file) {
      this.uploadIMG(file).then((data) => {
        this.form.imageUrl = data;
      });
    },

    // 监听轮播图删除
    handleRemove(file) {
      this.uploadStatus = true;
    },

    handleContent(file, fileList) {
      this.fileList = fileList; //将图片数组存储
    },

    beforeContent(file) {
      this.uploadIMG(file).then((data) => {
        this.fileList.push(data);
      });
    },

    handleRemove1(file) {
      this.imgall.url = file.data;
      this.banner = file; //轮播图
      this.uploadStatus = false;
    },

    // 添加首页轮播图
    submitMap() {
      //新增
      if (this.addStatus == 0) {
        this.addBanner();
      } else {
        this.editbanenr();
      }
    },

    cancal() {
      this.$router.go(-1);
    },

    // 新增轮播图
    async addBanner() {
      let params = {};
      if(!this.suo){
        return console.log("重复点击")
      }
      params.bannerModuleId = this.form.bannerModuleId;
      //是否展示 0 展示； 1不展示
      params.isShow = 1;
      if (!this.form.imageUrl) {
        return this.$message.error("主图不能为空");
      }

      if (!this.input) {
        return this.$message.error("请填写序号");
      }

      if (this.check_hd_btn) {
        params.pictureOrLink = 0;
        params.pictureLinkInfo = this.imgall.url;
        // if (!this.imgall.url) {
        //   return this.$message.error("跳转图片不能为空");
        // }
      } else {
        params.pictureOrLink = 1;
        params.pictureLinkInfo = this.link;
        // if (!this.link) {
        //   return this.$message.error("链接不能为空");
        // }
      }

      //图片还是链接  0图片 1链接
      if (this.check_hd_btn) {
        params.pictureOrLink = 0;
        params.pictureLinkInfo = this.imgall.url;
      } else {
        params.pictureOrLink = 1;
        params.pictureLinkInfo = this.link;
      }

      params.slideshowFirst = this.form.imageUrl; //轮播图主图
      params.slideshowSort = this.input; //排序

      this.suo = false;
      this.loadingBut = true;
      this.loadingButtext = "提交中";
      const { data } = await this.$http.post("createSlideshowDetails", params);
      if (data.ok) {
        this.loadingBut = false;
        this.loadingButtext = "确认";
        this.$message.success("新增轮播图成功");
        setTimeout(() => {
          this.$router.go(-1);
        }, 2000);
      } else {
        this.loadingBut = false;
        this.suo = true;
        this.loadingButtext = "确认";
      }
    },

    // 编辑轮播图
    async editbanenr() {
       if(!this.suo){
        return console.log("重复点击")
      }
      let params = {};
      params.bannerModuleId = this.form.bannerModuleId;
      //是否展示 0 展示 ； 1不展示
      params.isShow = 1;
      if (!this.form.imageUrl) {
        return this.$message.error("主图不能为空");
      }

      if (!this.input) {
        return this.$message.error("请填写序号");
      }

      //图片还是链接  0图片 1链接

      if (this.check_hd_btn) {
        params.pictureOrLink = 0;
        params.pictureLinkInfo = this.imgall.url;
        // if (!this.imgall.url) {
        //   return this.$message.error("跳转图片不能为空");
        // }
      } else {
        params.pictureOrLink = 1;
        params.pictureLinkInfo = this.link;
        // if (!this.link) {
        //   return this.$message.error("链接不能为空");
        // }
      }

      this.suo = false;
      params.id = this.form.id; //轮播图主键id
      params.slideshowFirst = this.form.imageUrl; //轮播图主图
      params.slideshowSort = this.input; //排序
      params.isShow = this.form.isShow;
      this.loadingBut = true;
      this.loadingButtext = "提交中";
      const { data } = await this.$http.post("updateSlideshowDetails", params);
      if (data.ok) {
        this.loadingBut = false;
        this.loadingButtext = "确认";
        this.$message.success("编辑轮播图成功");
        setTimeout(() => {
          this.$router.go(-1);
        }, 2000);
      } else {
        this.suo = true;
        this.loadingBut = false;
        this.loadingButtext = "确认";
        this.$message.error("编辑轮播图失败");
      }
    },

    // **************** 上传图片
    async uploadIMG(file) {
      const typeArr = ["image/png", "image/gif", "image/jpeg", "image/jpg"];
      const isJPG = typeArr.indexOf(file.type) !== -1;
      const isLt3M = file.size / 1024 / 1024 < 3;
      if (!isJPG) {
        this.$message.error("只能是图片!");
        this.files = null;
        return;
      }
      if (!isLt3M) {
        this.$message.error("上传图片大小不能超过 3MB!");
        this.files = null;
        return;
      }
      this.files = file;
      // FormData 对象
      var formData = new FormData();
      // 文件对象
      formData.append("file", this.files);
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        methods: "post",
      };
      const { data } = await this.$http.post(
        "uploadAboutBannerFile",
        formData,
        config
      );
      return data.data;
    },
  },
};
</script>
<style lang="scss" scoped>
.box-card {
  box-sizing: border-box;
  padding: 31px 51px;
  .header_img {
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 25px;
  }

  .MainGraph {
    margin-top: 10px;
    width: 490px;
    height: 140px;
    border: 1px solid #ccc;
    text-align: center;
    line-height: 120px;
  }

  .SerialNumber {
    display: flex;
    align-items: center;
    margin-top: 40px;
    span {
      margin-right: 10px;
    }

    .max_width {
      max-width: 96px;
    }

    .max_width2 {
      max-width: 200px;
    }
  }
}

.avatar {
  width: 490px;
  height: 140px;
  display: block;
}

.box-cent {
  margin-top: 18px;
}

.hd_btn {
  padding: 6px 6px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #666666;
  .hd_btn_round {
    width: 9px;
    height: 9px;
    margin-right: 5px;
    border-radius: 50%;
    border: 1px solid #666666;
  }

  .check_hd_round {
    border: 1px solid #ff1932;
  }

  .check_hd_color {
    color: #ff1932;
  }
}

// 选中css
.check_hd_btn {
  border: 1px solid #ff1932;
}

.hd_btn2 {
  margin-left: 21px;
}

.el-row {
  margin-bottom: 10px;
}

.swrpie {
  padding: 10px 25px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.swrpieChek {
  color: #fff;
  background: #ff1932;
}
</style>