<template>
  <div>
    <div class="labelWrap">
      <div class="img_top">编辑模块</div>
      <el-row :gutter="20">
        <el-col :span="16">
          <el-col :span="5" style="min-width: 200px">
            <img
              v-if="BannerModuleDetails.length != 0"
              :src="BannerModuleDetails[4].moduleImage"
              alt=""
              width="140px"
            />
            <img
              v-else
              src="@/assets/img/label1.svg"
              alt=""
              style="width: 180px"
            />
          </el-col>
          <el-col :span="12">
            <el-form ref="form" :model="form" :rules="rules" label-width="80px">
              <el-form-item label="模块名称" prop="moduleTitle">
                <el-input v-model="form.moduleTitle"></el-input>
              </el-form-item>
              <el-form-item label="模块描述">
                <el-input v-model="form.moduleInfo"></el-input>
              </el-form-item>
              <el-form-item label="序号" prop="moduleSort">
                <el-input v-model.number="form.moduleSort"></el-input>
              </el-form-item>
              <el-form-item label="是否启用" v-if="items.Editstatus == 1">
                <el-switch
                  v-model="isShow"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  @change="ISSHoW"
                >
                </el-switch>
              </el-form-item>
              <!-- 添加标签 -->
              <el-form-item
                v-if="items.Editstatus == 1"
                label="添加标签"
                class="PR"
              >
                <el-row style="margin-left: 24px" :style="{ width: label_w }">
                  <!-- 新增的标签 -->
                  <div>
                    <div v-for="(item, i) in labels" :key="i" class="labels">
                      <span v-if="item.tagName != null">{{
                        item.tagName
                      }}</span>
                      <img
                        src="@/assets/img/delItem.svg"
                        alt=""
                        style="margin-left: 10px; width: 12px"
                        @click="delitem(i, item)"
                      />
                    </div>
                  </div>

                  <!-- 标签点击按钮 -->
                  <div @click="labelShow" style="width: 100px; display: flex">
                    <div style="margin-top: 3px; display: inline-block">
                      <img
                        src="@/assets/img/upitem.svg"
                        style="vertical-align: sub; margin-right: 11px"
                        alt=""
                      />
                    </div>
                    <el-button type="text">点击添加标签</el-button>
                  </div>
                </el-row>

                <!-- 需要 显示、 隐藏、筛选、新增、删除 功能  -->
                <el-card v-show="visible" class="PA_C" style="overflow: revert">
                  <!-- 选中内容 -->
                  <el-input
                    class="inline-input"
                    placeholder="选择标签或输入标签按回车键确认"
                    ref="getFocus"
                    maxlength="10"
                    show-word-limit
                    v-model.trim="input_value"
                  ></el-input>

                  <!-- 左右分离 -->
                  <div style="display: flex; justify-content: space-between">
                    <span>最近使用</span>
                    <el-button type="text" @click="dellist">清除记录</el-button>
                  </div>

                  <!-- 内容 -->
                  <div class="list_item">
                    <div
                      v-for="(item, i) in JsonsData"
                      :key="i"
                      class="text item"
                    >
                      <span
                        class="item_css"
                        @click="screen(i, item)"
                        :class="item.check == true ? 'addclass' : ''"
                      >
                        <img
                          v-show="item.check == true"
                          src="@/assets/img/addItem.svg"
                          alt=""
                        />
                        {{ item.name }}
                      </span>
                    </div>
                  </div>
                </el-card>
              </el-form-item>
              <el-form-item>
                <el-button @click="cancel">取消</el-button>
                <el-button
                  type="primary"
                  @click="onSubmit('form')"
                  :loading="loadingBut"
                  >{{ loadingButtext }}</el-button
                >
              </el-form-item>
            </el-form>
          </el-col>
        </el-col>
      </el-row>
    </div>

    <!-- 遮罩层 点击取消弹框 -->
    <div v-show="mask" class="maskFile" @click="delMask"></div>
  </div>
</template>

<script>
export default {
  data() {
    var BannerModuleDetails = this.$store.state.BannerModuleDetails;
    return {
      form: {
        moduleTitle: "",
        moduleInfo: "",
        moduleSort: 1,
      },
      items: {}, //上个页面传递来的值
      table: false, //抽屉
      dialogVisible: false, //排序弹出层
      isShow: false, // 启用开关
      label_w: "800px",
      loadingBut: false,
      loadingButtext: "确认",

      labels: [], //选中的标签
      JsonsData: [], //全部标签
      visible: false, //显示标签列表
      mask: false, //遮罩层
      suo: true, //提交锁
      input_value: "", // 标签赛选值。

      rules: {
        moduleTitle: [
          { required: true, message: "请输入模块名称", trigger: "blur" },
        ],
        moduleSort: [
          { required: true, message: "序号不能为空" },
          { type: "number", message: "序号必须为数字值" },
        ],
      },
      BannerModuleDetails: BannerModuleDetails, //共用数据图片
    };
  },

  mounted() {
    // 1.3 页面加载完成调用数据 获取 标签
    this.loadAll();
    let item = this.$route.query;
    // 0为新增  1为编辑
    if (item.Editstatus == 1) {
      item.moduleSort = Number(item.moduleSort);
      this.form = item;
      if (item.isShow == 0) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
      this.getBannerModuleDetails(item.id);
    }
    this.items = item; //上个页面跳转携带的数据
  },

  // 监听
  watch: {
    input_value: {
      handler(newValue, oldValue) {
        // console.log(oldValue); //更改前
        // 1.4重新置回-1
        this.isactive = -1;
        // console.log(newValue); //更改后
        if (newValue) {
          this.search(newValue);
        } else {
          this.JsonsData = this.restaurants;
        }
      },
    },
  },

  methods: {
    // 2.编辑模块--- 排序编辑
    handleClick(item) {
      this.dialogVisible = true;
    },

    // 获取模块详情
    async getBannerModuleDetails(id) {
      const { data } = await this.$http.get("getBannerModuleDetails/" + id);
      this.form.isShow = data.data.isShow;
      this.form.moduleTitle = data.data.moduleTitle;
      this.form.moduleSort = data.data.moduleSort;
      if (data.data.isShow == 0) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }

      this.labels = data.data.tagModuleList || [];
    },

    // 2.0排序提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          return false;
        }
      });
    },

    // 标签是否可以启用
    ISSHoW() {
      if (this.labels.length < 1) {
        this.isShow = false;
        this.$message.error("至少有一个标签才可启用");
      }
    },

    // 2.0-1重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    //3.0 添加书籍
    addBook() {
      this.table = true;
    },

    //3.0-1 添加书籍  --  右侧弹出层书籍
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },


    // 提交
    onSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
           if(!this.suo){
            return console.log("重复点击")
          }
          let params = {};
          if (this.form.moduleSort < 1) {
            return this.$message.error("序号不能小于1");
          }
          params.id = this.items.id; //模块id
          params.moduleInfo = this.form.moduleInfo; //描述
          params.moduleTitle = this.form.moduleTitle; //名称
          params.moduleSort = this.form.moduleSort; //序号
          params.moduleType = this.items.moduleType; //模块类型
          if (this.isShow) {
            params.isShow = 0; //启用
          } else {
            params.isShow = 1; //未启用
          }
          if (this.items.Editstatus == 1) {
            params.tagModuleList = []; //标签信息集合
            for (let i = 0; i < this.labels.length; i++) {
              const item = this.labels[i];
              let label = {};
              label.bannerModuleId = this.items.moduleId;
              label.id = this.items.id;
              label.tagId = item.tagId || item.id;
              label.tagName = item.name || item.tagName;
              params.tagModuleList.push(label);
            }
            this.loadingBut = true;
            this.loadingButtext = "提交中";
            const { data } = await this.$http.post(
              "updateBannerModule",
              params
            );
            this.suo = false;
            this.loadingBut = false;
            this.loadingButtext = "确定";
            if (data.ok) {
              this.$message.success("编辑成功，返回上一页");
              setTimeout(() => {
                this.$router.back();
              }, 1000);
            }
          } else {
            this.loadingBut = true;
            this.loadingButtext = "提交中";
            const { data } = await this.$http.post(
              "createBannerModule",
              params
            );
            this.loadingBut = false;
            this.loadingButtext = "确定";
            if (data.ok) {
              // console.log(data);

              this.items.moduleId = data.data;
              this.items.Editstatus = 1;
              this.items.id = data.data;
              this.getBannerModuleDetails(data.data);
            }
          }
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },

    // 标签操作-- 获取所有的标签
    async loadAll() {
      const { data: res } = await this.$http.get("getBookTagList");
      // 标签栏的赋值
      this.restaurants = res.data;
      this.JsonsData = res.data;
      this.restaurants.forEach((v) => {
        v.check = false;
      });
      this.updatalist();
    },

    //标签操作 -- 筛选选中项
    updatalist() {
      setTimeout(() => {
        for (var a = 0; a < this.labels.length; a++) {
          for (var i = 0; i < this.restaurants.length; i++) {
            if (this.labels[a].tagId == this.restaurants[i].id) {
              // console.log(this.restaurants[i]);
              let item = this.restaurants[i];
              item.check = true;
              this.$set(this.restaurants, i, item);
            }
          }
        }
      }, 100);
    },

    // 标签操作 -- 显示标签
    labelShow() {
      this.input_value = "";
      this.visible = !this.visible;
      this.mask = true;
      if (this.visible == true) {
        setTimeout(() => {
          this.$refs["getFocus"].focus();
        }, 200);
      } else {
        setTimeout(() => {
          this.$refs["getFocus"].blur();
        }, 200);
      }
    },

    //标签操作 -- 遮罩层
    delMask() {
      this.visible = false;
      this.mask = false;
    },

    //标签操作 -- 清除历史记录
    dellist() {
      this.JsonsData = [];
      this.restaurants = [];
    },

    //1.2 模糊查询
    search(newValue) {
      // 支持模糊查询
      // this.xmgcqkJsonsData：用于搜索的总数据
      //　toLowerCase():用于把字符串转为小写，让模糊查询更加清晰
      let _search = newValue.toLowerCase();
      let newListData = []; // 用于存放搜索出来数据的新数组
      if (_search) {
        this.restaurants.filter((item) => {
          if (item.name.toLowerCase().indexOf(_search) !== -1) {
            newListData.push(item);
          }
        });
      }
      if (newListData.length != 0) {
        this.JsonsData = newListData;
      } else {
        this.JsonsData = [];
      }
    },

    //标签操作-- 筛选标签选中项
    async screen(i, item) {
      item.check = !item.check;
      let item_key = item;
      this.$set(this.JsonsData, i, item_key);
      // 光标重新回到inupt框内
      setTimeout(() => {
        this.$refs["getFocus"].focus();
      }, 200);


      let index_item = this.labels.findIndex((v) => {
        return v.id == item_key.id || v.tagId == item_key.id;
      });
      if (item.check) {
        item.tagName = item.name;
        return this.labels.push(item);
      } else {
        return this.labels.splice(index_item, 1);
      }
    },

    // 标签操作 --  删除单条已添加标签
    delitem(i, item) {
      let index = this.restaurants.findIndex((v) => {
        return v.id == item.tagId || v.id == item.id;
      });
      // 如果点击了清空数据，那么将拿不到 this.restaurants 总数据  dess 将为 undefinde
      let dess = this.restaurants[index];
      if (dess) {
        dess.check = false;
      }
      this.labels.splice(i, 1);
      this.$set(this.restaurants, index, dess);
      if (this.labels.length == 0) {
        this.isShow = false;
      }
    },

    // 取消返回上一页
    cancel() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.img_top {
  margin-bottom: 20px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.edit_btn_Wrap {
  display: flex;
  justify-content: space-between;

  .EB_Style {
    font-weight: 700;
    padding: 5px 0;
  }

  .upbook {
    padding: 5px 14px;
    color: #fff;
    text-align: center;
    background: #ff1932;
  }
}

.wrap_cx {
  display: flex;
  align-content: center;
  justify-content: space-between;
  .addupbook {
    display: flex;
    .upbook {
      padding: 0 14px;
      line-height: 32px;
      height: 32px;
      color: #fff;
      border-radius: 2px;
      text-align: center;
      background: #ff1932;
    }

    .cancel {
      padding: 0 14px;
      line-height: 32px;
      height: 32px;
      border-radius: 2px;
      text-align: center;
      border: 1px solid #ccc;
    }
  }
}

// 标签容器
.list_item {
  max-height: 300px;
  overflow-y: auto;
}

.labels {
  padding: 0 10px;
  margin-bottom: 5px;
  margin-right: 5px;
  border-radius: 3px;
  display: inline-block;
  background: #f4f4f5;
}

/*滚动条样式*/
.list_item::-webkit-scrollbar {
  width: 4px;
}
.list_item::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
.list_item::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

/* 定位 标签 添加标签。收搜标签*/
.PR {
  position: relative;
  .PA_C {
    position: absolute;
    min-width: 436px;
    z-index: 5;
    min-height: 431px;
    max-height: 500px;
    background: #ffffff;
    box-shadow: -5px 5px 15px 0px rgba(51, 51, 51, 0.2);
  }

  .PA_C:after,
  .PA_C:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: #b3bdc1;
    left: 6%;
    margin-left: -4px;
    top: -20px;
  }

  .PA_C:after {
    border-bottom-color: #fff;
  }
}

/* 模糊查询 */
.item_css {
  padding: 3px 8px;
  border-radius: 3px;
  background-color: #f4f4f5;
}
.addclass {
  background-color: #ff1932;
  color: #fff;
}

// 遮罩层
.maskFile {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.labelWrap {
  padding: 25px 50px 21px;
  background: #fff;
}
</style>