<template>
  <!-- 首页图片 -->
  <div>
    <el-row>
      <el-col :span="24" class="edit_btn_Wrap">
        <div class="EB_Style">编辑轮播</div>
        <div class="upbook" @click="addSwiper">添加轮播</div>
      </el-col>
    </el-row>
    <template class="table_top">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column label="轮播图">
          <template slot-scope="scope">
            <img :src="scope.row.slideshowFirst" alt="" width="80px" />
          </template>
        </el-table-column>
        <el-table-column prop="slideshowSort" label="序号"> </el-table-column>
        <el-table-column prop="pictureLinkInfo" label="跳转内容">
        </el-table-column>
        <el-table-column prop="isShow" label="是否启用">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.check"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="isShow(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作">
          <template slot-scope="scope">
            <el-button
              @click="handleClick(scope.row)"
              type="text"
              size="small"
              style="color: #0bd46a"
              >编辑</el-button
            >
            <el-button
              v-if="scope.row.prohibit"
              @click.native.prevent="deleteRow(scope.row)"
              type="text"
              size="small"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      check_hd_btn: true,
      tableData: [],

      // 编辑弹出层
      dialogVisible: false,
      id: "", //	模块级别 （1轮播图模块、2固定书单模块、3书籍一模块、4书籍二模块、5书籍三模块、6书单模块、7标签模块
      // 排序
      item: {
        page: 10,
      },

      arrShow:0, //启用轮播图的数量
    };
  },
  mounted() {
    this.gethandBook();
  },
  methods: {
    async gethandBook() {
      const { data } = await this.$http.get("getBannerModuleList/1/10");
      let fixdBook = data.data.records;
      let item = fixdBook.find((v) => {
        return v.moduleType == 1;
      });
      this.id = item.id;
      this.getbanner(item.id);
    },

    //轮播图
    async getbanner(id) {
      const { data } = await this.$http.get("getBannerModuleDetails/" + id);
      let slideshowList = data.data.slideshowList;
      for (let i = 0; i < slideshowList.length; i++) {
        const item = slideshowList[i];
        item.prohibit = true;
        if (item.isShow == 1) {
          item.check = false;
        } else {
          item.check = true;
        }
      }

      let arr = slideshowList.filter((item) => item.isShow == 0);

      if (arr.length <= 2) {
        // 根据单个名字筛选
        for (let i = 0; i < arr.length; i++) {
          const element = arr[i];
          element.prohibit = false;
          let index = slideshowList.findIndex((v) => v.id == arr[i].id);
          slideshowList[index] = arr[i];
        }
      }

      this.arrShow=arr; //启用轮播图的数量
      this.tableData = slideshowList;
    },

    // 1.顶部css切换
    editImg() {
      this.check_hd_btn = true;
    },

    editBook() {
      this.check_hd_btn = false;
    },

    // 2.排序编辑
    handleClick(item) {
      item.addStatus = 1; //编辑
      this.$router.push({ path: "/HDSwiper", query: item });
    },

    // 跳转到添加轮播图页
    addSwiper() {
      let query = {};
      query.id = this.id;
      query.addStatus = 0; //新增
      this.$router.push({ path: "/HDSwiper", query: query });
    },

    // 启用
    async isShow(item) {
      if (this.arrShow.length<=2) {
       let index=this.arrShow.findIndex(v=>{
         return v.id==item.id
        })
        if (index>-1) {
          item.check=true;
          this.$message.error("轮播图至少启用两个")
        }
      }
      if (item.check) {
        item.isShow = 0;
        delete item.check;
      } else {
        item.isShow = 1;
        delete item.check;
      }
      const { data } = await this.$http.post("updateSlideshowDetails", item);
      if (data.ok) {
        this.getbanner(this.id);
      }
    },

    // 2.3删除轮播图
    deleteRow(rows) {
      this.$confirm("是否删除该轮播图?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          if (this.tableData.length <= 2) {
            this.$message({
              type: "error",
              message: "轮播图至少剩余两张!",
            });
          } else {
            const { data } = await this.$http.get("removeSlideshow/" + rows.id);
            if (data.ok) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getbanner(this.id);
            }
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
//2 表格列表
.box-card {
  margin-top: 10px;

  .edit_btn_Wrap {
    display: flex;
    justify-content: space-between;

    .EB_Style {
      font-weight: 700;
      padding: 5px 0;
    }

    .upbook {
      padding: 5px 14px;
      color: #fff;
      text-align: center;
      background: #ff1932;
    }
  }
}
</style>