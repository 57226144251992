export default {
    data() {
        return {
            nav_List: [],
            //弹出框显示或者隐藏
            dialogFormVisible: false,
            pushform: {
                // ISBN码
                isbnCode: "",
                qrCode: "",
            },

            erweima1: false,
            erweima2: false,

            loading:true,

            // // 手动入库值
            // listItem: {},
            // 手动入库编辑
            details: {},


            // 
            Book_list: [

            ],

            tableData: [],
            // QRcode不携带微信参数

            // 查询参数对象
            queryInfo: {
                // pagenum: 1,//pageIndex
                current: 1,
                size: 10, //每一页
            },

            // 总条数，用来分
            total: 0,
            bookName: "软件工程",


            // ***************************************************

            // 1 子书父书区分层
            EditDialog: false,


            // 2 当前选中的子书数据
            item: {},

            // 3 选中了当前项
            checkValue: 0,


            // 用户输入的qrCode（图书码）
            bookQrCode: ""
        };
    },

    created() {
        this.getDataList();
 
    },

    computed: {
        fullName: function () {
            return this.pushform;
        },
    },

    methods: {
        addDialogVisible() {
            this.dialogFormVisible = true;
            this.erweima1 = true;
            this.erweima2 = false;
            this.$nextTick(() => {
                this.$refs["qrCodeinupt"].focus();
            });
        },


        //  1.页面加载时拉取后台数据渲染页面
        async getDataList() {
            const { data } = await this.$http.get("getRecentlyBookStorage",this.queryInfo);
            this.tableData = data.data.list;
            // isIncludeChildren
            this.total = data.data.total;
            this.loading = false;
        },


        // 1.1当输入完图书面时，绑定的监听事件。直接换行
        keyupSubmit() {
            document.onkeydown = (e) => {
                let _key = window.event.keyCode;
                if (_key === 13) {
                    this.$refs["ISBNinput"].focus();
                }
            };
        },

        // 2 跳转到 ---入库页
        ToManipulate() {
            this.$router.push({ path: "/manipulate" });
            this.dialogFormVisible = false;
        }, 


        // 1.3 关闭弹出层 ，清除输入框内 内容
        addDialogClosed() {
            this.pushform.isbnCode = "";
            this.pushform.qrCode = "";
        },


        // 2.1 补录入库
        async addBook_Manual() {
            if (!this.pushform.qrCode) {
                return this.$message.error("请输入图书码");
            }


            let indeOF = this.pushform.qrCode.indexOf(
                "http://jdsf.cc/t?t=b&i=",0
            );
            if (indeOF == -1) {
                this.pushform.qrCode = "";
                // this.pushform.isbnCode = "";
                return this.$message.error("请输入图书侠专用书籍二维码");
            }

            if(this.pushform.qrCode.length !=55){
                return this.$message.error("图书码位数错误");
            }

            if (!this.pushform.isbnCode) {
                return this.$message.error("请输入ISBN码");
            }

            let lenght = this.pushform.isbnCode.length;
            if (lenght == 13 || lenght == 10) {
            } else {
                return this.$message.error("isbn码的长度应为10 或者 13 位");
            }

            // // 截取字符串
            // let index = this.pushform.qrCode.lastIndexOf("&i=");
            // let str = this.pushform.qrCode.substring(
            //     index + 3,
            //     this.pushform.qrCode.length
            // );

            // 2.2.1 j截取赋值
            this.bookQrCode = this.pushform.qrCode;
            let axios_arr = {}
            axios_arr.isbnCode = this.pushform.isbnCode
            axios_arr.qrCode = this.pushform.qrCode;
            // 2.2.2 发起请求 传isbn码，获取子书 || 父书 
          


            const { data } = await this.$http.post("getBookByISBN", axios_arr)
            this.checkValue = 0;
            if (!data.ok)  return this.$message.error("该书已入库！请核对信息")
            

            // 2.2.3 赋值到 子书 || 父书列表中
            this.Book_list = data.data

            // 2.2.4 页面初次加载。默认选中第一条
            this.item = this.Book_list[0]
            // ************** 包含用户isbn码  用户图书码*****************


            // 关闭第一层弹框
            this.dialogFormVisible = false

            // 打开第二层弹框
            this.EditDialog = true
        },


        // 3.1  子书 父书 当前选中项。
        BookIist(item, i) {
            this.item = item
            this.checkValue = i
        },

        async Supplement() {
            let axios_arr = {}
            axios_arr.bookId = this.item.id
            axios_arr.qrCode = this.bookQrCode 
            axios_arr.id = this.bookQrCode.split("i=")[1]
            const { data } = await this.$http.post("saveBookSupplementInfo", axios_arr)
            if (data.code == -1) {
                this.$message.error("入库失败")
            } else {
                this.$message.success("入库成功")
                // 关闭第二层弹框
                this.EditDialog = false
                this.getDataList()
            }
        },


        // 监听分页跳转
        handleSizeChange(val) {
            this.queryInfo.size = val;
            this.getDataList();
        },

        handleCurrentChange(val) {
            this.queryInfo.current = val;
            this.getDataList();
        },
    },
};