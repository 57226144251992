<template>
  <div>
    <el-card style="padding: 25px 50px 21px">
      <div class="img_top">编辑模块</div>
      <el-row :gutter="20">
        <el-col :span="16">
          <el-col :span="5" style="min-width: 200px">
            <img
              v-if="BannerModuleDetails.length != 0"
              :src="BannerModuleDetails[3].moduleImage"
              alt=""
              width="140px"
            />
            <img v-else src="@/assets/img/BookList.svg" alt="" />
          </el-col>
          <el-col :span="12">
            <el-form ref="form" :rules="rules" :model="form" label-width="80px">
              <el-form-item label="模块名称" prop="moduleTitle">
                <el-input v-model="form.moduleTitle"></el-input>
              </el-form-item>
              <el-form-item label="序号" prop="moduleSort">
                <el-input v-model.number="form.moduleSort"></el-input>
              </el-form-item>
              <el-form-item label="是否启用" v-if="items.Editstatus == 2">
                <el-switch
                  v-model="isShow"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  @change="ISSHOU"
                >
                </el-switch>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  class="btn_Submit"
                  style="background: #ff1932"
                  @click="onSubmit('form')"
                  :loading="loadingBut"
                  >{{ loadingButtext }}</el-button
                >
              </el-form-item>
            </el-form>
          </el-col>
        </el-col>
      </el-row>
    </el-card>

    <el-card style="margin-top: 20px" v-if="items.Editstatus == 2">
      <el-row>
        <el-col :span="24" class="edit_btn_Wrap">
          <div class="EB_Style">模块内书单</div>
          <div class="upbook" @click="addBook(0)">添加书单</div>
        </el-col>
      </el-row>
      <template class="table_top">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column type="index" width="50"> </el-table-column>
          <el-table-column prop="bookListTitle" label="书单名称">
          </el-table-column>
          <el-table-column prop="bookListInfo" label="书单描述">
          </el-table-column>
          <el-table-column prop="bookListSort" label="序号"> </el-table-column>
          <el-table-column prop="bookName" label="启用状态">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.check"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="shouBook(scope.row)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="操作">
            <template slot-scope="scope">
              <el-button
                @click="handleClick(scope.row)"
                type="text"
                size="small"
                style="color: #0bd46a"
                >编辑</el-button
              >
              <el-button
                v-if="scope.row.prohibit"
                @click.native.prevent="deleteRow(scope.row)"
                type="text"
                size="small"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </el-card>
  </div>
</template>

<script>
export default {
  props: ["status", "item"],
  data() {
    var BannerModuleDetails = this.$store.state.BannerModuleDetails;
    return {
      form: {
        moduleTitle: "",
        moduleSort: 1,
      },

      isShow: false, //是否启用
      loadingBut: false,
      loadingButtext: "保存",
      forbid: false, //模块默认不能启用
      table: false, //抽屉
      dialogVisible: false, //排序弹出层

      // 数组数据
      tableData: [],
      rules: {
        moduleTitle: [
          { required: true, message: "请输入模块名称", trigger: "blur" },
        ],
        moduleSort: [
          { required: true, message: "序号不能为空" },
          { type: "number", message: "序号必须为数字值" },
        ],
      },

      // 遮罩层显示数组数据
      gridData: [],

      // 选中项
      options: [
        {
          value: "3",
          label: "书籍1",
        },
        {
          value: "4",
          label: "书籍2",
        },
        {
          value: "5",
          label: "书籍3",
        },
      ],
      value: this.status, //选中项

      items: this.item, //父级页面传递来的值

      // 排序
      numbersolt: {
        bookSort: 0,
      },

      input3: "",
      EnablingArr: [], //书单列表中正在启用的书单集合

      multipleSelection: [], //选中书籍
      BannerModuleDetails: BannerModuleDetails, // 公共数据
    };
  },

  mounted() {
    // 存储的id
    let itemsID = sessionStorage.getItem("itemsID");
    // 判断路由来源，赛选是否执行刷新  新增为1； 编辑为2；
    let Satus = sessionStorage.getItem("Satus");
    this.items.Editstatus = Satus;
    if (this.items.isShow == 0) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
    if (this.items.Editstatus == 2) {
      this.items.moduleSort = Number(this.items.moduleSort);
      if (itemsID != -1) {
        this.getBookModuleByBookList(itemsID);
      } else {
        this.getBookModuleByBookList(this.items.id);
      }
    }
    let temporaryData = JSON.parse(JSON.stringify(this.items));
    temporaryData.moduleSort = 1; //因为创建时，该序号为undfind,移除浅拷贝关系。重新赋值
    this.form = temporaryData;
    this.form.moduleTitle = "";
  },

  methods: {
    // 获取书单中的书籍 为编辑时。获取
    async getBookModuleByBookList(id) {
      const { data } = await this.$http.get("getBannerModuleDetails/" + id);
      let bookListsVOS = data.data.bookListsVOS;
      this.form.moduleSort = Number(data.data.moduleSort);
      this.form.moduleTitle = data.data.moduleTitle;
      if (data.data.isShow == 1) {
        this.isShow = false;
      } else {
        this.isShow = true;
      }
      for (let i = 0; i < bookListsVOS.length; i++) {
        const element = bookListsVOS[i];
        element.prohibit = true;
        if (element.isShow == 1) {
          element.check = false;
        } else {
          element.check = true;
        }
      }
      this.items.id = sessionStorage.getItem("itemsID");

      this.form.id = sessionStorage.getItem("itemsID");
      let arr = bookListsVOS.filter((item) => item.isShow == 0);
      this.EnablingArr = arr; //启用的书集合
      if (arr.length <= 1) {
        // 根据单个名字筛选
        for (let i = 0; i < arr.length; i++) {
          const item = arr[i];
          item.prohibit = false;
          let index = bookListsVOS.findIndex((v) => v.id == arr[i].id);
          bookListsVOS[index] = arr[i];
        }
      }

      if (arr.length < 1) {
        this.forbid = false; //不能启用该模块
      } else {
        this.forbid = true; //启用该模块
      }

      this.tableData = bookListsVOS;
    },

    // 启用书单模块
    ISSHOU() {
      if (this.forbid == false) {
        this.isShow = false;
        this.$message.error("该模块下没有启用的书单");
      }
    },

    // 启用书单
    async shouBook(item) {
      const { data } = await this.$http.get(
        "getBookListWhetherIsInfo/" + item.id
      );

      if (data.ok) {
        // 弹窗代码
        if (this.EnablingArr.length == 1 && !item.check) {
          this.$confirm("关闭后无启用书单，该模块将不能开启，是否继续关闭?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(async () => {
              if (item.check) {
                item.isShow = 0;
              } else {
                item.isShow = 1;
              }
              const { data } = await this.$http.get(
                "openShowBookList/" + item.id + "/" + item.isShow
              );
              if (data.ok) {
                this.isShow = false;
                this.onSubmit("form");
              }
            })
            .catch(() => {
              item.check = true; //依旧启用
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
        } else {
          if (item.check) {
            item.isShow = 0;
          } else {
            item.isShow = 1;
          }
          const { data } = await this.$http.get(
            "openShowBookList/" + item.id + "/" + item.isShow
          );
          if (data.ok) {
            this.getBookModuleByBookList(this.items.id);
          }
        }

        // 弹窗代码 end
      } else {
        item.check = false;
        this.$message.error("该书单下暂无书籍");
      }
    },

    // 添加书单
    addBook(status) {
      let item = this.items;
      item.BookStatus = status; //0添加; 1编辑
      // console.log(sessionStorage.getItem("itemsID"));
      this.$router.push({ path: "/editBookList", query: item });
    },

    // 提交
    onSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let params = {};
          params.moduleInfo = this.form.moduleInfo; //模描述
          params.moduleSort = this.form.moduleSort; //模块序号
          params.moduleTitle = this.form.moduleTitle; //模块名称
          params.moduleType = this.form.moduleType;
          //是否启用
          if (this.isShow) {
            params.isShow = 0;
          } else {
            params.isShow = 1;
          }

          if (this.form.moduleSort < 1) {
            return this.$message.error("序号不能小于1");
          }
          if (this.items.Editstatus == 2) {
            params.id = this.form.id; //模块id
            this.loadingBut = true;
            this.loadingButtext = "保存中";
            const { data } = await this.$http.post(
              "updateBannerModule",
              params
            );
            this.loadingBut = false;
            this.loadingButtext = "保存";
            if (data.ok) {
              this.items.Satus = 2; //1为新增保存到缓存。判断浏览器返回来源。将1变为2。改为编辑
              sessionStorage.setItem("Satus", 2);
              this.$message.success("编辑成功");
              this.getBookModuleByBookList(this.form.id);
            }
          } else {
            params.moduleId = this.form.id; //模块id
            this.loadingBut = true;
            this.loadingButtext = "保存中";
            const { data } = await this.$http.post(
              "createBannerModule",
              params
            );
            this.loadingBut = false;
            this.loadingButtext = "保存";
            this.items.Editstatus = 2; //0位新增。将0变为1。改为编辑
            this.form.id = data.data;
            this.items.id = data.data;

            this.items.itemsID = 2; //1为新增保存到缓存。判断浏览器返回来源。将1变为2。改为编辑
            sessionStorage.setItem("itemsID", data.data);
            sessionStorage.setItem("Satus", 2);
            this.getBookModuleByBookList(data.data);
          }
        }
      });
    },

    // 编辑书单
    handleClick(row) {
      let item = row;
      item.BookStatus = 1; //0添加; 1编辑
      this.$router.push({ path: "/editBookList", query: item });
    },

    // 删除书单
    deleteRow(item) {
      this.$confirm("是否删除该书籍?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await this.$http.get("removeBookList/" + item.id);
          if (data.ok) {
            this.getBookModuleByBookList(this.items.id);
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.img_top {
  margin-bottom: 20px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.edit_btn_Wrap {
  display: flex;
  justify-content: space-between;

  .EB_Style {
    font-weight: 700;
    padding: 5px 0;
  }

  .upbook {
    padding: 5px 14px;
    color: #fff;
    text-align: center;
    background: #ff1932;
  }
}

.wrap_cx {
  display: flex;
  align-content: center;
  justify-content: space-between;
  .addupbook {
    display: flex;
    .upbook {
      padding: 0 14px;
      line-height: 32px;
      height: 32px;
      color: #fff;
      border-radius: 2px;
      text-align: center;
      background: #ff1932;
    }

    .cancel {
      padding: 0 14px;
      line-height: 32px;
      height: 32px;
      border-radius: 2px;
      text-align: center;
      border: 1px solid #ccc;
    }
  }
}
</style>