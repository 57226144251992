<template>
  <div>
    <el-card style="padding: 25px 50px 21px">
      <div class="img_top">编辑书单</div>
      <el-row :gutter="20">
        <el-col :span="16">
          <el-col :span="14">
            <el-form ref="form" :rules="rules" :model="form" label-width="80px">
              <el-form-item label="书单名称" prop="bookListTitle">
                <el-input v-model="form.bookListTitle"></el-input>
              </el-form-item>
              <el-form-item label="书单描述" prop="bookListInfo">
                <el-input v-model="form.bookListInfo"></el-input>
              </el-form-item>
              <el-form-item label="序号" prop="bookListSort">
                <el-input v-model.number="form.bookListSort"></el-input>
              </el-form-item>

              <el-form-item label="是否启用" v-if="items.BookStatus == 1">
                <el-switch
                  v-model="isShow"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  @change="isSHOW"
                >
                </el-switch>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  class="btn_Submit"
                  style="background: #ff1932"
                  @click="onSubmit('form')"
                  :loading="loadingBut"
                  >{{ loadingButtext }}</el-button
                >
              </el-form-item>
            </el-form>
          </el-col>
        </el-col>
      </el-row>
    </el-card>

    <el-card style="margin-top: 20px" v-if="items.BookStatus == 1">
      <el-row>
        <el-col :span="24" class="edit_btn_Wrap">
          <div class="EB_Style">书单内书籍</div>
          <div class="upbook" @click="addBook">添加书籍</div>
        </el-col>
      </el-row>
      <template class="table_top">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column label="书籍封面">
            <template slot-scope="scope">
              <img :src="scope.row.bookImage" style="width: 80px" alt="" />
            </template>
          </el-table-column>
          <el-table-column prop="bookName" label="书籍名称"> </el-table-column>
          <el-table-column prop="bookSort" label="序号"> </el-table-column>
          <el-table-column prop="address" label="操作">
            <template slot-scope="scope">
              <el-button
                @click="handleClick(scope.row)"
                type="text"
                size="small"
                style="color: #0bd46a"
                >编辑</el-button
              >
              <el-button
                v-if="prohibit"
                @click.native.prevent="deleteRow(scope.row)"
                type="text"
                size="small"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </el-card>

    <!-- 右侧弹出层 -->
    <el-drawer
      :with-header="false"
      :visible.sync="table"
      direction="rtl"
      size="50%"
      @close="hideDrawer"
    >
      <el-card>
        <el-col :span="24">
          <div class="wrap_cx">
            <div>
              <el-input
                placeholder="请输入内容"
                v-model="input3"
                class="input-with-select"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="queryBook"
                ></el-button>
              </el-input>
            </div>
            <el-row class="addupbook">
              <div class="cancel" style="margin-right: 10px" @click="cancal">
                取消
              </div>
              <div class="upbook" @click="upBook">添加</div>
            </el-row>
          </div>
        </el-col>
      </el-card>
      <el-card>
        <el-table
          :data="gridData"
          :show-header="false"
          ref="multipleTable"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          @row-click="handleRowClick"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column property="title" label="姓名"></el-table-column>
          <el-table-column property="isbn" label="isbn"></el-table-column>
          <el-table-column property="bookAlias" label="别名"></el-table-column>
        </el-table>
      </el-card>
    </el-drawer>

    <!-- 编辑排序 -->
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <el-form
        :model="numbersolt"
        ref="numberValidateForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item
          label="序号"
          prop="bookSort"
          :rules="[
            { required: true, message: '序号不能为空' },
            { type: 'number', message: '序号必须为数字值' },
          ]"
        >
          <el-input
            type="bookSort"
            v-model.number="numbersolt.bookSort"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('numberValidateForm')"
            >提交</el-button
          >
          <el-button @click="resetForm('numberValidateForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        moduleTitle: "",
        moduleSort: 1,
      },

      isShow: false, //是否启用
      loadingBut: false,
      loadingButtext: "保存",
      prohibit: true,

      table: false, //抽屉
      dialogVisible: false, //排序弹出层

      // 数组数据
      tableData: [],
      rules: {
        bookListTitle: [
          { required: true, message: "请输入书单名称", trigger: "blur" },
        ],
        bookListInfo: [
          { required: true, message: "请输入书单名称", trigger: "blur" },
        ],
        bookListSort: [
          { required: true, message: "序号不能为空" },
          { type: "number", message: "序号必须为数字值" },
        ],
      },

      // 遮罩层显示数组数据
      gridData: [],

      // 选中项
      options: [
        {
          value: "3",
          label: "书籍1",
        },
        {
          value: "4",
          label: "书籍2",
        },
        {
          value: "5",
          label: "书籍3",
        },
      ],

      items: {}, //创建时书单时的模块信息

      // 排序
      numbersolt: {
        bookSort: 0,
      },

      input3: "",

      BookListID: 0, //书单书籍时使用的id书单
      multipleSelection: [], //选中书籍
      bannerModuleId: 0, //存储的模块id
      SerialNumber: 0, //序号，  修改排序时需要使用
    };
  },

  mounted() {
    // console.log(sessionStorage.getItem("itemsID"));
    let item = this.$route.query;
    // console.log(item);
    // console.log(this.bannerModuleId);
    this.bannerModuleId = item.id; //存储的模块id
    // console.log(item);
    this.items = item; //创建时书单时的模块信息
    if (this.items.BookStatus == 1) {
      this.items.bookListSort = Number(this.items.bookListSort);
      this.getBookModuleByBookList(this.items.id);
    } else {
      sessionStorage.setItem("itemsID", this.bannerModuleId);
      // console.log( this.bannerModuleId);
      sessionStorage.setItem("Satus", 2); //新增为1;  编辑为2
    }
    this.form = this.items;
    this.keyupSubmit(); //监听回车
  },

  methods: {
    // 获取书单中的书籍 为编辑时。获取
    async getBookModuleByBookList(id) {
      const { data } = await this.$http.get("getBookModuleByBookList/" + id);
      let bookModuleList = data.data.bookModuleList;
      if (data.data.isShow == 0) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
      this.BookListID = data.data.id; //查询用的书单id；
      this.form.bookListTitle = data.data.bookListTitle;
      this.form.bookListSort = data.data.bookListSort;
      this.form.bookListInfo = data.data.bookListInfo;
      if (bookModuleList.length <= 1) {
        this.prohibit = false; //禁止删除
      } else {
        this.prohibit = true; //禁止删除
      }

      this.tableData = bookModuleList;
    },

    // 删除 书籍
    deleteRow(item) {
      this.$confirm("是否删除该书籍?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await this.$http.get("removeBookModule/" + item.id);
          if (data.ok) {
            this.getBookModuleByBookList(this.items.id);
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 是否可以启用
    isSHOW() {
      if (this.tableData.length < 1) {
        this.isShow = false;
        this.$message.error("书单下书籍不能少于1本");
      }
    },

    // 2.0-1重置
    resetForm(formName) {
      this.numbersolt.bookSort = this.SerialNumber;
      this._watcher.run();
    },

    // 保存
    onSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let params = {};
          // 是否启用
          // 书单默认设置为未启用
          params.bookListInfo = this.form.bookListInfo; //书单简介
          params.bookListTitle = this.form.bookListTitle; //书单标题
          params.bookListSort = this.form.bookListSort; //书单序号
          if (this.isShow) {
            params.isShow = 0; //是否启用
          } else {
            params.isShow = 1; //1 不启用
          }
          if (this.form.moduleSort < 1) {
            return this.$message.error("序号不能小于1");
          }

          if (this.items.BookStatus == 1) {
            params.bannerModuleId = this.items.bannerModuleId; //模块id
            params.id = this.items.id;
            this.loadingBut = true;
            this.loadingButtext = "保存中";
            const { data } = await this.$http.post("updateBookList", params);
            this.loadingBut = false;
            this.loadingButtext = "保存";
            if (data.ok) {
              this.$message.success("编辑成功");
            }
          } else {
            params.bannerModuleId = this.items.id; //模块id
            this.loadingBut = true;
            this.loadingButtext = "保存中";
            const { data } = await this.$http.post("createBookList", params);
            this.loadingBut = false;
            this.loadingButtext = "保存";
            if (data.ok) {
              this.$message.success("添加成功");
              this.items.BookStatus = 1;
              this.form.id = data.data;
              this.items.id = data.data; //赋值id
              this.getBookModuleByBookList(data.data);
            }
          }
        }
      });
    },

    //3.0 添加书籍
    addBook() {
      this.table = true;
    },

    // 3.0关闭遮罩层
    cancal() {
      this.table = false;
    },

    // 3.0-2 //关闭书籍
    hideDrawer() {
      this.input3 = "";
      this.gridData = [];
      this.multipleSelection = [];
    },

    // 3.0-1 模糊查询 获取到书籍
    async queryBook() {
      const { data } = await this.$http.get(
        "getBannerModuleBook/" + this.input3 + "/" + this.BookListID + "/" + "0" // 0 书单; 1书籍
      );
      this.gridData = data.data;
    },

    //3.0-1 添加书籍  --  右侧弹出层书籍
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    // 3.0-2 //添加书籍
    async upBook() {
      let multipleSelection = this.multipleSelection;
      let params = {};
      params.bookModuleList = [];
      for (let i = 0; i < multipleSelection.length; i++) {
        const item = multipleSelection[i];
        let intems = {};
        intems.bookAuthor = item.author;
        intems.listModuleId = this.items.id; //书单或者模块id
        // intems.id = this.items.id;
        intems.listOrModule = 0; //  书单 还是模块 0书单 1模块
        intems.bookPublisher = item.publisher;
        intems.bookPrice = item.price;
        intems.bookName = item.title;
        intems.bookImage = item.img;
        intems.bookId = item.id;
        intems.bookAlias = item.bookAlias;
        intems.bookSort = 0;
        params.bookModuleList.push(intems);
      }
      params.listModuleId = this.items.id; //模板或者书单id
      params.listOrModule = 0; // 书单还是模块 0书单 1模块
      const { data } = await this.$http.post("addBookToModule", params);
      if (data.ok) {
        this.$message({
          message: "添加成功",
          type: "success",
          duration: 1000,
        });
        this.getBookModuleByBookList(this.items.id);
        this.queryBook();
      }
    },

    // 2.编辑书单--- 排序编辑
    handleClick(item) {
      this.dialogVisible = true;
      this.SerialNumber = item.bookSort;
      let items = JSON.parse(JSON.stringify(item));
      this.numbersolt = items;
    },

    //点击行触发，选中或不选中复选框
    handleRowClick(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },

    // 2.0排序提交
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let params = {};
          params.id = this.numbersolt.id;
          params.sort = this.numbersolt.bookSort;
          const { data } = await this.$http.post("updateBookModule", params);
          if (data.ok) {
            this.dialogVisible = false;
            this.getBookModuleByBookList(this.items.id);
          }
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },

    //添加一个监听enter事件
    keyupSubmit() {
      document.onkeydown = (e) => {
        let _key = window.event.keyCode;
        if (_key === 13) {
          this.queryBook();
        }
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.img_top {
  margin-bottom: 20px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.edit_btn_Wrap {
  display: flex;
  justify-content: space-between;

  .EB_Style {
    font-weight: 700;
    padding: 5px 0;
  }

  .upbook {
    padding: 5px 14px;
    color: #fff;
    text-align: center;
    background: #ff1932;
  }
}

.wrap_cx {
  display: flex;
  align-content: center;
  justify-content: space-between;
  .addupbook {
    display: flex;
    .upbook {
      padding: 0 14px;
      line-height: 32px;
      height: 32px;
      color: #fff;
      border-radius: 2px;
      text-align: center;
      background: #ff1932;
    }

    .cancel {
      padding: 0 14px;
      line-height: 32px;
      height: 32px;
      border-radius: 2px;
      text-align: center;
      border: 1px solid #ccc;
    }
  }
}
</style>