<template>
  <div>
    <div >
      <div class="booklid_wrap">
      
        <el-input
          placeholder="输入图书名"
          v-model.trim="queryInfo.bookName"
          class="ipt_Submit ipt1"
        ></el-input>
          <el-input
          placeholder="输入图书书号"
          v-model.trim="queryInfo.isbnCode"
          onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
          class="ipt_Submit ipt1"
        ></el-input>
         <div class="block">
          <el-date-picker
            v-model="timeinterval"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </div>
         <button
          class="btn_Submit"
          @click="bookReport(1)"
          v-on:keyup.enter="bookReport(1)"
        >
          查询
        </button>
         <div class="clear"
          @click="clear()"
        >
            <img src="@/assets/img/clear.png" alt="">
            <p>清除条件</p>
        </div>
      </div>

    </div>
    <el-card>
      
      <el-table
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        :data="tableData"
      >
        <el-table-column prop="memberCode" label="会员编号">
        </el-table-column>
        <el-table-column prop="memberName" label="借书人" width="180">
        </el-table-column>
        <el-table-column prop="saleNum" label="借阅单号" width="205">
        </el-table-column>
        <el-table-column prop="buyOutTime" label="买断时间" width="180">
        </el-table-column>
        <el-table-column prop="bookName" label="书籍名称" width="200">
        </el-table-column>
        <el-table-column prop="isbnCode" label="ISBN">
        </el-table-column>
        <el-table-column prop="bookPrice" label="图书定价">
        </el-table-column>
        <el-table-column prop="condition" label="品相">
        </el-table-column>
        <el-table-column prop="buyOutPrice" label="买断费用" >
        </el-table-column>
        <el-table-column prop="buyOutRefundPrice" label="退款" >
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="1"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
    data() {
      return {
        tableData: [
        ],
        timeinterval:"",
        queryInfo: {
          current: 1,
          size: 10,
          bookName:'',
          isbnCode:'',
          startDate:'',
          endDate:'',
        },
  
        currentPage: 1,
        total: 0,
        loading: true, //加载中
      };
    },
    mounted() {
      this.bookReport(); //获取提现订单列表
    },
  
    methods: {
      // 0.获取买断列表
      async bookReport(e) {
         if(e==1){
          this.queryInfo.current=1;
        }
        if(this.timeinterval!=''){
          let startDate = this.time(this.timeinterval[0])
          let endDate = this.time(this.timeinterval[1])
          this.startDate=startDate;
          // this.startDate=startDate.slice(0,10);
          this.endDate=endDate;
        }
        let params ={
          current:this.queryInfo.current,
          size:this.queryInfo.size,
          bookName:this.queryInfo.bookName,
          isbnCode:this.queryInfo.isbnCode,
          startDate:this.startDate,
          endDate:this.endDate,
        }
        let { data } = await this.$http.post("bookBuyOutReport", params);
         data.data.list =  data.data.list.map(item => {
          if(item.condition==0){
            item.condition = '全新';
          }else if(item.condition==1){
            item.condition = '良好';
          }else{
            item.condition = '普通';
          }
          return item
        })
        this.tableData = data.data.list;
        this.total = data.data.total;
        this.loading = false;
      },
    clear(){
      this.queryInfo.bookName='';
      this.queryInfo.isbnCode='';
      this.startDate='';
      this.endDate='';
      this.timeinterval='';
    },
    time(date) {
        var y = date.getFullYear()
        var m = date.getMonth() + 1
        m = m < 10 ? '0' + m : m
        var d = date.getDate()
        d = d < 10 ? '0' + d : d
        var h = date.getHours()
        h = h < 10 ? '0' + h : h
        var minute = date.getMinutes()
        minute = minute < 10 ? '0' + minute : minute
        var second = date.getSeconds()
        second = second < 10 ? '0' + second : second
        return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second
      },
  
      // 3.分页
      handleSizeChange(val) {
        this.queryInfo.size = val;
        this.bookReport(0);
      },
      handleCurrentChange(val) {
        this.queryInfo.current = val;
        this.bookReport(0);
      },
    },
  };
</script>

<style scoped>
.booklid_wrap {
  /* width: 100%; */

  display: flex;
  margin-bottom: 21px;
  /* justify-content: space-between; */
}


.ipt_Submit {
  width: 200px;
}

.ipt_Submit ::v-deep input {
  height: 35px;
}

.btn_Submit {
  margin-left: 10px;
}

.ipt1 {
  margin-right: 10px;
}
.el-table{
  color: #333333;
}
::v-deep.el-table th > .cell {
  text-align: center;
}

::v-deep.el-table .cell {
  text-align: center;
}
.clear{
  display: flex;
  margin-top: 9px;
  margin-left: 22px;
  color: #999999;
}
.clear img{
  width: 16px;
  height: 16px;
}
</style>